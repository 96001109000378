/* eslint-disable no-shadow */
import React, { useState, useRef, useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { DialogAtom } from '../../../../components/atoms';
import ButtonAtom from '../../../../components/atoms/button';
import { Buttons } from '../../../../constant';
import { useStyles } from './style';
import CheckboxAtom from '../../../../components/atoms/checkbox';
import { exportUserColumns } from '../constant';

export default function UserManagerExportDialog(props) {
  const { show, setShow, data } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [selectedColumn, setSelectedColumn] = useState([]);
  const [dataForDownload, setDataForDownload] = useState([]);
  const [bDownloadReady, setDownloadReady] = useState(false);
  const csvLink = useRef();
  const now = moment().format('MM-DD-yyyy');
  const currentTime = moment().format('hh:mm A');
  const name = 'User';

  useEffect(() => {
    if (csvLink && csvLink.current && bDownloadReady) {
      csvLink.current.link.click();
      setDownloadReady(false);
    }
  }, [bDownloadReady]);

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const selectedCheck = [];
      exportUserColumns.forEach((ci) => selectedCheck.push(ci.id));
      setSelectedColumn(selectedCheck);
    } else {
      setSelectedColumn([]);
    }
    setIsCheckAll(e.target.checked);
  };

  const handleCheck = (event, item) => {
    const { checked } = event.target;
    if (event.target.checked) {
      if (item.id === 'fee') {
        setSelectedColumn([...selectedColumn, item.id]);
      } else {
        setSelectedColumn([...selectedColumn, item.id]);
      }
    }
    if (!checked) {
      setSelectedColumn(selectedColumn.filter((value) => value !== item.id));
    }
  };

  const exportUserList = (e) => {
    e.preventDefault();
    const headers = [];
    const preheaders = [];
    selectedColumn.forEach((head) => {
      const selColumn = exportUserColumns.find((col) => col?.id === head); // filter selected head from all headers
      preheaders.push(selColumn.label);
    });
    preheaders.reverse().forEach((headersData) => {
      if (preheaders.includes(headersData)) {
        headers.unshift(headersData);
      }
    });

    const bodyData = [];
    data?.forEach((elem) => {
      const temp = [];

      if (selectedColumn.includes('name')) {
        temp[selectedColumn.findIndex((selectedColumnData) => selectedColumnData === 'name')] = elem.Name;
      }
      if (selectedColumn.includes('status')) {
        if (elem.Status) {
          temp[selectedColumn.findIndex((selectedColumnData) => selectedColumnData === 'status')] = 'Active';
        } else {
          temp[selectedColumn.findIndex((selectedColumnData) => selectedColumnData === 'status')] = 'Inactive';
        }
      }
      if (selectedColumn.includes('profilePhoto')) {
        temp[selectedColumn.findIndex((selectedColumnData) => selectedColumnData === 'profilePhoto')] = elem.ProfilePhoto;
      }
      if (selectedColumn.includes('manabadiEmail')) {
        temp[selectedColumn.findIndex((selectedColumnData) => selectedColumnData === 'manabadiEmail')] = elem.ManabadiEmail;
      }
      if (selectedColumn.includes('roleNames')) {
        temp[selectedColumn.findIndex((selectedColumnData) => selectedColumnData === 'roleNames')] = elem.RoleName;
      }
      if (selectedColumn.includes('personalEmail')) {
        temp[selectedColumn.findIndex((selectedColumnData) => selectedColumnData === 'personalEmail')] = elem.PersonalEmail;
      }
      if (selectedColumn.includes('address')) {
        temp[selectedColumn.findIndex((selectedColumnData) => selectedColumnData === 'address')] = elem.Address;
      }
      if (selectedColumn.includes('contactNumber')) {
        temp[selectedColumn.findIndex((selectedColumnData) => selectedColumnData === 'contactNumber')] = elem.ContactNumber;
      }
      if (selectedColumn.includes('gender')) {
        temp[selectedColumn.findIndex((selectedColumnData) => selectedColumnData === 'gender')] = elem.Gender;
      }
      bodyData.push(temp);
    });
    setDataForDownload([headers, ...bodyData]);
    setDownloadReady(true);
    setSelectedColumn([]);
    setIsCheckAll(false);
    setShow();
  };

  const onCancel = () => {
    setIsCheckAll(false);
    setSelectedColumn([]);
    setShow();
  };

  return (
    <DialogAtom
      isOpen={show}
      dialogHeading={t('EXPORT_CSV')}
      customClass={classes.RegionExportDialogueAtom}
      secHandle={onCancel}
      content={(
        <Grid className={classes.regionBox}>
          <Box
            component="form"
            m={3}
            mt={1}
            mb={0}
            sx={{
              '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
          >
            <Grid container className={classes.selectAll}>
              <Grid item>
                <CheckboxAtom
                  label={t('SELECT_ALL')}
                  id="acceptCheckbox"
                  checked={isCheckAll}
                  handleChange={handleSelectAll}
                  additionalClass={classes.customCheckBox}
                />
              </Grid>
              <div>&nbsp;</div>
            </Grid>
            <Grid className={classes.checkbox}>
              <Grid container className={classes.checkboxContent}>
                {exportUserColumns?.map((obj) => (
                  <Grid item xs={5} md={2.4} lg={2.4}>
                    <CheckboxAtom
                      additionalClass={classes.customCheckBox}
                      checked={selectedColumn.includes(obj.id)}
                      value={obj.id}
                      label={obj.label}
                      handleChange={(e) => handleCheck(e, obj)}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Box>
          <Grid item xs={12} className={`${classes.dialogButtons} ${classes.popupButton}`}>
            <ButtonAtom
              className={classes.popupBtn}
              name={t('CANCEL')}
              onClick={onCancel}
              btntype={Buttons.SECONDARY}
            />
            <ButtonAtom
              className={classes.popupBtn}
              name={t('EXPORT_TO_NEW_SHEET')}
              btntype={Buttons.PRIMARY}
              onClick={(e) => { exportUserList(e); }}
            />
            <CSVLink
              data={dataForDownload}
              download={`${name} - ${now} - ${currentTime}.csv`}
              target="_self"
              filename={`${name} - ${now} - ${currentTime}`}
              className="hidden"
              ref={csvLink}
            />
          </Grid>
        </Grid>
      )}
    />
  );
}
