/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid, Paper, Typography,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useStyles from '../../custom-hooks/useStyles';
import { updateTempRegLog } from '../../store/actions/getPayment';
import styles from './style';
import logo from '../../assets/images/logo.png';
import failierBoy from '../../assets/images/paymentFailed.png';
import { getLocalStorage } from '../../utils/localStorageMethod';

function Cancel() {
  const { t } = useTranslation();
  const classes = useStyles(styles)();
  const navigate = useNavigate();
  const [registrationId, setRegistrationId] = useState('');
  const [isNewRegistration, setIsNewRegistration] = useState(false);
  const [isBackDoor, setIsBackDoor] = useState(false);
  const orderId = getLocalStorage('orderId');
  const dispatch = useDispatch();

  useEffect(() => {
    const url = new URL(window.location);
    const params = new URLSearchParams(url.search);
    const registrationId = params.get('id');
    setRegistrationId(registrationId);
    const newRegistration = params.get('newRegistration') === 'true' ? true : false;
    setIsNewRegistration(newRegistration);
    const backDoor = params.get('isBackdoor') === 'true' ? true : false;
    setIsBackDoor(backDoor);

    callUpdateTempReg();
  }, []);

  const callUpdateTempReg = () => {
    debugger
    const payload = {
      registrationId,
      orderId,
      message: 'Click On Cancel Registration',
    };
    dispatch(updateTempRegLog(payload));
  };

  return (
    <Paper className={classes.root}>
      <Grid container>
        <img
          src={logo}
          width={441}
          alt="Italian Trulli"
          className={classes.headerLogo}
          onClick={() => navigate('/')}
        />
      </Grid>
      <Grid container justifyContent="space-between" className={classes.mainContainer}>
        <Grid item xs={11} lg={8} md={10} justifyContent="center" className={classes.cardRight}>
          <Grid container justifyContent="center">
            <img
              src={failierBoy}
              alt="Success"
              width={409}
              className={classes.successBackgroundImg}
            />
          </Grid>
          <Grid item justifyContent="center" xs={12} md={12} lg={12} className={classes.failedContent}>
            <Typography mb={2} className={classes.failedText}>
              {t('PAYMENT_FAILED_TEXT')}
            </Typography>
            {isNewRegistration && !isBackDoor ? (
              <>
                <Typography
                  mb={4}
                  xs={3}
                  md={3}
                  lg={3}
                  className={classes.successSubtext}
                >
                  {t('RETURN_PREFILLED_FORM')}
                  {' '}
                  <a href={`../register?id=${registrationId}`}>
                    {t('CLICK_HERE')}
                  </a>
                </Typography>
                <Typography
                  mb={4}
                  xs={3}
                  md={3}
                  lg={3}
                  className={classes.successSubtext}
                >
                  {t('RETURN_MANABADI')}
                  {' '}
                  <a href="/">
                    {t('CLICK_HERE')}
                  </a>
                </Typography>
              </>
            ) : (
              <>
                <Typography
                  mb={4}
                  xs={3}
                  md={3}
                  lg={3}
                  className={classes.successSubtext}
                >
                  {t('PAYMENT_TRY_AGAIN')}
                  {' '}
                  <a href={(isBackDoor ? "../dashboard/backlog-register" : "../dashboard/students")}>
                    {t('CLICK_HERE')}
                  </a>
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default Cancel;
