/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import * as Yup from 'yup';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import { useGridApiRef as useApiRef } from '@mui/x-data-grid-pro';
import styles from './style';
import useStyles from '../../../custom-hooks/useStyles';
import SyncHeader from './header';
import MediaUploader from '../../../utils/uploadSheet';
import DataGridProTable from '../../../components/atoms/data-grid-pro';
import {
  getAssignedLocations,
  getSectionDetails,
  setSelectedLocation,
} from '../../../store/actions/getLocationCoordinator';
import ChangeSection from './update-section';
import { moveStudent } from '../../../store/actions/getLocations';
import { getLocalStorage } from '../../../utils/localStorageMethod';
import { Buttons } from '../../../constant';
import { colors } from '../../../theme';
import ButtonAtom from '../../../components/atoms/button';
import { DialogAtom, Loader } from '../../../components/atoms';
import DialogAtomSheeet from '../marks-sync/dialogSheet';
import {
  getImportSheetTime,
  saveImportExportSheetTime,
  getStudentSectionsService,
} from '../../../store/services/auth';
import constant from '../../../store/constant';
import { getCurrentYear } from '../../../utils/methods';

const useStylesSheet = makeStyles(() => ({
  studentBox: {
    maxWidth: 'unset !important',
    '& .MuiGrid-item': {
      maxWidth: 'unset !important',
      textAlign: 'right !important',
    },
  },
  alertprimary: {
    height: '3vw',
    display: 'flex',
    color: colors.green,
    '@media (max-width: 900px)': {
      height: '15vw',
    },
  },
}));
const valuesArray = [
  {
    userEnteredValue: {
      stringValue: 'Student Id',
    },
  },
  {
    userEnteredValue: {
      stringValue: 'Student Name',
    },
  },
  {
    userEnteredValue: {
      stringValue: 'Age',
    },
  },
  {
    userEnteredValue: {
      stringValue: 'Parent Name',
    },
  },
  {
    userEnteredValue: {
      stringValue: 'Course',
    },
  },
  {
    userEnteredValue: {
      stringValue: 'Section',
    },
  },
];
export default function Classes() {
  const apiRef = useApiRef();
  const classes = useStyles(styles)();
  const { t } = useTranslation();
  const validationSchema = Yup.object({});
  const reduxStore = useSelector((state) => state?.getLocationCoordinator);
  const assignedLocations = reduxStore?.assignedLocations;
  const assignedYears = reduxStore?.assignedYears;
  const selectedYearRedux = reduxStore?.selectedYear;
  const selectedLocationRedux = reduxStore?.selectedLocations;
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [locations, setLocationData] = useState([]);
  const [years, setYearsData] = useState([]);
  const [fileName, setFileName] = useState(false);
  const [columns, setColumns] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [originSort, setOriginSort] = useState([]);
  const [payload, setPayload] = useState();
  const [bOpenImportSheet, setOpenImportSheet] = useState(false);
  const [bOpenDialog, setOpenDialog] = useState(false);
  const [showTokenAdvice, setShowTokenAdvice] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSelectedlocationName, setSelectedlocationName] = useState();
  const [isselectedLocation, setSelectedLocationn] = useState();
  const [idSheet, setIdSheet] = useState();
  const [nameSheet, setNameSheet] = useState('Sheet has not been exported');
  const [createAtSheetTime, setCreateAtSheetTime] = useState(
    'Sheet has not been exported',
  );
  const [exportSheetTime, setExportSheetTime] = useState(
    'Sheet has not been exported',
  );
  const [importSheetTime, setImportSheetTime] = useState(
    'Sheet has not been imported',
  );
  const [openSheetExists, setOpenSheetExists] = useState(false);
  const [openSheet, setOpenSheet] = useState(false);
  const [isApiFailed, setApiFailed] = useState(false);
  const [apiFailedMessage, setApiFailedMessage] = useState(false);

  const setFailed = (message) => {
    setApiFailedMessage(message);
    setApiFailed(true);
  };
  useEffect(() => {
    setExportSheetTime(reduxStore.exportSheetTime);
    setCreateAtSheetTime(reduxStore.createdAtSheetTime);
  }, [reduxStore.exportSheetTime, reduxStore.createdAtSheetTime]);

  useEffect(() => {
    setImportSheetTime(reduxStore.importSheetTime);
  }, [reduxStore.importSheetTime]);

  useEffect(() => {
    if (selectedLocationRedux?.value) {
      setSelectedLocationn(selectedLocationRedux?.value);
      const oLocation = assignedLocations.filter((location) => location.id === selectedLocationRedux.value);
      setSelectedlocationName(oLocation[0]?.shortName);
    } else {
      setSelectedLocationn(assignedLocations[0]?.id);
      setSelectedlocationName(assignedLocations[0]?.shortName);
    }
  }, [assignedLocations]);

  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      academicYear: '',
      locationId: locations[0]?.id,
    },
    validationSchema,
    onSubmit: () => { },
  });
  const [sortModel, setSortModel] = useState([
    {
      field: 'section',
      sort: 'desc',
    },
  ]);
  const onSortModelChange = (model) => {
    const fieldName = model[0]?.field;
    const sortType = model[0]?.sort;
    if (fieldName === 'section' && sortType === 'desc') {
      const strDescending = [...rowData].sort((a, b) => b.section.localeCompare(a.section));
      setRowData(strDescending);
    } else if (fieldName === 'section' && sortType === 'asc') {
      const strAscending = [...rowData].sort((a, b) => a.section.localeCompare(b.section));
      setRowData(strAscending);
    } else if (fieldName === 'studentInfo' && sortType === 'desc') {
      setRowData((prevRowData) => [...prevRowData].sort((a, b) => b.studentInfo.localeCompare(a.studentInfo)));
    } else if (fieldName === 'studentInfo' && sortType === 'asc') {
      setRowData((prevRowData) => [...prevRowData].sort((a, b) => a.studentInfo.localeCompare(b.studentInfo)));
    } else if (fieldName === 'parent1Info' && sortType === 'desc') {
      setRowData((prevRowData) => [...prevRowData].sort((a, b) => b.parent1Info.localeCompare(a.parent1Info)));
    } else if (fieldName === 'parent1Info' && sortType === 'asc') {
      setRowData((prevRowData) => [...prevRowData].sort((a, b) => a.parent1Info.localeCompare(b.parent1Info)));
    } else if (fieldName === 'course_name' && sortType === 'desc') {
      setRowData((prevRowData) => [...prevRowData].sort((a, b) => b.course_name.localeCompare(a.course_name)));
    } else if (fieldName === 'course_name' && sortType === 'asc') {
      setRowData((prevRowData) => [...prevRowData].sort((a, b) => a.course_name.localeCompare(b.course_name)));
    } else if (fieldName === undefined && sortType === undefined) {
      setRowData(originSort);
    }

    if (JSON.stringify(model) !== JSON.stringify(sortModel)) {
      setSortModel(model);
    }
  };

  const getGoogleSheet = (callback) => {
    const gettoken = getLocalStorage('accessToken');
    const baseUrl = JSON.parse(gettoken);
    const xhr = new XMLHttpRequest();
    xhr.open(
      'GET',
      'https://www.googleapis.com/drive/v3/files?pageSize=1000&supportAllDrives=false&includeItemsFromAllDrives=false&q="root" in parents&fields=files(id,name,createdTime,modifiedTime,size,webViewLink)',
    );
    xhr.setRequestHeader('Authorization', `Bearer ${baseUrl?.access_token}`);
    xhr.onload = () => {
      callback(xhr.responseText);
    };
    xhr.onerror = () => {
      callback(null);
    };
    xhr.send();
  };

  const handleImportDialog = () => {
    setOpenDialog(false);
  };

  const getSheet = () => {
    setLoading(true);
    getGoogleSheet((i) => {
      const fileData = JSON?.parse(i)?.files;
      if (fileData !== undefined) {
        const sNameSheet = fileData?.filter((item) => item.name === fileName);
        const webViewLink = sNameSheet.length === 0 ? [] : sNameSheet[0]?.webViewLink.split('/');
        if (webViewLink[3] === 'spreadsheets') {
          setOpenImportSheet(true);
        } else {
          setOpenDialog(true);
        }
      } else {
        setShowTokenAdvice(true);
        setLoading(false);
      }
    });
  };
  const classesSheet = useStylesSheet();
  const getGoogleSheetbyId = (id, callback) => {
    const gettoken = getLocalStorage('accessToken');
    const baseUrl = JSON.parse(gettoken);
    const xhr = new XMLHttpRequest();
    xhr.open(
      'GET',
      `https://sheets.googleapis.com/v4/spreadsheets/${id}?includeGridData=true`,
    );
    xhr.setRequestHeader('Authorization', `Bearer ${baseUrl?.access_token}`);
    xhr.onload = () => {
      callback(xhr.responseText);
    };
    xhr.onerror = () => {
      callback(null);
    };
    xhr.send();
  };
  const refreshList = () => {
    if (formik.values?.academicYear && formik.values?.locationId) {
      setLoading(true);
      const importPayload = {
        syncType: 'section',
        type: 'import',
        locationId: formik.values?.locationId,
        academicYear: formik.values?.academicYear,
      };
      getImportSheetTime(importPayload)
        .then((importres) => {
          if (importres?.data?.length) {
            const importDate = moment(importres?.data[0]?.sync_time).format(
              'MM-DD-YYYY HH:mm:ss',
            );
            setLoading(false);
            dispatch({
              type: constant.SET_IMPORT_TIME,
              payload: moment(importDate).fromNow(),
            });
            dispatch({
              type: constant.SET_CREATED_SHEET_TIME,
              payload: importDate,
            });
          } else {
            dispatch({
              type: constant.SET_IMPORT_TIME,
              payload: false,
            });
            dispatch({
              type: constant.SET_CREATED_SHEET_TIME,
              payload: '',
            });
          }
        })
        .catch(() => {
          setLoading(false);
        });

      const exportPayload = {
        syncType: 'section',
        type: 'export',
        locationId: formik.values?.locationId,
        academicYear: formik.values.academicYear,
      };
      getImportSheetTime(exportPayload)
        .then((exportResponse) => {
          if (exportResponse?.data?.length) {
            const exportDate = moment(
              exportResponse?.data[0]?.sync_time,
            ).format('MM-DD-YYYY HH:mm:ss');
            dispatch({
              type: constant.SET_EXPORT_TIME,
              payload: moment(exportDate).fromNow(),
            });
            dispatch({
              type: constant.SET_EXPORTED_SHEET_TIME,
              payload: exportDate,
            });
          } else {
            dispatch({
              type: constant.SET_EXPORT_TIME,
              payload: false,
            });
            dispatch({
              type: constant.SET_EXPORTED_SHEET_TIME,
              payload: '',
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    setIsOpen(false);
    getGoogleSheet((i) => {
      const fileData = JSON?.parse(i)?.files;
      if (fileData !== undefined) {
        const sNameSheet = fileData.filter((item) => item.name === fileName);
        setLoading(false);
        if (sNameSheet.length !== 0) {
          setNameSheet(sNameSheet[0].name);
          setIdSheet(sNameSheet[0].id);
        } else {
          setNameSheet('Sheet has not been exported');
          setIdSheet();
        }
      } else {
        setShowTokenAdvice(true);
        setLoading(false);
      }
    });
  };
  const handleOkDialog = () => {
    setOpenSheet(false);
  };
  const saveDBFromSheet = async (data) => {
    setLoading(true);
    const payloadEdit = [];
    for (let iSheet = 0; iSheet < data.sheets.length; iSheet++) {
      const l = data.sheets[iSheet];
      for (let iRow = 1; iRow < l.data[0]?.rowData?.length; iRow++) {
        const info = l.data[0].rowData[iRow].values;
        if (
          info[0].formattedValue !== ''
          && info[0].formattedValue !== undefined
        ) {
          const studentData = rowData.filter((option) => option.id === info[0].formattedValue);
          console.log(studentData);
          if (studentData.length > 0 && info[5]?.formattedValue.length === 1) {
            const payloadRow = {
              changeLog: 'Changed Section from Location Coordinator',
              locationId: formik.values.locationId,
              logMessage: 'Changed Section from Location Coordinator',
              courseId: studentData[0].courseId,
              isSectionChanged: true,
            };
            payloadRow.studentId = info[0].formattedValue;
            if (
              info[5]
              && info[5].formattedValue !== ''
              && info[5].formattedValue !== undefined
            ) {
              let sectionId = [];
              const sectionOptions = [];
              const payloadSection = {
                locationId: formik.values.locationId,
                courseId: studentData[0].courseId,
                academicYear: localStorage.getItem('academicYear'),
              };
              await getStudentSectionsService(payloadSection).then((res) => {
                res.data.forEach((response) => {
                  sectionOptions.push(response);
                });
                let isSectionIn = false;
                for (let section = 0; section < sectionOptions.length; section++) {
                  if (sectionOptions[section].section === studentData[0].section) {
                    isSectionIn = true;
                  }
                }
                if (!isSectionIn) {
                  sectionOptions.push({ id: studentData[0].sectionId, section: studentData[0].section });
                }
                sectionId = sectionOptions.filter((option) => option.section === info[5].formattedValue.toUpperCase());
                if (sectionId.length > 0) {
                  payloadRow.sectionId = sectionId[0].id;
                } else {
                  payloadRow.section = info[5].formattedValue.toUpperCase();
                }
              });
            }
            if (payloadRow) {
              payloadEdit.push(payloadRow);
            }
          }
        }
      }
    }
    dispatch(
      moveStudent(
        payloadEdit,
        setLoading,
        refreshList,
        formik.values.locationId,
        formik.values.academicYear,
        '',
        setFailed,
      ),
    );
  };
  const sendSheet = (array) => {
    setLoading(true);
    const timePayload = {
      syncType: 'section',
      type: 'export',
      syncTime: new Date(),
      locationId: formik.values.locationId,
      academicYear: formik.values.academicYear,
    };
    if (idSheet === undefined) {
      const payloadPutSheet = [];
      const newRowsQ1 = {
        updateCells: {
          rows: [
            {
              values: valuesArray,
            },
          ],
          start: {
            rowIndex: 0,
            columnIndex: 0,
            sheetId: '',
          },
          fields: 'userEnteredValue',
        },
      };

      const spreadSheetData = array;
      for (let indexQ1 = 0; indexQ1 < spreadSheetData.length; indexQ1++) {
        const newValuesQ1 = {
          values: [],
        };
        for (const pQ1 in spreadSheetData[indexQ1]) {
          if (pQ1 === 'id') {
            newValuesQ1.values.push({
              userEnteredValue: {
                stringValue: spreadSheetData[indexQ1][pQ1],
              },
            });
          } else if (pQ1 === 'studentInfo') {
            newValuesQ1.values.push({
              userEnteredValue: {
                stringValue: spreadSheetData[indexQ1][pQ1],
              },
            });
          } else if (pQ1 === 'date_of_birth') {
            newValuesQ1.values.push({
              userEnteredValue: {
                numberValue: spreadSheetData[indexQ1][pQ1],
              },
            });
          } else if (pQ1 === 'parent1Info') {
            newValuesQ1.values.push({
              userEnteredValue: {
                stringValue: spreadSheetData[indexQ1][pQ1],
              },
            });
          } else if (pQ1 === 'course_name') {
            newValuesQ1.values.push({
              userEnteredValue: {
                stringValue: spreadSheetData[indexQ1][pQ1],
              },
            });
          } else if (pQ1 === 'section') {
            newValuesQ1.values.push({
              userEnteredValue: {
                stringValue: spreadSheetData[indexQ1][pQ1],
              },
            });
          }
        }
        newRowsQ1.updateCells.rows.push(newValuesQ1);
      }

      if (newRowsQ1.updateCells.rows[1]?.values.length !== 0) {
        payloadPutSheet.push({
          requests: [
            {
              appendDimension: {
                sheetId: '',
                dimension: 'COLUMNS',
                length: 50,
              },
            },
            {
              repeatCell: {
                cell: {
                  dataValidation: {
                    condition: {
                      type: 'BOOLEAN',
                    },
                  },
                },
                range: {
                  sheetId: '',
                  startRowIndex: 0,
                  endRowIndex: 0,
                  startColumnIndex: 0,
                  endColumnIndex: 0,
                },
                fields: 'dataValidation',
              },
            },
            newRowsQ1,
          ],
        });
      }

      if (newRowsQ1.updateCells.rows[1]?.values.length === 0) {
        payloadPutSheet.push({
          requests: [
            {
              updateCells: {
                rows: [
                  {
                    values: valuesArray,
                  },
                ],
                start: {
                  rowIndex: 0,
                  columnIndex: 0,
                  sheetId: '',
                },
                fields: 'userEnteredValue',
              },
            },
          ],
        });
      }
      const payloadCreateSheet = {
        properties: {
          title: fileName,
        },
        sheets: [
          {
            properties: {
              title: 'Section Sync',
            },
          },
        ],
      };
      setLoading(true);
      const gettoken = getLocalStorage('accessToken');
      const baseUrl = JSON.parse(gettoken);
      const uploader = new MediaUploader({
        token: baseUrl?.access_token,
        filename: fileName,
        contentType: 'application/vnd.google-apps.spreadsheet',
        metadata: payloadCreateSheet,
        sheetMood: 'New',
        file: payloadPutSheet,
        onComplete: () => {
          saveImportExportSheetTime(timePayload)
            .then(() => {
              refreshList();
            })
            .catch(() => { });
          setLoading(false);
          setOpenSheet(true);
          getGoogleSheet((i) => {
            const fileData = JSON?.parse(i)?.files;
            if (fileData !== undefined) {
              const sNameSheet = fileData.filter(
                (item) => item.name === fileName,
              );
              if (sNameSheet.length !== 0) {
                setNameSheet(sNameSheet[0].name);
                setIdSheet(sNameSheet[0].id);
              } else {
                setNameSheet('Sheet has not been exported');
                setIdSheet();
              }
            } else {
              setShowTokenAdvice(true);
              setLoading(false);
            }
          });
        },
        onError: () => {
          setShowTokenAdvice(true);
          setLoading(false);
        },
      });
      uploader.upload();
    } else {
      saveImportExportSheetTime(timePayload)
        .then(() => {
          refreshList();
        })
        .catch(() => { });
      setOpenSheetExists(true);
      setLoading(false);
    }
  };
  const ImportCsv = () => {
    setOpenImportSheet(false);
    getGoogleSheet((i) => {
      const fileData = JSON?.parse(i)?.files;
      if (fileData !== undefined) {
        const sNameSheet = fileData.filter((item) => item.name === fileName);
        getGoogleSheetbyId(sNameSheet[0].id, (data) => {
          const fileDataById = JSON.parse(data);
          saveDBFromSheet(fileDataById);
        });
      } else {
        setShowTokenAdvice(true);
        setLoading(false);
      }
    });
  };
  const renderImportTextContent = () => (
    <Grid className={classes.studentBox}>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <Grid>
          <div className={classesSheet.alertprimary} role="alert">
            {t('IMPORT_FROM_SHEET_ADVICE')}
          </div>
        </Grid>
      </Box>
      <Grid
        item
        xs={12}
        className={`${classesSheet.dialogButtons} ${classesSheet.popupButton}`}
      >
        <ButtonAtom
          className={classesSheet.popupBtn}
          name={t('CANCEL')}
          onClick={() => {
            setOpenImportSheet(false);
            setLoading(false);
          }}
          btntype={Buttons.SECONDARY}
        />
        <ButtonAtom
          className={classes.popupBtn}
          name={t('OK')}
          onClick={ImportCsv}
          btntype={Buttons.PRIMARY}
        />
      </Grid>
    </Grid>
  );

  const renderOkContent = () => (
    <Grid className={classesSheet.studentBox}>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <Grid>
          <div className={classesSheet.alertprimary} role="alert">
            {t('STUDENT_CSV_EXPORTED_SUCCESSFULLY')}
          </div>
        </Grid>
      </Box>
      <Grid
        item
        xs={12}
        className={`${classesSheet.dialogButtons} ${classesSheet.popupButton}`}
      >
        <ButtonAtom
          className={classesSheet.popupBtn}
          name={t('OK')}
          onClick={handleOkDialog}
          btntype={Buttons.PRIMARY}
        />
      </Grid>
    </Grid>
  );
  const handleSheetDialog = () => {
    setOpenSheetExists(false);
    setLoading(false);
  };
  const exportSheetExists = () => {
    setOpenSheetExists(false);
    const payloadPutSheet = [];
    const newRowsQ1 = {
      updateCells: {
        rows: [
          {
            values: valuesArray,
          },
        ],
        start: {
          rowIndex: 0,
          columnIndex: 0,
          sheetId: '',
        },
        fields: 'userEnteredValue',
      },
    };
    const spreadSheetData = rowData;
    for (let indexQ1 = 0; indexQ1 < spreadSheetData.length; indexQ1++) {
      const newValuesQ1 = {
        values: [],
      };
      for (const pQ1 in spreadSheetData[indexQ1]) {
        if (pQ1 === 'id') {
          newValuesQ1.values.push({
            userEnteredValue: {
              stringValue: spreadSheetData[indexQ1][pQ1],
            },
          });
        } else if (pQ1 === 'studentInfo') {
          newValuesQ1.values.push({
            userEnteredValue: {
              stringValue: spreadSheetData[indexQ1][pQ1],
            },
          });
        } else if (pQ1 === 'date_of_birth') {
          newValuesQ1.values.push({
            userEnteredValue: {
              numberValue: spreadSheetData[indexQ1][pQ1],
            },
          });
        } else if (pQ1 === 'parent1Info') {
          newValuesQ1.values.push({
            userEnteredValue: {
              stringValue: spreadSheetData[indexQ1][pQ1],
            },
          });
        } else if (pQ1 === 'course_name') {
          newValuesQ1.values.push({
            userEnteredValue: {
              stringValue: spreadSheetData[indexQ1][pQ1],
            },
          });
        } else if (pQ1 === 'section') {
          newValuesQ1.values.push({
            userEnteredValue: {
              stringValue: spreadSheetData[indexQ1][pQ1],
            },
          });
        }
      }
      newRowsQ1.updateCells.rows.push(newValuesQ1);
    }

    if (newRowsQ1.updateCells.rows[1]?.values.length !== 0) {
      payloadPutSheet.push({
        requests: [
          {
            appendDimension: {
              sheetId: '',
              dimension: 'COLUMNS',
              length: 50,
            },
          },
          {
            repeatCell: {
              cell: {
                dataValidation: {
                  condition: {
                    type: 'BOOLEAN',
                  },
                },
              },
              range: {
                sheetId: '',
                startRowIndex: 0,
                endRowIndex: 0,
                startColumnIndex: 0,
                endColumnIndex: 0,
              },
              fields: 'dataValidation',
            },
          },
          newRowsQ1,
        ],
      });
    }
    if (newRowsQ1.updateCells.rows[1]?.values.length === 0) {
      payloadPutSheet.push({
        requests: [
          {
            updateCells: {
              rows: [
                {
                  values: valuesArray,
                },
              ],
              start: {
                rowIndex: 0,
                columnIndex: 0,
                sheetId: '',
              },
              fields: 'userEnteredValue',
            },
          },
        ],
      });
    }
    const payloadCreateSheet = {
      properties: {
        title: fileName,
      },
      sheets: [
        {
          properties: {
            title: 'Section',
          },
        },
      ],
    };
    const gettoken = getLocalStorage('accessToken');
    const baseUrl = JSON.parse(gettoken);
    const uploader = new MediaUploader({
      token: baseUrl?.access_token,
      filename: fileName,
      contentType: 'application/vnd.google-apps.spreadsheet',
      metadata: payloadCreateSheet,
      sheetMood: 'Edit',
      file: payloadPutSheet,
      sheetId: idSheet,
      onComplete: () => {
        setLoading(false);
        setOpenSheet(true);
        getGoogleSheet((i) => {
          const fileData = JSON?.parse(i)?.files;
          if (fileData !== undefined) {
            const sNameSheet = fileData.filter(
              (item) => item.name === fileName,
            );
            if (sNameSheet.length !== 0) {
              setIdSheet(sNameSheet[0].id);
            } else {
              setNameSheet('Sheet has not been exported');
              setIdSheet();
            }
          } else {
            setShowTokenAdvice(true);
            setLoading(false);
          }
        });
      },
      onError: () => {
        setShowTokenAdvice(true);
        setLoading(false);
      },
    });
    uploader.upload();
  };

  const renderSheet = () => (
    <Grid className={classesSheet.studentBox}>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <Grid>
          <div className={classesSheet.alertprimary} role="alert">
            {t('SHEET_EXISTS')}
          </div>
        </Grid>
      </Box>
      <Grid
        item
        xs={12}
        className={`${classesSheet.dialogButtons} ${classesSheet.popupButton}`}
      >
        <ButtonAtom
          className={classesSheet.popupBtn}
          name={t('CANCEL')}
          onClick={handleSheetDialog}
          btntype={Buttons.SECONDARY}
        />
        <ButtonAtom
          className={classesSheet.popupBtn}
          name={t('OK')}
          onClick={exportSheetExists}
          btntype={Buttons.PRIMARY}
        />
      </Grid>
    </Grid>
  );
  const renderMissingContent = () => (
    <Grid className={classes.studentBox}>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <Grid>
          <div className={classesSheet.alertprimary} role="alert">
            {t('SHEET_MISSING')}
          </div>
        </Grid>
      </Box>
      <Grid
        item
        xs={12}
        className={`${classes.dialogButtons} ${classes.popupButton}`}
      >
        <ButtonAtom
          className={classes.popupBtn}
          name={t('OK')}
          onClick={handleImportDialog}
          btntype={Buttons.PRIMARY}
        />
      </Grid>
    </Grid>
  );
  const apiFailedContent = () => (
    <Grid className={classesSheet.studentBox}>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <Grid>
          <div className={classesSheet.alertprimary} role="alert">
            {apiFailedMessage}
          </div>
        </Grid>
      </Box>
      <Grid
        item
        xs={12}
        className={`${classesSheet.dialogButtons} ${classesSheet.popupButton}`}
      >
        <ButtonAtom
          className={classesSheet.popupBtn}
          name={t('OK')}
          onClick={() => setApiFailed(false)}
          btntype={Buttons.PRIMARY}
        />
      </Grid>
    </Grid>
  );
  const missingToken = () => (
    <Grid className={classesSheet.studentBox}>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <Grid>
          <div className={classesSheet.alertprimary} role="alert">
            {t('TOKEN_EXPIRED')}
          </div>
        </Grid>
      </Box>
      <Grid
        item
        xs={12}
        className={`${classesSheet.dialogButtons} ${classesSheet.popupButton}`}
      >
        <ButtonAtom
          className={classesSheet.popupBtn}
          name={t('OK')}
          onClick={() => setShowTokenAdvice(false)}
          btntype={Buttons.PRIMARY}
        />
      </Grid>
    </Grid>
  );

  const loadFalse = () => setLoading(false);
  useEffect(() => {
    setSelectedRows([]);
    dispatch(getAssignedLocations(loadFalse));
  }, []);

  useEffect(() => {
    getGoogleSheet((i) => {
      const fileData = JSON?.parse(i)?.files;
      if (fileData !== undefined) {
        const sNameSheet = fileData.filter((item) => item.name === fileName);
        if (sNameSheet.length !== 0) {
          setLoading(false);
          setNameSheet(sNameSheet[0].name);
          setIdSheet(sNameSheet[0].id);
        } else {
          setNameSheet('Sheet has not been exported');
          setIdSheet();
        }
      } else {
        setShowTokenAdvice(true);
        setLoading(false);
      }
    });
  }, [fileName]);
  useEffect(() => {
    setLocationData(assignedLocations);
    setYearsData(assignedYears);
    const currentAcademicYear = getCurrentYear()?.id;
    const index = assignedYears.findIndex((cur) => cur.name === currentAcademicYear);
    if (index >= 0) {
      if (selectedYearRedux?.id) {
        formik.setFieldValue('academicYear', selectedYearRedux.id);
      } else {
        formik.setFieldValue('academicYear', currentAcademicYear);
      }
    }
    if (selectedLocationRedux?.value) {
      const oLocation = assignedLocations.filter((location) => location.id === selectedLocationRedux.value);
      formik.setFieldValue('locationId', oLocation[0]?.id);
    } else {
      formik.setFieldValue('locationId', assignedLocations[0]?.id);
    }
  }, [assignedLocations, assignedYears, selectedYearRedux?.id]);
  useEffect(() => { }, [rowData]);
  useEffect(() => {
    if (formik.values.locationId && formik.values.academicYear) {
      const payloadObj = {
        year: formik.values.academicYear,
        locationId: formik.values.locationId,
      };
      dispatch(getSectionDetails(payloadObj, setLoading, payloadObj));
    }
  }, [formik.values.locationId, formik.values.academicYear]);

  useEffect(() => {
    const locName = locations?.filter((item) => {
      if (item.id === formik.values.locationId) {
        return item.name;
      }
    });
    const name = `${locName[0]?.name} ${formik.values.academicYear} Section Sync Sheet .csv`;
    setFileName(name);
  }, [
    formik?.values?.locationId,
    formik?.values?.academicYear,
    formik?.values?.courseId,
    locations,
  ]);

  useEffect(() => {
    let sectionDetails = reduxStore?.sectionDetails;
    setRowData([]);
    if (sectionDetails && sectionDetails.length > 0) {
      sectionDetails = sectionDetails.map((section) => ({
        id: section?.id,
        studentInfo: `${section?.studentInfo?.firstName} ${section?.studentInfo?.lastName}`,
        date_of_birth: moment().diff(section?.dateOfBirth, 'years'),
        parent1Info: `${section?.parent1Info?.firstName} ${section?.parent1Info?.lastName}`,
        course_name: section?.enrolled_courses?.[0]?.course?.name,
        section: section?.enrolled_courses?.[0]?.google_class?.section,
        sectionId: section?.enrolled_courses?.[0]?.google_class?.id,
        courseId: section?.enrolled_courses?.[0]?.course?.id,
      }));
      const filterData = sectionDetails.filter((element) => element.id !== undefined);
      filterData.sort((a, b) => a?.section?.localeCompare(b?.section));
      setRowData(filterData);
      setOriginSort(filterData);
    }
  }, [reduxStore?.sectionDetails]);
  const openSectionDialog = (e) => {
    const payloadData = {
      locationId: formik.values.locationId,
      data: e?.row,
    };
    setPayload(payloadData);
    setIsOpen(true);
  };

  const getPayload = () => {
    const payloadData = {
      locationId: formik.values.locationId,
      data: payload?.data,
    };
    return payloadData;
  };

  function renderDropdownCell(e) {
    return (
      <p>
        {e.row.section}
        {' '}
        <KeyboardArrowDownOutlinedIcon
          className={classes.cursorPointer}
          onClick={() => openSectionDialog(e)}
        />
      </p>
    );
  }

  useEffect(() => {
    const columnsData = [
      {
        field: 'studentInfo',
        headerName: t('STUDENT_NAME'),
        align: 'left',
        headerClassName: 'commonHeader',
        cellClassName: 'commonCell',
      },
      {
        field: 'date_of_birth',
        headerName: t('AGE'),
        align: 'left',
        headerClassName: 'commonHeader',
        cellClassName: 'commonCell',
        sortable: false,
      },
      {
        field: 'parent1Info',
        headerName: t('PARENT_NAME'),
        align: 'left',
        headerClassName: 'commonHeader',
        cellClassName: 'commonCell',
      },
      {
        field: 'course_name',
        headerName: t('COURSE'),
        align: 'left',
        headerClassName: 'commonHeader',
        cellClassName: 'commonCell',
      },
      {
        field: 'section',
        headerName: t('SECTION'),
        align: 'left',
        headerClassName: 'commonHeader',
        cellClassName: 'commonCell',
        type: 'number',
        renderCell: renderDropdownCell,
      },
    ];
    setColumns(columnsData);
  }, [formik.values.academicYear]);
  const onClose = () => {
    setIsOpen(false);
  };
  const handlelocation = (data) => {
    apiRef.current.setSortModel([]);
    const { value } = data.target;
    const aLocationDataById = assignedLocations.filter((index) => {
      return index.id === value;
    });
    setLoading(true);
    setSelectedLocationn(value);
    setSelectedlocationName(aLocationDataById[0].shortName);
    dispatch(setSelectedLocation(data.target));
  };
  const handleSubmit = (studentId, section, options, courseId) => {
    const sectionId = options.filter((option) => option.section === section);
    const body = [{
      changeLog: 'Changed Section from Location Coordinator',
      courseId,
      locationId: formik.values.locationId,
      logMessage: 'Changed Section from Location Coordinator',
      sectionId: sectionId[0]?.id,
      studentId,
      isSectionChanged: true,
    }];
    setLoading(true);
    dispatch(
      moveStudent(
        body,
        setLoading,
        refreshList,
        formik.values.locationId,
        formik.values.academicYear,
        '',
        setFailed,
      ),
    );
  };

  return (
    <Box className={classes.gridContainer}>
      <Grid container item className={classes.titleRow}>
        <Grid item>
          <Typography className={classes.headerTitle}>
            {t('SECTION_SYNC')}
          </Typography>
        </Grid>
      </Grid>
      <Grid xs={12}>
        <SyncHeader
          {...{
            formik,
            fileName,
            locations,
            years,
            t,
            selectedRows,
            rowData,
            loading,
            isSelectedlocationName,
            isselectedLocation,
            idSheet,
            nameSheet,
            createAtSheetTime,
            exportSheetTime,
            importSheetTime,
          }}
          handlelocationDropDownChange={handlelocation}
          importSheet={getSheet}
          exportSheet={sendSheet}
        />
      </Grid>
      <Grid xs={12} mt={5} className={classes.manageBottomScroll}>
        {rowData.length ? (
          <DataGridProTable
            data={rowData}
            columns={columns}
            autoHeight
            hideFooter
            disableColumnFilter
            disableColumnSelector
            disableColumnMenu
            disableSelectionOnClick
            ColumnUnsorted
            ColumnSortedAscending
            ColumnSortedDescending
            onSortModelChange={onSortModelChange}
            apiRef={apiRef}
          />
        ) : (
          <Grid className={classes.noData}>
            <DoNotDisturbIcon />
            <Grid>
              <Typography variant="subtitle2" color="text.secondary">
                {t('NO_DATA')}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>

      {isOpen && (
        <ChangeSection
          open={isOpen}
          locationId={formik.values.locationId}
          academicYear={formik.values.academicYear}
          data={getPayload(payload)}
          handleSubmit={handleSubmit}
          handleClose={onClose}
        />
      )}
      <DialogAtom
        isOpen={bOpenDialog}
        dialogHeading={t('IMPORT_FROM_SHEET')}
        customClass={classes.DialogAtom}
        content={renderMissingContent()}
        secHandle={() => {
          setLoading(false);
          setOpenDialog(false);
        }}
      />
      <DialogAtom
        isOpen={bOpenImportSheet}
        dialogHeading={t('IMPORT_FROM_SHEET')}
        customClass={classes.DialogAtom}
        content={renderImportTextContent()}
        secHandle={() => {
          setLoading(false);
          setOpenImportSheet(false);
        }}
      />
      <DialogAtomSheeet
        isOpen={openSheet}
        dialogHeading={t('STUDENT_EXPORT')}
        content={renderOkContent()}
        secHandle={() => {
          setOpenSheet(false);
          setLoading(false);
        }}
      />
      <DialogAtomSheeet
        isOpen={openSheetExists}
        dialogHeading={t('STUDENT_EXPORT')}
        content={renderSheet()}
        secHandle={() => setOpenSheetExists(false)}
      />
      <DialogAtomSheeet
        isOpen={showTokenAdvice}
        dialogHeading={t('TOKEN_EXPIRED_TITLE')}
        content={missingToken()}
        secHandle={() => setShowTokenAdvice(false)}
      />
      <DialogAtomSheeet
        isOpen={isApiFailed}
        dialogHeading={t('FAILED_TO_IMPORT')}
        content={apiFailedContent()}
        secHandle={() => setApiFailed(false)}
      />
      {loading ? (
        <Grid>
          <Loader message={t('LOADING')} />
        </Grid>
      ) : null}
    </Box>
  );
}
