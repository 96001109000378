import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  Stack, Typography, TextField, Popover,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import { useNavigate } from 'react-router-dom';
import styles from './style';
import ListView from '../list-view';
import {
  createStudentHomeworkMarks,
  createStudentMarks,
  getAssignedLocations,
  updateStudentHomeworkMarks,
  updateStudentMarks,
} from '../../../store/actions/getLocationCoordinator';
import { getAllCourses, getStudentEnrollmentHistory } from '../../../store/actions/getStudent';
import useStyles from '../../../custom-hooks/useStyles';
import Dropdown from '../../../components/atoms/dropdown';
import DialogAtom from '../../../components/atoms/dialog';

import { NavigateRoutes, Buttons } from '../../../constant';
import Constant from '../../../store/constant';
import { setLocalStorage } from '../../../utils/localStorageMethod';
import ButtonAtom from '../../../components/atoms/button';
import {
  classesByYear, studentsByYearAndClassId, makeUpMarks, setSelectedClassRooms, setSelectedYear,
} from '../../../store/actions/getTeacherView';
import { StudentList } from './helper';
import BonusMarks from '../../location-coordinator/bonus-marks';
import UpdateMarks from '../../location-coordinator/update-marks';
import { Loader } from '../../../components/atoms';
import { getCurrentYear, isValidSemesterYear } from '../../../utils/methods';
import { updateCurricularActivitiesMarks } from '../../../store/actions/curricular-activities';
import { updateAssignmentsMarks } from '../../../store/actions/getAssignmentsSchema';
import { updateQuizMarks } from '../../../store/actions/quizSchema';
import { ColumnSelectIcon } from '../../../assets/svg';
import SelectColumns from '../select-columns';
import {
  getAllQuarterFields,
  getAllSemesterFields,
  getQuarterCSVHeaders,
  getSemesterCSVHeaders,
  getAllSemesterFields2023,
} from '../list-view/columns';

export default function MyClassData() {
  const { t } = useTranslation();
  const classes = useStyles(styles)();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const reduxStore = useSelector((state) => state?.getLocationCoordinator);
  const assignedYears = reduxStore?.assignedYears;
  const studentState = useSelector((state) => state?.getStudent);
  const assignedCourses = studentState?.courses;
  const teacherPanel = useSelector((state) => state?.getTeacherView);
  const [assignedClassrooms, setAssignedClassRooms] = useState([]);
  const [isColumnSelectOpen, setDialogOpen] = useState(false);
  const tableData = teacherPanel?.studentsByYearAndClassId;
  const [isselectedYear, setSelectYear] = useState();
  const [isSelectedClassRoom, setSelectedClassRoom] = useState('');
  const [isMarks, setIsMarks] = useState(false);
  const [isBonusMarks, setIsBonusMarks] = useState(false);
  const [updateMarksContent, setUpdateMarksContent] = useState();
  const [courseData, setCourseData] = useState(assignedCourses);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCourseId, setSelectedCourseId] = useState('');
  const [selectedLocationId, setSelectedLocationId] = useState('');
  const [error, setError] = useState('');
  const [updateMakupMarks, setUpdateMakupMarks] = useState(false);
  const [updateData, setData] = useState();
  const [updateTypeData, setUpdateType] = useState();
  const [updateQuarterData, setQuarter] = useState();
  const [showMakeupMarks, setShowMakeupMarks] = useState();
  const [passingCriteria, setPassingCriteria] = useState();
  const [number, setNumber] = useState();
  const [maxNumber, setMaxNumber] = useState();
  const [assignLoading, setAssignLoading] = useState(false);
  const [studentsData, setStudentsData] = useState([]);
  const [courseName, setCourseItem] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [studentListPayload, setStudentListPayload] = useState(false);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const [selectedQuarter, setSelectedQuarter] = useState('');
  const [classRoomsLoading, setClassRoomsLoading] = useState(true);
  const [studentContent, setStudentContent] = useState();
  const [validSemester, setValidSemester] = useState(false);
  const [fileHeaders, setFileHeaders] = useState([]);
  const [visibleFields, setVisibleFields] = useState([]);
  const QUARTER_OPTIONS = [
    { id: 'Q1', name: 'Q1' },
    { id: 'Q2', name: 'Q2' },
    { id: 'Q3', name: 'Q3' },
  ];

  const getSemesterOptions = (type) => {
    // eslint-disable-next-line no-nested-ternary
    return (type === 'marks') ? (courseName === 'BalaBadi - I' || courseName === 'BalaBadi - II'
      || courseName === 'Telugu-3' || courseName === 'Telugu-1') ? [{ id: 'S1', name: 'S1' }, { id: 'S2', name: 'S2' }]
      : (courseName === 'Prakasam' || courseName === 'Prabhasam' || courseName === 'Telugu-2' || courseName === 'Telugu-4') ? [{ id: 'S1', name: 'S1' }, { id: 'S2', name: 'S2' }, { id: 'S3', name: 'PSTU' }]
        : [{ id: 'S1', name: 'S1' }, { id: 'S2', name: 'S2' }, { id: 'S3', name: 'SUPPLEMENTARY' }]
      : [{ id: 'S1', name: 'S1' }, { id: 'S2', name: 'S2' }];
  };

  useEffect(() => {
    setCourseData(studentState?.courses);
  }, [studentState?.courses]);

  React.useEffect(() => {
    if (validSemester) {
      if (isselectedYear === '2023-2024') {
        setVisibleFields(getAllSemesterFields2023(t));
      } else {
        setVisibleFields(getAllSemesterFields(t));
      }
      setFileHeaders(getSemesterCSVHeaders(t));
    } else {
      setFileHeaders(getQuarterCSVHeaders(t));
      setVisibleFields(getAllQuarterFields(t));
    }
  }, [validSemester]);

  useEffect(() => {
    const courseItem = teacherPanel.classesByYear.find((item) => item.courseId === selectedCourseId);
    const students = new StudentList(tableData, courseItem?.name);
    setCourseItem(courseItem?.name);
    setStudentsData(students);
  }, [tableData]);

  useEffect(() => {
    const classRooms = teacherPanel.classesByYear?.map((obj) => ({
      id: obj?.googleClassId,
      name: `${obj?.name}(${obj?.sectionName}) - ${obj?.locationName}`,
      courseId: obj?.courseId,
      locationId: obj?.locationId,
    }));
    setAssignedClassRooms(classRooms);
  }, [teacherPanel.classesByYear]);

  useEffect(() => {
    dispatch(getAssignedLocations());
    dispatch(getAllCourses());
  }, []);

  const getEnrollDetails = async (studentData) => {
    setAssignLoading(true);
    const payload = {
      studentId: studentData?.studentId,
      courseId: selectedCourseId,
      academicYear: isselectedYear,
      quarter: studentData?.selectedQuarter,
    };
    dispatch(getStudentEnrollmentHistory(payload, setAssignLoading));
  };

  useEffect(() => {
    const currentAcademicYear = getCurrentYear()?.id;
    const index = assignedYears.findIndex((cur) => cur.name === currentAcademicYear);
    if (index >= 0) {
      if (teacherPanel?.selectedYear?.name) {
        setSelectYear(teacherPanel?.selectedYear?.name);
      } else {
        setSelectYear(assignedYears[index]?.id);
      }
    }
  }, [assignedYears, teacherPanel?.selectedYear]);

  useEffect(() => {
    setSelectedLocationId(localStorage.getItem('LocationTeacher'));
    setSelectedCourseId(localStorage.getItem('CourseTeacher'));
  }, [assignedClassrooms]);

  useEffect(() => {
    if (isselectedYear) {
      setValidSemester(isValidSemesterYear(isselectedYear));
      setLoading(true);
      setStudentsData([]);
      setAssignedClassRooms([]);
      const payload = {
        academicYear: isselectedYear,
      };
      dispatch(classesByYear(payload, setClassRoomsLoading));
    }
  }, [isselectedYear]);

  useEffect(() => {
    if (assignedClassrooms.length) {
      if (localStorage.getItem('sendAnnouncement') === 'true') {
        let defaultSelectedValue = localStorage.getItem('ClassRoomTeacher');
        if (defaultSelectedValue === '' || defaultSelectedValue === null || defaultSelectedValue === undefined) {
          defaultSelectedValue = assignedClassrooms.length > 0 ? assignedClassrooms[0]?.id : '';
        }
        setSelectedClassRoom(defaultSelectedValue);
      } else {
        const classRoomData = assignedClassrooms?.find((dat) => dat?.id === teacherPanel?.selectedClassroom?.value);
        if (classRoomData?.id) {
          setSelectedClassRoom(teacherPanel?.selectedClassroom?.value);
        } else {
          setSelectedClassRoom(assignedClassrooms?.[0]?.id);
          dispatch(setSelectedClassRooms(assignedClassrooms[0]), setLoading);
        }
      }
    }
  }, [assignedClassrooms, teacherPanel?.selectedClassroom]);

  useEffect(() => {
    const temData = teacherPanel?.classesByYear?.filter((data) => data.googleClassId === isSelectedClassRoom);
    if (teacherPanel?.selectedClassroom) {
      setSelectedLocationId(temData[0]?.locationId || localStorage.getItem('LocationTeacher'));
      setSelectedCourseId(temData[0]?.courseId || localStorage.getItem('CourseTeacher'));
    }
  }, [isSelectedClassRoom]);

  const refreshList = () => {
    const loadFalse = () => setLoading(false);
    setStudentsData([]);
    const listPayload = {
      academicYear: isselectedYear,
      googleClassId: isSelectedClassRoom,
      locationId: selectedLocationId,
      courseId: selectedCourseId,
    };
    setTimeout(() => {
      if (listPayload) {
        setStudentListPayload(listPayload);
        dispatch(studentsByYearAndClassId(listPayload, loadFalse));
      }
    }, 1000);
  };

  useEffect(() => {
    setLoading(true);
    if (isselectedYear && isSelectedClassRoom && selectedLocationId && selectedCourseId) {
      refreshList();
    }
  }, [isselectedYear, isSelectedClassRoom, selectedLocationId, selectedCourseId]);

  const handleAssignyear = (event) => {
    setSelectYear(event.target.value);
    localStorage.setItem('academicYearTeacher', event.target.value);
    localStorage.setItem('sendAnnouncement', 'false');
    setSelectedClassRoom(assignedClassrooms?.[0]?.id);
    const payload = {
      id: event.target.value,
      name: event.target.value,
    };
    dispatch(setSelectedYear(payload));
  };

  React.useEffect(async () => {
    if (studentContent) {
      setAssignLoading(true);

      const studentInfo = {
        firstName: `${updateData?.row?.studentInfo?.firstName} ${updateData?.row?.studentInfo?.middleName} ${updateData?.row?.studentInfo?.lastName}`,
        courseTo: selectedCourseId,
        studentId: updateData?.row?.studentId,
        academicYear: isselectedYear,
        selectedQuarter,
        location: selectedLocationId,
        quarterOptions: validSemester ? getSemesterOptions(updateTypeData) : QUARTER_OPTIONS,
        updateType: updateTypeData,
        validSemester,
        quizName: 'Quiz 1',
      };
      setUpdateMarksContent(studentInfo);
      await getEnrollDetails(studentInfo).finally(() => {
      });
    }
  }, [selectedQuarter]);

  const marksOpen = async (data, updateType, quarter) => {
    setAssignLoading(true);

    if (updateType === 'bonus') {
      const studentInfo = {
        courseId: selectedCourseId,
        studentId: data?.row?.studentId,
        academicYear: isselectedYear,
        locationId: selectedLocationId,
      };
      setUpdateMarksContent(studentInfo);
      setIsBonusMarks(true);
      setAnchorEl(null);
      return;
    }

    const courses = assignedCourses.map((e) => ({
      id: e?.id,
      name: e?.name,
    }));
    const studentInfo = {
      firstName: `${data?.row?.studentInfo?.firstName} ${data?.row?.studentInfo?.middleName} ${data?.row?.studentInfo?.lastName}`,
      courseTo: selectedCourseId,
      studentId: data?.row?.studentId,
      academicYear: isselectedYear,
      selectedQuarter: quarter,
      location: selectedLocationId,
      courses,
      quarterOptions: validSemester ? getSemesterOptions(updateType) : QUARTER_OPTIONS,
      updateType,
      validSemester,
      quizName: 'Quiz 1',
    };
    await getEnrollDetails(studentInfo).finally(() => {
      setUpdateMarksContent(studentInfo);
      setIsMarks(true);
      setAnchorEl(null);
    });
  };

  const checkboxChanged = (selectedCheckoxes) => {
    setSelectedRows(selectedCheckoxes);
    setError('');
  };

  const marksClose = () => {
    setIsMarks(false);
  };

  const bonusMarksClose = () => {
    setIsBonusMarks(false);
    setUpdateMarksContent('');
  };

  const updateMarks = (payload) => {
    setStudentsData([]);
    const loadFalse = () => {
      refreshList(studentListPayload);
      setIsMarks(false);
      setLoading(true);
    };
    if (payload.updateType === 'marks') {
      dispatch(updateStudentMarks(payload, loadFalse));
    } else if (payload.updateType === 'homework-marks') {
      dispatch(updateStudentHomeworkMarks(payload, loadFalse));
    } else if (payload.updateType === 'curricular-marks') {
      dispatch(updateCurricularActivitiesMarks(payload, loadFalse));
    } else if (payload.updateType === 'assignments') {
      dispatch(updateAssignmentsMarks(payload, loadFalse));
    } else if (payload.updateType === 'quiz1-marks') {
      dispatch(updateQuizMarks(payload, loadFalse));
    } else if (payload.updateType === 'quiz2-marks') {
      dispatch(updateQuizMarks(payload, loadFalse));
    }
  };

  const createMarks = (payload) => {
    setStudentsData([]);
    const loadFalse = () => {
      refreshList(studentListPayload);
      setIsMarks(false);
      setLoading(true);
    };
    if (payload.updateType === 'marks') {
      dispatch(createStudentMarks(payload, loadFalse));
    } else {
      dispatch(createStudentHomeworkMarks(payload, loadFalse));
    }
  };

  const sendBulkEmail = () => {
    if (selectedRows.length > 0) {
      setError('');
      const emailList = [];
      const emailParents = [];
      selectedRows?.forEach((row) => {
        emailList.push(row?.studentInfo?.manabadiEmail);
        emailParents.push(row?.parent1Info?.personalEmail?.toString());
        emailParents.push(row?.parent2Info?.personalEmail?.toString());
      });
      dispatch({ type: Constant.RECIPIENTS, payload: emailList });
      dispatch({ type: Constant.MAIL_PARENTS, payload: emailParents });
      dispatch({ type: Constant.MAIL_FILTER, payload: 'Student' });
      setLocalStorage('selectedYear', isselectedYear);
      setLocalStorage('showSelectAllinEmail', false);
      setLocalStorage('ClassRoomTeacher', isSelectedClassRoom);

      if (selectedRows.length > 0) {
        setLocalStorage('showLocationFilterRecipients', false);
        setLocalStorage('showLocationAnnouncementsRecipients', false);
      } else {
        setLocalStorage('showLocationFilterRecipients', false);
        setLocalStorage('showLocationAnnouncementsRecipients', false);
      }
      navigate(NavigateRoutes.TEACHER_VIEW_EMAIL);
    } else {
      setError(t('SELECT_ATLEAST_ONE_STUDENT'));
    }
  };

  const setOpenDialog = (event, dataUpdate, dataUpdateType, dataQuarterUpdate) => {
    const courseItem = teacherPanel.classesByYear.find((item) => item.courseId === selectedCourseId);
    if (courseItem?.name === 'BalaBadi - I' || courseItem?.name === 'BalaBadi - II') {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(event.currentTarget);
    }
    if (dataUpdateType === 'marks') {
      setShowMakeupMarks(dataUpdate?.row?.marks[dataQuarterUpdate]?.obtainedMarks);
      setPassingCriteria(dataUpdate?.row?.marks[dataQuarterUpdate]?.passingCriteria);
    } else {
      setShowMakeupMarks(dataUpdate?.row?.homeworkMarks[dataQuarterUpdate]?.obtainedMarks);
    }
    setData(dataUpdate);
    setUpdateType(dataUpdateType);
    setQuarter(dataQuarterUpdate);
  };

  const handleUpdateMakupMarksClose = () => {
    setUpdateMakupMarks(false);
  };

  const handleUpdateMakupMarksOpen = () => {
    const maxAddNumber = passingCriteria - showMakeupMarks;
    setMaxNumber(parseFloat(maxAddNumber).toFixed(1));
    setUpdateMakupMarks(true);
    setAnchorEl(null);
    setNumber('');
  };

  const validateNumber = (event) => {
    const value = parseFloat(event.target.value) || event.target.value;
    const setValue = value <= maxNumber ? event.target.value : number;
    const num = setValue.split('.');
    if (num.length > 2) return;
    if (/^\d+$/.test(num[0])) {
      if (num[1]) {
        if (/^\d+$/.test(num[1])) {
          if (num[1].length > 2) {
            setNumber(Number(setValue).toFixed(2));
          } else {
            setNumber(setValue);
          }
        }
      } else {
        setNumber(setValue);
      }
    } else if (setValue === '') {
      setNumber(setValue);
    }
  };

  const handleUpdateMakupMarks = (updateMakeUpData) => {
    if (number) {
      const payload = {
        courseId: selectedCourseId,
        studentId: updateMakeUpData?.row?.studentId,
        academicPanelId: updateMakeUpData?.row?.marks[updateQuarterData]?.academicPanelId,
        academicYear: isselectedYear,
        quarter: updateQuarterData,
        makeUpMarks: Number(number),
      };
      dispatch(makeUpMarks(payload));
      setUpdateMakupMarks(false);
      setTimeout(() => {
        refreshList(studentListPayload);
      }, 2000);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const renderUpdateMakupMarksContent = () => (
    <Grid>
      <Typography>
        {t('MAKEUP_MARKS')}
        {' '}
        (Max:
        {' '}
        {maxNumber}
        ):
      </Typography>
      <div className={classes.addMakupMarksPopup}>
        <TextField
          id="outlined-number"
          onChange={validateNumber}
          value={number}
          error={number < 0}
          helperText={number < 0 ? 'Should be greater than 0' : ''}
          InputProps={
            {
              inputProps: {
                min: 0,
                max: maxNumber,
                pattern: '[0-9]*',
                step: '1',
                inputMode: 'numeric',
              },
            }
          }
          onKeyDown={(event) => {
            if ((event.key === '+' || event.key === '-' || event.key === 'E' || event.key === 'e')) {
              event.preventDefault();
            }
          }}
          required
        />
      </div>
      <Grid item xs={12}>
        <div style={{ display: 'flex', justifyContent: 'end', padding: '40px 0 15px' }}>
          <div className={classes.popupBtn}>
            <ButtonAtom
              className={classes.popupBtn}
              name={t('CANCEL')}
              btntype={Buttons.SECONDARY}
              onClick={() => {
                setUpdateMakupMarks(false);
              }}
            />
          </div>
          <div className={number < 0 ? classes.popupBtnDisable : ''}>
            <ButtonAtom
              name={t('SAVE')}
              btntype={Buttons.PRIMARY}
              onClick={() => handleUpdateMakupMarks(updateData)}
            />
          </div>
        </div>
      </Grid>
    </Grid>
  );

  return (
    <div className={classes.gridPadding}>
      <div className={classes.divAlignment}>
        <Stack sx={{ width: '100%' }} spacing={4}>
          <Grid container flexDirection="row" display="flex" className={`${classes.divWrapper}`}>
            <Grid container>
              <Grid container item className={classes.titleRow}>
                <Grid item>
                  <Typography className={classes.headerTitle}>
                    {t('MY_CLASS')}
                  </Typography>
                </Grid>
                <Grid item className={classes.HeaderWrapper}>
                  <div className={classes.dropdownWrap}>
                    <div className={classes.assignyear}>
                      <Dropdown
                        minWidth="100%"
                        id="myClass"
                        variant="standard"
                        options={assignedYears}
                        value={isselectedYear}
                        customClass={classes.dropdown}
                        changeCss
                        customFormControlCss={{ width: '100%' }}
                        labelId="myClass"
                        handleChange={handleAssignyear}
                      />
                    </div>
                    <div className={classes.googleClasses}>
                      <Dropdown
                        minWidth="100%"
                        id="googleClass"
                        variant="standard"
                        options={assignedClassrooms}
                        value={isSelectedClassRoom}
                        customClass={classes.dropdown}
                        changeCss
                        customFormControlCss={{ width: '100%' }}
                        labelId="myClass"
                        handleChange={(e) => {
                          setSelectedClassRoom(e.target.value);
                          dispatch(setSelectedClassRooms(e.target));
                          const classRoom = assignedClassrooms.filter((obj) => obj.id === e.target.value);
                          setSelectedCourseId(classRoom?.[0]?.courseId);
                          setSelectedLocationId(classRoom?.[0]?.locationId);
                          localStorage.setItem('ClassRoomTeacher', e.target.value);
                          localStorage.setItem('CourseTeacher', classRoom?.[0]?.courseId);
                          localStorage.setItem('LocationTeacher', classRoom?.[0]?.locationId);
                          localStorage.setItem('sendAnnouncement', 'false');
                        }}
                      />
                    </div>
                  </div>

                  <div className={classes.dropdownWrap}>
                    <div className={classes.rightIcons}>
                      {(!classRoomsLoading && assignedClassrooms.length > 0) && (
                        <Grid container justifyContent="flex-end" spacing={2}>
                          <Grid item className={classes.gridActions} onClick={sendBulkEmail}>
                            <Tooltip title={t('SEND_EMAIL')} placement="right">
                              <EmailOutlinedIcon className={classes.icon} />
                            </Tooltip>
                          </Grid>
                          <Grid item className={classes.gridActions} onClick={() => setDialogOpen(true)}>
                            <Tooltip title={t('UPDATE_SETTINGS')} placement="right">
                              <ColumnSelectIcon customClass={classes.iconn} strokeColor="#104F96" />
                            </Tooltip>
                          </Grid>
                        </Grid>
                      )}
                    </div>
                  </div>

                </Grid>
                <div container spacing={2} direction="row" display="flex" alignItems="center" className={classes.errorText}>
                  <span className={classes.errorText}>{error}</span>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid container className={classes.resourceContainer}>
            <Grid container className={classes.resourcesListView}>
              {
                (!classRoomsLoading && assignedClassrooms.length > 0) && (
                  <ListView
                    {...{
                      refreshList,
                      studentsData,
                      setCourseData,
                      courseData,
                      checkboxChanged,
                      loading,
                      setError,
                      setOpenDialog,
                      courseName,
                      isselectedYear,
                      visibleFields,
                    }}
                  />
                )
              }
            </Grid>
          </Grid>
          <Grid>
            {(classRoomsLoading && assignedClassrooms.length === 0) && (
              <Grid className={classes.noData}>
                <Loader message={t('LOADING')} />
              </Grid>
            )}
            {(!classRoomsLoading && assignedClassrooms.length === 0) && (
              <Grid className={classes.noData}>
                <DoNotDisturbIcon />
                <Grid>
                  <Typography variant="subtitle2" color="text.secondary">
                    {t('NO_CLASSES_AVAILABLE')}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
          <DialogAtom
            isOpen={isMarks}
            secHandle={() => marksClose(false)}
            dialogHeading={t('ASSIGN_SCORE')}
            customClass={classes.marksDialog}
            content={(
              <UpdateMarks
                {...{ updateMarks, courseData, createMarks }}
                studentData={updateMarksContent}
                handleClose={() => marksClose(false)}
                assignLoading={assignLoading}
                setSelectedQuarter={setSelectedQuarter}
                selectedQuarter={selectedQuarter}
                setStudentContent={setStudentContent}
                setStudentData={setUpdateMarksContent}
                getEnrollDetails={getEnrollDetails}
              />
            )}
          />
          <DialogAtom
            customClass={classes.columnSettingDialog}
            isOpen={isColumnSelectOpen}
            dialogHeading={t('COLUMN_SETTINGS')}
            content={(
              <SelectColumns
                {...{
                  classes,
                  setDialogOpen,
                  visibleFields,
                  setVisibleFields,
                  fileHeaders,
                  setFileHeaders,
                  validSemester,
                  isselectedYear,
                }}
              />
            )}
            secHandle={() => setDialogOpen(false)}
          />
          <DialogAtom
            isOpen={isBonusMarks}
            secHandle={() => bonusMarksClose()}
            dialogHeading={t('ASSIGN_BONUS_MARKS')}
            customClass={classes.marksDialog}
            content={(
              <BonusMarks
                {...{ updateData }}
                studentData={updateMarksContent}
                handleClose={() => bonusMarksClose()}
                afterSave={() => {
                  refreshList();
                  bonusMarksClose();
                }}
              />
            )}
          />
          <DialogAtom
            isOpen={updateMakupMarks}
            secHandle={() => handleUpdateMakupMarksClose(false)}
            dialogHeading={t('ADD_MAKUP_MARKS')}
            content={renderUpdateMakupMarksContent()}
          />
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            sx={{ left: '3%' }}
          >
            {updateTypeData === 'marks' && (showMakeupMarks >= (passingCriteria - 10) && showMakeupMarks < passingCriteria
              && !validSemester) && (
                <div onClick={() => handleUpdateMakupMarksOpen()}>
                  <Typography sx={{
                    p: 2,
                    cursor: 'pointer',
                    fontSize: '12px',
                    borderBottom: '0.1vw solid #d0d0d0',
                  }}
                  >
                    {t('UPDATE_MAKEUP_MARKS')}
                  </Typography>
                </div>
            )}
            <div onClick={() => marksOpen(updateData, updateTypeData, updateQuarterData)}>
              <Typography sx={{ p: 2, cursor: 'pointer', fontSize: '12px' }}>
                {t('UPDATE_MARKS')}
              </Typography>
            </div>
          </Popover>
        </Stack>
      </div>
    </div>
  );
}
