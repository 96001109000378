import { ApiRoutes } from '../../constant';
import { APIROUTES } from '../../constant/routes';
import request from '../../utils/request';
// Login services
export const getLoginTokenService = (body) => request.post(`${ApiRoutes.GET_TOKEN_FOR_LOGIN}`, body);
export const postForgotPasswordService = (body) => request.post(`${ApiRoutes.POST_FORGOT_PASSWORD}`, body);
export const resetPasswordService = (body) => request.post(`${ApiRoutes.POST_RESET_PASSWORD}`, body);

// parent services
export const getEmailAvailabilityService = (email) => request.get(`${ApiRoutes.GET_EMAIL_AVAILABILITY}/?email=${email}`);
export const getVolunteerService = () => request.get(`${ApiRoutes.GET_VALUE_BY_TYPE}${ApiRoutes.GET_VOLUNTEERS}`);
export const getHearAboutUsService = () => request.get(`${ApiRoutes.GET_VALUE_BY_TYPE}${ApiRoutes.HEAR_ABOUTS_US}`);
export const editParentService = (studentId, payload) => request.post(`${ApiRoutes.EDIT_PARENT}/${studentId}`, payload);
export const updateParentInfoByStudentService = (body) => request.put(`${ApiRoutes.UPDATE_PARENT_INFO}`, body);

// student services
export const getTShirtService = () => request.get(`${ApiRoutes.GET_VALUE_BY_TYPE}/tshirt`);
export const getAllCourseService = () => request.get(`${ApiRoutes.GET_ALL_COURSES}`);
export const getAllTopicsService = () => request.get(`${ApiRoutes.GET_ALL_TOPICS}`);
export const getCourseByAgeLocationService = (body) => request.post(`${ApiRoutes.GET_COURSES_AGE_LOCATION}`, body);
export const getCourseLocationService = (body) => request.post(`${ApiRoutes.GET_LOCATION_COURSE}`, body);
export const getCoursesForBacklogRegistratrion = (body) => request.post(`${ApiRoutes.GET_LOCATION_COURSE_BACKLOG_REGISTRATION}`, body);
export const getEditExamDetails = (body) => request.post(`${ApiRoutes.GET_EDIT_EXAM_DETAILS}`, body);
export const getActiveExamCenters = (body) => request.get(`${ApiRoutes.GET_ACTIVE_EXAM_CENTERS}`, body);
export const getUpdateExamCenter = (body) => request.post(`${ApiRoutes.UPDATE_STUDENT_EXAM_CENTER}`, body);
export const getAdminPaginatedStudentsService = (pageNumber, pageLimit, body) => request.post(
  `${ApiRoutes.GET_ALL_STUDENTS}?pageNo=${pageNumber}&&pageLimit=${pageLimit}`,
  body,
);
export const getAcademicGradesService = () => request.get(`${ApiRoutes.GET_VALUE_BY_TYPE}${ApiRoutes.GET_ACADEMIC_GRADES}`);
export const editStudentByAdminService = (studentId, body) => request.post(`${ApiRoutes.EDIT_STUDENT}/${studentId}`, body);
export const updateStudentInfoService = (body) => request.patch(`${ApiRoutes.UPDATE_STUDENT_INFO}`, body);
export const updateSiblingInfoService = (body, siblingId) => request.patch(`${ApiRoutes.UPDATE_SIBLING_INFO}/${siblingId}`, body);

export const getExtraCurricularActivitiesService = () => request.get(
  `${ApiRoutes.GET_VALUE_BY_TYPE}${ApiRoutes.GET_EXTRA_CURRICULAR_ACTIVITIES}`,
);
export const getLocationService = () => request.get(`${ApiRoutes.GET_LOCATIONS}`);
export const getAllLocationsByCourseService = (id) => request.get(`${ApiRoutes.GET_LOCATIONS}/?courseId=${id}`);
export const getStudentsByAdminService = (body) => request.post(`${ApiRoutes.GET_ALL_STUDENTS}`, body);
export const getStudentsByRegionService = (pageNumber, pageLimit, body) => request.post(
  `${ApiRoutes.GET_STUDENTS_BY_REGION}?pageNo=${pageNumber}&&pageLimit=${pageLimit}`,
  body,
);
export const getStudentLogDetailService = (logId, isEmailLog, isAcademicDates, isRegistrationDates, isAddTeacher, isAddSection) => request.get(`${ApiRoutes.GET_STUDENT_LOG_DETAIL}/${logId}/${isEmailLog}/${isAcademicDates}/${isRegistrationDates}/${isAddTeacher}/${isAddSection}`);
export const getStudentLogsService = (studentId, logVal, isAcademicDates, isRegistrationDates, isAddTeacher, isAddSection) => request.get(`${ApiRoutes.GET_STUDENT_LOGS}/${studentId}/${logVal}/${isAcademicDates}/${isRegistrationDates}/${isAddTeacher}/${isAddSection}`);
export const moveStudentService = (body) => request.post(`${ApiRoutes.MOVE_STUDENT}`, body);
export const getStudentSectionsService = (payload) => request.get(
  `${ApiRoutes.GET_STUDENT_SECTIONS}/${payload.locationId}/${payload.courseId}/${payload.academicYear}`,
);
export const getStudentAccountDetailService = () => request.get(`${ApiRoutes.GET_STUDENT_ACCOUNT_DETAILS}`);
export const getStudentDetailsByYearService = (payload) => request.post(`${ApiRoutes.GET_STUDENT_DETAILS_BY_YEAR}`, payload);
export const addSiblingService = (body) => request.post(`${ApiRoutes.ADD_SIBLING}`, body);
export const addFilterViewService = (body) => request.post(`${ApiRoutes.ADD_FILTER_VIEW}`, body);
export const deleteFilterViewService = (id) => request.delete(`${ApiRoutes.ADD_FILTER_VIEW}/${id}`);
export const getAllFilterViewService = () => request.get(`${ApiRoutes.GET_FILTER_VIEWS}`);
export const getAllFilterService = (body) => request.post(`${ApiRoutes.GET_ALL_FILTERS}`, body);
export const getAllFilterServiceRegion = (body) => request.post(`${ApiRoutes.GET_ALL_FILTERS_REGION}`, body);
export const getLocationsByCourseId = (courseId) => request.get(`${ApiRoutes.GET_LOCATIONS_BY_COURSE}/${courseId}`);
export const getLocationsByRegionService = () => request.get(`${ApiRoutes.GET_LOCATIONS_BY_REGION}`);
export const getNextEnrollCourseAdminService = (body) => request.post(`${ApiRoutes.GET_NEXT_ENROLL_COURSE}`, body);
export const getStudentMarksService = (studentId) => request.get(`${ApiRoutes.GET_STUDENT_MARKS}/${studentId}`);
export const getStudentEnrollmentService = (payload) => request.post(`${ApiRoutes.GET_ENROLLMENT_HISTORY}`, payload);
export const getStudentExamDetails = (body) => request.post(`${ApiRoutes.GET_STUDENT_EXAM_DETAILS}`, body);
// payment services
export const getDonationService = () => request.get(`${ApiRoutes.GET_VALUE_BY_TYPE}${ApiRoutes.GET_DONATIONS}`);
export const updateTempRegLogService = (body) => request.post(`${ApiRoutes.PUT_TEMP_REGISTER_LOG}`, body);
export const postRegisterService = (body) => request.post(`${ApiRoutes.POST_REGISTER}`, body);
export const postFeeStructureService = (body) => request.post(`${ApiRoutes.POST_FEE_STRUCTURE}`, body);
export const postFeeStructureServiceSpotReg = (body) => request.post(`${ApiRoutes.POST_FEE_STRUCTURE_SPOT_REG}`, body);
export const createLinkService = (body) => request.post(`${ApiRoutes.CREATE_ORDER}`, body);
export const donationService = (body) => request.post(`${ApiRoutes.CREATE_DONATION_ORDER}`, body);
export const websiteDonationService = (body) => request.post(`${ApiRoutes.CREATE_WEBSITE_DONATION_ORDER}`, body);

export const captureOrderService = (body) => request.post(`${ApiRoutes.CAPTURE_ORDER}`, body);
export const captureDonationService = (body) => request.post(`${ApiRoutes.CAPTURE_DONATION}`, body);
export const captureWebsiteDonationService = (body) => request.post(`${ApiRoutes.CAPTURE_WEBSITE_DONATION}`, body);

export const getStudentPaymentInfoService = (id) => request.get(`${ApiRoutes.GET_STUDENT_PAYMENT_INFO}/${id}`);

export const getFeeStructureForEnrollService = (body) => request.post(`${ApiRoutes.GET_FEE_FOR_ENROLL}`, body);

// location coordinator services
export const saveImportExportSheetTime = (body) => request.post(`${ApiRoutes.SAVE_IMPORT_EXPORT_TIME}`, body);
export const getImportSheetTime = (body) => request.post(`${ApiRoutes.GET_IMPORT_EXPORT_TIME}`, body);
export const getExportSheetTime = (body) => request.post(`${ApiRoutes.GET_IMPORT_EXPORT_TIME}`, body);

export const getClassroomsService = (body) => request.post(`${ApiRoutes.GET_CLASSROOMS}`, body);
export const createSectionsByLocationYearCourseService = (body) => request.post(`${ApiRoutes.ADD_CLASSROOMS_SECTIONS}`, body);
export const addTeacherService = (body) => request.post(`${ApiRoutes.ADD_TEACHER}`, body);
export const getAcademicYearsService = () => request.get(`${ApiRoutes.GET_ACADEMIC_YEARS}`);
export const getAcademicYearsServiceStatus = () => request.get(`${ApiRoutes.GET_ACADEMIC_YEARS_STATUS}`);
export const postAcademicYearsServiceStatus = (body) => request.post(`${ApiRoutes.POST_ACADEMIC_YEARS_STATUS}`, body);
export const getAcademicYearsInfoService = () => request.get(`${ApiRoutes.GET_ACADEMIC_YEARS_INFO}`);
export const getCurrentAcademicYearService = () => request.get(`${ApiRoutes.GET_CURRENT_ACADEMIC_YEAR}`);
export const getCurrentDobCutoffService = () => request.get(`${ApiRoutes.GET_CURRENT_DOB_CUTOFF}`);
export const getRegistrationAcademicYearDatesService = () => request.get(`${ApiRoutes.GET_REGISTRATION_ACADEMIC_YEAR}`);
export const getTempRegisterInfoService = (registrationId) => request.get(`${ApiRoutes.GET_REGISTRATION_TEMP_INFO}/${registrationId}`);

export const getAssignedLocationsService = () => request.get(`${ApiRoutes.GET_ASSIGNED_LOCATIONS}`);
export const getAllTeachersService = () => request.get(`${ApiRoutes.GET_ALL_TEACHERS}`);
export const getAssignedYearsDetailsService = (body) => request.post(`${ApiRoutes.GET_ACADEMIC_INFO}`, body);
export const getAcademicInfoById = (body) => request.post(`${ApiRoutes.GET_ACADEMIC_INFO_ID}`, body);
export const getClassroomById = (body) => request.post(`${ApiRoutes.GET_GOOGLE_CLASS_INFO_ID}`, body);
export const postAcademicInfoService = (body) => request.post(`${ApiRoutes.POST_ACADEMIC_INFO}`, body);
export const updateAcademicInfoService = (body) => request.post(`${ApiRoutes.UPDATE_ACADEMIC_INFO}`, body);
export const getStudentsByLocationService = (body) => request.post(`${ApiRoutes.GET_STUDENTS_BY_LOCATION}`, body);
export const getTeacherResourceService = (body) => request.post(`${ApiRoutes.GET_TEACHER_RESOURCE}`, body);
export const getTeacherResourcePaginationService = (pageNumber, pageLimit, body) => request.post(`${ApiRoutes.GET_TEACHER_RESOURCE}?pageNo=${pageNumber}&&pageLimit=${pageLimit}`, body);
export const getStudentResourceWRTCourseService = (body) => request.post(`${ApiRoutes.GET_STUDENT_RESOURCE_WRTCOURCE}`, body);
export const getStudentResourceCourseService = (pageNumber, pageLimit, body) => request.post(`${ApiRoutes.GET_STUDENT_RESOURCE_WRTCOURCE}?pageNo=${pageNumber}&&pageLimit=${pageLimit}`, body);
export const updateMarksService = (body) => request.put(`${ApiRoutes.UPDATE_STUDENT_MARKS}`, body);
export const updateHomeworkMarksService = (body) => request.put(`${ApiRoutes.UPDATE_HOMEWORK_MARKS}`, body);

export const saveBonusMarksService = (body) => request.post(`${ApiRoutes.SAVE_STUDENT_BONUS_MARKS}`, body);

export const createMarksService = (body) => request.post(`${ApiRoutes.UPDATE_STUDENT_MARKS}`, body);
export const createHomeworkMarksService = (body) => request.post(`${ApiRoutes.UPDATE_HOMEWORK_MARKS}`, body);
export const getLocationDetailsService = (body) => request.post(`${ApiRoutes.GET_LOCATION_STUDENT_DASHBOARD}`, body);
export const getHelpVideosService = (userRole) => request.get(`${ApiRoutes.GET_HELP_VIDEOS}/?type=${userRole}`);
export const getHelpVideosPaginationService = (userRole, pageNumber, pageLimit) => request.get(`${ApiRoutes.GET_HELP_VIDEOS}/?type=${userRole}&&pageNo=${pageNumber}&&pageLimit=${pageLimit}`);
export const getTeachersService = (body) => request.post(`${ApiRoutes.GET_TEACHERS}`, body);

// User Manager Services

export const getAllUserService = () => request.post(`${ApiRoutes.GET_ALL_USER}`);
export const getAllUserServicePaginated = (pageNumber, pageLimit, body) => request.post(
  `${ApiRoutes.GET_ALL_USER}?pageNo=${pageNumber}&&pageLimit=${pageLimit}`,
  body,
);
export const getAssignedUserRolesServices = () => request.get(`${ApiRoutes.GET_ASSIGNED_USER_ROLES}`);
export const getUserRolesService = () => request.get(`${ApiRoutes.GET_ALL_USER_ROLES}`);
export const addAddUserService = (body) => request.post(`${ApiRoutes.ADD_USER}`, body);
export const updateUserInfoService = (userId, body) => request.post(`${ApiRoutes.EDIT_USER}/${userId}`, body);
export const getAllFilterUserManagerService = (body) => request.post(`${ApiRoutes.GET_FILTER_LIST_USER_MANAGER}`, body);
export const getAllEmailUsersService = () => request.get(`${ApiRoutes.GET_ALL_EMAIL_USER}`);

export const getEmailTemplateService = () => request.get(`${ApiRoutes.EMAIL_TEMPLATE}`);
export const saveEmailTemplateService = (body) => request.post(`${ApiRoutes.EMAIL_TEMPLATE}`, body);
export const getSectionDetailsService = (body) => request.post(`${ApiRoutes.GET_SECTION_DETAILS}`, body);
export const getAnnouncementListService = (payload, pageNumber, pageLimit) => request.post(`${ApiRoutes.GET_ANNOUNCEMENT_LIST}?pageNo=${pageNumber}&&pageLimit=${pageLimit}`, payload);
export const getAnnouncementByIdService = (id) => request.get(`${ApiRoutes.GET_ANNOUNCEMENT_BY_ID}/${id}`);
export const getAnnouncementListServiceByUserID = (payload, pageNumber, pageLimit) => request.post(`${ApiRoutes.GET_ANNOUNCEMENT_LIST_RC}?pageNo=${pageNumber}&&pageLimit=${pageLimit}`, payload);

// Admin Services
export const getParentService = (id) => request.get(`${ApiRoutes.GET_PARENT}/${id}`);
export const getStudentService = (body) => request.post(`${ApiRoutes.GET_STUDENT}`, body);
export const getPaymentService = (body) => request.post(`${ApiRoutes.GET_PAYMENT}`, body);

export const uploadVideoService = (body) => request.post(`${ApiRoutes.GET_UPLOADVIDEO}?makePublic=true`, body);
export const uploadHelpVideoService = (body) => request.post(`${ApiRoutes.ADD_HELP_VIDEO}`, body);
export const uploadFileService = (body) => request.post(`${ApiRoutes.GET_UPLOADFILE}?makePublic=true`, body);
export const uploadFileEmailService = (body) => request.post(`${ApiRoutes.GET_UPLOADFILE_EMAIL}?makePublic=true`, body);
export const sendAnnouncementService = (body) => request.post(`${ApiRoutes.SEND_ANNOUNCEMENT}`, body);
export const getEmailFilterService = (screenType) => request.get(`${ApiRoutes.GET_EMAIL_FILTERS}/${screenType}`);
export const getRecipientsByFilterService = (body) => request.post(`${APIROUTES.GET_RECIPIENTS_BY_FILTER}`, body);
export const createStudentResourceService = (body) => request.post(`${ApiRoutes.CREATE_STUDENT_RESOURCE}`, body);
export const cancelEnrollService = (body) => request.post(`${ApiRoutes.CANCEL_ENROLLMENT}`, body);
export const deactivateResourceService = (body) => request.post(`${ApiRoutes.DEACTIVATE_RESOURCE}`, body);
export const updateResourceService = (body) => request.post(`${ApiRoutes.UPDATE_RESOURCE}`, body);
export const impersonateUserService = (body) => request.post(`${ApiRoutes.IMPERSONATE_USER}`, body);
export const updateHelpVideoService = (body) => request.post(`${ApiRoutes.UPDATE_HELP_VIDEO}`, body);
export const deactivateHelpVideosService = (body) => request.post(`${ApiRoutes.DEACTIVATE_HELP_VIDEO}`, body);
export const getStudentResourceInFolderService = (body) => request.post(`${ApiRoutes.GET_STUDENT_RESOURCE_WRTCOURCE}`, body);

export const getTeacherResourceInFolderService = (body) => request.post(`${ApiRoutes.GET_TEACHER_RESOURCE}`, body);
export const studentMarksByQuarter = (body) => request.post(`${ApiRoutes.POST_STUDENT_MARKS_BY_QUARTER}`, body);
export const getRegionManagerLogsService = (regionId) => request.get(`${ApiRoutes.GET_REGION_MANAGER_LOGS}/${regionId}`);
export const getRegionManagerLogDetailService = (logId) => request.get(`${ApiRoutes.GET_REGION_MANAGER_LOGS_DETAIL}/${logId}`);

export const getLocationManagerLogsService = (locationId) => request.get(`${ApiRoutes.GET_LOCATION_MANAGER_LOGS}/${locationId}`);
export const getLocationManagerLogDetailService = (logId) => request.get(`${ApiRoutes.GET_LOCATION_MANAGER_LOGS_DETAIL}/${logId}`);

export const getCourseManagerLogsService = (courseId) => request.get(`${ApiRoutes.GET_COURSE_MANAGER_LOGS}/${courseId}`);
export const getCourseManagerLogDetailService = (logId) => request.get(`${ApiRoutes.GET_COURSE_MANAGER_LOGS_DETAIL}/${logId}`);
export const exportStudentData = (body) => request.post(`${ApiRoutes.STUDENT_EXPORT}`, body);

export const getExamCenterManagerLogsService = (examcenterId) => request.get(`${ApiRoutes.GET_EXAM_CENTER_MANAGER_LOGS}/${examcenterId}`);
export const getExamCenterManagerLogDetailService = (logId) => request.get(`${ApiRoutes.GET_EXAM_CENTER_MANAGER_LOGS_DETAIL}/${logId}`);

export const getExportedSheets = () => request.get(`${ApiRoutes.GET_EXPORTED_SHEETS}`);
export const addExportedSheets = (body) => request.post(`${ApiRoutes.ADD_EXPORTED_SHEETS}`, body);
// Substitution Tags
export const getSubstitutionTagsService = () => request.get(`${ApiRoutes.GET_SUBSTITUTION_TAGS}`);

// Role Manager Services
export const getAllRoleService = () => request.get(`${ApiRoutes.GET_ALL_ROLE}`);
export const getAllRolePermission = () => request.get(`${ApiRoutes.GET_ALL_ROLE_PERMISSIONS}`);
export const addRoleService = (payload) => request.post(`${ApiRoutes.GET_ALL_ROLE}`, payload);
export const updateRoleService = (roleId, payload) => request.put(`${ApiRoutes.GET_ALL_ROLE}/${roleId}`, payload);
export const getRoleChangeLogsListService = () => request.get(`${ApiRoutes.GET_ROLE_CHANGE_LOGS_LIST}`);
export const getRoleChangeLogsDetailsService = (logId) => request.get(`${ApiRoutes.GET_ROLE_CHANGE_LOGS_DETAILS}/${logId}`);

// Region Manager Services
export const getAllRegionService = (pageNumber, pageLimit, searchText) => request.get(`${ApiRoutes.GET_ALL_REGION}?pageNo=${pageNumber}&&pageLimit=${pageLimit}&&searchText=${searchText}`);
export const getActiveRegionService = () => request.get(`${ApiRoutes.GET_ACTIVE_REGION}`);

export const getCountriesService = () => request.get(`${ApiRoutes.GET_COUNTRIES}`);
export const getRegionCordinatorsService = () => request.get(`${ApiRoutes.GET_REGION_COORDINATORS}`);
export const getGeoRegionService = (payload) => request.post(`${ApiRoutes.GET_GEO_REGION}`, payload);
export const addRegionService = (payload) => request.post(`${ApiRoutes.ADD_REGION_DATA}`, payload);
export const editRegionService = (regionId, payload) => request.put(`${ApiRoutes.EDIT_REGION_DATA}/${regionId}`, payload);

// Location Manager Services
export const getAllLocationsService = () => request.get(`${ApiRoutes.GET_ALL_LOCATIONS}`);
export const getAllPaginatedLocationsService = (pageNum, pageLimit, searchText) => request.post(`${ApiRoutes.GET_ADMIN_LOCATIONS}?pageNo=${pageNum}&pageLimit=${pageLimit}`, searchText);
export const updateLocationService = (body) => request.put(`${ApiRoutes.ADD_UPDATE_LOCATION}`, body);
export const CreateLocationService = (body) => request.post(`${ApiRoutes.ADD_UPDATE_LOCATION}`, body);
export const getAllExamCentersService = () => request.get(`${ApiRoutes.GET_ALL_EXAM_CENTERS}`);
export const getAllLocationCoordinatorsService = () => request.get(`${ApiRoutes.GET_ALL_LOCATION_COORODINATORS}`);
export const updateSyncCsv = (payload) => request.post(`${ApiRoutes.UPDATE_CSV_SECTION}`, payload);
export const deleteGoogleSheetbyIdService = (payload) => request.post(`${ApiRoutes.DELETE_GOOGLE_SHEET_BY_ID}`, payload);

// Teacher View Services
export const studentsByYearAndClassIdService = (body) => request.post(`${ApiRoutes.STUDENTS_BY_YEAR_AND_CLASSID}`, body);
export const classesTimingsService = (body) => request.post(`${ApiRoutes.CLASSES_TIMINGS}`, body);
export const classesTimingsStudentService = (body) => request.post(`${ApiRoutes.CLASSES_TIMINGS_STUDENTS}`, body);
export const classesByAcademicYear = (body) => request.post(`${ApiRoutes.CLASSES_BY_ACADEMIC_YEAR}`, body);
export const getTeachetPanelStudentsService = (body) => request.post(`${ApiRoutes.GET_TEACHER_PANEL_STUDENTS}`, body);
export const markStudentAttendanceService = (body) => request.post(`${ApiRoutes.MARK_STUDENT_ATTENDANCE}`, body);
export const studentAttendDetailService = (body) => request.post(`${ApiRoutes.POST_TEACHER_DASH_ATTEND_DETAILS}`, body);
export const getStudentsByLocationMarksService = (body) => request.post(`${ApiRoutes.GET_STUDENTS_BY_LOCATION_MARKS}`, body);
// Course Manager Services
export const getAllCoursesForManagerService = () => request.get(`${ApiRoutes.GET_COURSES_MANAGER}`);
export const getCoursesForManagerServicePagination = (pageNumber, pageLimit) => request.get(`${ApiRoutes.GET_COURSES_MANAGER}?pageNo=${pageNumber}&&pageLimit=${pageLimit}`);
export const createCourseService = (body) => request.post(`${ApiRoutes.POST_CREATE_COURSE}`, body);
export const editCourseService = (body) => request.post(`${ApiRoutes.POST_EDIT_COURSE}`, body);

// Grades Services
export const getGradesService = () => request.get(`${ApiRoutes.GET_GRADES}`);
export const editGradesService = (body) => request.put(`${ApiRoutes.POST_EDIT_GRADE}`, body);

// Academic Dates Services
export const getAcademicYearDatesByYearService = (body) => request.post(`${ApiRoutes.GET_ACADEMIC_YEAR_DATES_BY_YEAR}`, body);
export const getAcademicYearDatesService = (year, payload) => request.post(`${ApiRoutes.GET_ACADEMIC_YEAR_DATES}/${year}`, payload);
export const academicYearDatesService = (payload) => request.post(`${ApiRoutes.GET_ACADEMIC_YEAR_DATES}`, payload);
// Terms and Condition
export const UpdateTermsConditionsSettingsService = (body) => request.put(`${ApiRoutes.UPDATE_TERMS_CONDITIONS}`, body);
export const getTermsConditionsSettingsService = () => request.get(`${ApiRoutes.UPDATE_TERMS_CONDITIONS}`);
// Exam Center Manager
export const getAllExamCenterManagerService = (pageNumber, pageLimit, searchText) => request.get(`${ApiRoutes.GET_EXAM_CENTER_MANAGER}?pageNo=${pageNumber}&&pageLimit=${pageLimit}&&searchText=${searchText}`);
export const createExamCenterService = (body) => request.post(`${ApiRoutes.POST_CREATE_EXAM_CENTER}`, body);
export const editExamCenterService = (body) => request.post(`${ApiRoutes.POST_EDIT_EXAM_CENTER}`, body);

// MakeUpMarks
export const makeUpMarksService = (body) => request.post(`${ApiRoutes.MAKE_UP_MARKS}`, body);

// Academic Panel
export const getAssignmentPerCourseService = (courseId, typeNew = false) => request.get(`${ApiRoutes.GET_ASSIGNMENT_PER_COURSE}/${courseId}?type=${typeNew ? 'new' : 'old'}`);
export const getAssignmentByClassroomIdService = (payload) => request.post(`${ApiRoutes.GET_ASSIGNMENT_BY_CLASSROOM}`, payload);
export const getAssignmentPerCourseAndYearAndLocationService = (payload) => request.post(`${ApiRoutes.GET_ASSIGNMENT_PER_COURSE_YEAR_LOCATION}`, payload);
export const deleteAssignmentService = (assignmentId, courseId) => request.delete(`${ApiRoutes.DELETE_ASSIGNMENT_BY_ID}/${courseId}/${assignmentId}`);
export const editAssignmentService = (body, courseId, selectedAssignmentId) => request.post(`${ApiRoutes.EDIT_ASSIGNMENT_BY_ID}/${courseId}/${selectedAssignmentId}`, body);
export const editAssignmentFromStatusService = (payload, classroomIds) => request.post(`${ApiRoutes.EDIT_ASSIGNMENT_BY_GOOGLE_ID}/${classroomIds.courseId}/${classroomIds.assignmentId}`, payload);
export const deleteAssignmentFromStatusService = (assignmentId, classroomId) => request.delete(`${ApiRoutes.DELETE_ASSIGNMENT_BY_GOOGLE_ID}/${assignmentId}/${classroomId}`);
export const deleteSelectedAssignmentsFromStatusService = (payload) => request.post(`${ApiRoutes.DELETE_SELECTED_ASSIGNMENTS_BY_GOOGLE_ID}`, payload);
export const editOldAssignmentService = (body, courseId, selectedAssignmentId) => request.post(`${ApiRoutes.EDIT_OLD_ASSIGNMENT_BY_ID}/${courseId}/${selectedAssignmentId}`, body);
export const publishAssignmentService = (body) => request.post(`${ApiRoutes.PUBLISH_ASSIGNMENT_BY_ID}`, body);
export const republishOldAssignmentService = (body) => request.post(`${ApiRoutes.REPUBLISH_OLD_ASSIGNMENT_BY_ID}`, body);
export const getClassesAmountService = (body) => request.post(`${ApiRoutes.GET_CLASSES_AMOUNT}`, body);

// Curricular Activities Schema
export const getActivitiesSchemaService = (courseId, academicYear, quarter) => request.get(`${ApiRoutes.GET_CURRICULAR_ACTIVITIES_SCHEMA}?courseId=${courseId}&academicYear=${academicYear}&quarter=${quarter}`);
export const createActivitiesSchemaService = (requestObj) => request.post(`${ApiRoutes.CREATE_CURRICULAR_ACTIVITIES_SCHEMA}`, requestObj);
export const updateActivitiesSchemaService = (requestObj) => request.put(`${ApiRoutes.UPDATE_CURRICULAR_ACTIVITIES_SCHEMA}`, requestObj);
export const updateCurricularActivitiesMarksService = (requestObj) => request.put(`${ApiRoutes.UPDATE_CURRICULAR_ACTIVITIES_MARKS}`, requestObj);

// Assignments Schema
export const getAssignmentSchemaService = (courseId, academicYear, quarter) => request.get(`${ApiRoutes.GET_ASSIGNMENTS_SCHEMA}?courseId=${courseId}&academicYear=${academicYear}&quarter=${quarter}`);
export const createAssignmentSchemaService = (requestObj) => request.post(`${ApiRoutes.CREATE_ASSIGNMENTS_SCHEMA}`, requestObj);
export const updateAssignmentSchemaService = (requestObj) => request.put(`${ApiRoutes.UPDATE_ASSIGNMENTS_SCHEMA}`, requestObj);
export const updateAssignmentMarksService = (requestObj) => request.put(`${ApiRoutes.UPDATE_ASSIGNMENTS_MARKS}`, requestObj);

// Reports
export const createDailyReport = (body) => request.post(`${ApiRoutes.DAILY_REPORTS}`, body);
export const getAllReports = () => request.get(`${ApiRoutes.GET_ALL_REPORTS}`);
export const getAllReportsByType = (reportType) => request.get(`${ApiRoutes.GET_ALL_REPORTS_BY_TYPE}?report=${reportType}`);
export const getUnregisteredStudentsByYear = (reportType, year, userType) => request.get(`${ApiRoutes.GET_ALL_REPORTS_BY_TYPE}?report=${reportType}&academicYear=${year}&userType=${userType}`);
export const getReportById = (id) => request.get(`${ApiRoutes.GET_REPORT_BY_ID}?id=${id}`);

// PSTU faqs
export const getAllPstuFaqs = () => request.get(`${ApiRoutes.GET_PSTU_FAQS}`);
export const createPstuFaqs = (body) => request.post(`${ApiRoutes.CREATE_PSTU}`, body);
export const updatePstuFaqs = (body) => request.post(`${ApiRoutes.UPDATE_PSTU}`, body);

export const checkStudensByEmailService = (body) => request.get(`${ApiRoutes.GET_STUDENTS_BY_EMAIL}?email=${body}`);
export const checkStudensByPhoneService = (body) => request.get(`${ApiRoutes.GET_STUDENTS_BY_PHONE}?phoneNumber=${body}`);
// repeat status
export const updateStudentRepeatStatus = (body) => request.post(`${ApiRoutes.UPDATE_REPEAT_STATUS}`, body);

export const getWelcomeEmail = (type, course) => request.get(`${ApiRoutes.GET_WELCOME_EMAIL}/${type}/${course}`);
export const postWelcomeEmail = (body) => request.post(`${ApiRoutes.GET_WELCOME_EMAIL}`, body);
