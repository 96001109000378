/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react';
import { Grid, Box, Tooltip } from '@mui/material';
import { FormikProvider } from 'formik';
import { DialogAtom, PerfromantDropDownSectionSync } from '../../../components/atoms';
import styles from './style';
import MapPin from '../../../assets/images/map-pin.png';
import useStyles from '../../../custom-hooks/useStyles';
import ButtonAtom from '../../../components/atoms/button';
import { Buttons } from '../../../constant';
import { getCurrentYear } from '../../../utils/methods';

function SyncHeader({
  formik,
  t,
  years,
  locations,
  rowData,
  importSheet,
  exportSheet,
  exportSheetTime,
  importSheetTime,
  createAtSheetTime,
  fileName,
  handlelocationDropDownChange,
}) {
  const classes = useStyles(styles)();
  const csvLink = useRef();

  const [issetOkOpenDialog, setOkOpenDialog] = useState(false);
  const [bDownloadReady, setDownloadReady] = useState(false);

  useEffect(() => {
    if (csvLink && csvLink.current && bDownloadReady) {
      csvLink.current.link.click();
      setDownloadReady(false);
    }
  }, [bDownloadReady]);

  const handleOkDialog = () => {
    setOkOpenDialog(false);
  };

  const renderOkContent = () => (
    <Grid className={classes.studentBox}>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        autoComplete="off"
      >
        <Grid>
          <div className={classes.alertprimary} role="alert">
            {t('CSV_EXPORTED_SUCCESSFULLY')}
          </div>
        </Grid>
      </Box>
      <Grid item xs={12} className={`${classes.dialogButtons} ${classes.popupButton}`}>
        <ButtonAtom
          className={classes.popupBtn}
          name={t('OK')}
          onClick={handleOkDialog}
          btntype={Buttons.PRIMARY}
        />
      </Grid>
    </Grid>
  );

  return (
    <Grid container spacing={2} direction="row" display="flex" alignItems="center">
      <FormikProvider value={formik}>
        <Grid item xs={2.5} md={1.5} lg={1.5} className={classes.yearDropdown}>
          <PerfromantDropDownSectionSync
            minWidth="100%"
            label={t('YEAR')}
            labelId={t('YEAR')}
            id="academicYear"
            name="academicYear"
            value={formik.values.academicYear}
            handleChange={formik.handleChange}
            options={years}
            customClass="studentDropdown"
            variant="standard"
          />
        </Grid>
        <Grid item xs={3} md={5.5} lg={5} className={classes.locationDropdown}>
          <PerfromantDropDownSectionSync
            minWidth="100%"
            label={t('LOCATION')}
            id="locationId"
            name="locationId"
            value={formik.values.locationId}
            handleChange={handlelocationDropDownChange}
            options={locations}
            customClass="locationDropdown"
            variant="standard"
            icon={<img src={MapPin} alt="" className={classes.mapPinImg} />}
          />
        </Grid>
        <Grid item xs={4} md={1} lg={1} />
        <Grid item xs={4} md={2} lg={2}>
          <ButtonAtom
            btntype={Buttons.PRIMARY}
            className={classes.secButton}
            name={t('EXPORT_TO_SHEET')}
            onClick={() => exportSheet(rowData)}
          />
          <span className={classes.agoText}>
            {exportSheetTime ? `Last edit was ${exportSheetTime}` : 'Sheet has not been exported'}
          </span>
        </Grid>
        <Grid item xs={4} md={2} lg={2}>
          <DialogAtom
            isOpen={issetOkOpenDialog}
            dialogHeading={t('SYNC_EXPORT')}
            customClass={classes.DialogAtom}
            content={renderOkContent()}
            secHandle={() => setOkOpenDialog(false)}
          />
          <ButtonAtom
            btntype={Buttons.PRIMARY}
            className={classes.secButton}
            name={t('IMPORT_FROM_SHEET')}
            onClick={importSheet}
            disabled={formik.values.academicYear !== getCurrentYear()?.id}
          />
          <span className={classes.agoText}>
            {importSheetTime ? `Last edit was ${importSheetTime}` : 'Sheet has not been imported'}
          </span>
        </Grid>
        <Grid className={classes.filterCSV}>
          <ul>
            <li>
              {t('SPREADSHEET')}
              :
              {' '}
              <span>
                {fileName?.length
                  ? (
                    <Tooltip
                      title={fileName?.length ? `${fileName}` : ''}
                    >
                      <span>{fileName?.length ? `${fileName?.substring(0, 50)}...` : ''}</span>
                    </Tooltip>
                  ) : null}
              </span>
            </li>
            <li>
              {t('SHEET')}
              :
              {' '}
              <span>Section Sync</span>
            </li>
            <li>
              {t('CREATED_ON')}
              :
              {' '}
              <span>{createAtSheetTime}</span>
            </li>
          </ul>
        </Grid>
      </FormikProvider>
    </Grid>
  );
}

export default SyncHeader;
