import { colors, fonts } from '../../../theme';

const styles = {
  titleRow: {
    color: colors.primary,
    justifyContent: 'space-between',
  },

  headerTitle: {
    color: colors.black,
    padding: '10px 10px 10px 1px',
    fontSize: '1.5vw',
    fontWeight: 'bold',
  },

  content: {
    width: '100%',
    '@media (max-width: 600px)': {
      width: '100%',
    },
  },

  saveBtnCont: {
    textAlign: 'right',
  },

  formCrossRow: {
    display: 'flex',
    justifyContent: 'left',
    marginTop: '4px',
    alignItems: 'center',
  },

  contentCont: {
    minHeight: 300,
    border: `1px solid ${colors.border}`,
    margin: 30,
    marginBottom: 50,
    marginTop: 0,
    borderRadius: 7,
    display: 'flex',
    '@media (max-width: 600px)': {
      display: 'flow-root',
    },
  },

  formControl: {
    '@media (max-width: 1199px)': {
      marginRight: 0,
    },
    '& .MuiOutlinedInput-input.Mui-disabled': {
      background: `${colors.disabledField} !important`,
      '-webkit-text-fill-color': `${colors.black} !important`,
    },
    '& .MuiInputLabel-root': {
      fontFamily: `${fonts.fontType.roboto} !important`,
      fontSize: '0.9vw !important',
      color: `${colors.primary} !important`,
    },
    '& .MuiOutlinedInput-root': {
      background: '#fff',
      borderRadius: '0.4vw',
      '@media (max-width: 600px)': {
        height: '55px !important',
      },
      color: colors.placeHolderColor,
      '@media (min-width: 1200px)': {
        fontSize: '0.9vw !important',
        height: '2.7vw',
      },
      outline: 'none',
      '& .MuiOutlinedInput-notchedOutline': {
        border: `0.1vw solid ${colors.primary}`,
      },
      '&:hover fieldset': {
        border: `0.2vw solid ${colors.primary} !important`,
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `0.2vw solid ${colors.primary}`,
      },
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        border: `0.1vw solid ${colors.errorText}`,
      },
      '&.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `0.2vw solid ${colors.errorText}`,
      },
      '& input': {
        '@media (min-width: 1200px) and (max-width: 1599px)': {
          padding: '0.7vw',
          fontSize: '0.9vw !important',
        },
        fontFamily: `${fonts.fontType.roboto} !important`,
        color: `${colors.black}!important`,
        textOverflow: 'ellipsis',
      },
    },
    '& .MuiFormHelperText-contained': {
      margin: 0,
    },
  },

  courseTableHeadingCont: {
    padding: '0, 0, 10, 10',
    borderBottom: `3px solid ${colors.primary}`,
    marginBottom: '30px',
  },

  courseTableHeading: {
    padding: 0,
    fontWeight: 600,
    color: colors.primary,
    fontSize: 12,
    marginBottom: '15px',
  },

  formControlGrid: {
    '@media (max-width: 1199px)': {
      marginRight: 0,
      paddingLeft: '5px !important',
    },
    '& .MuiOutlinedInput-input.Mui-disabled': {
      background: `${colors.disabledField} !important`,
      '-webkit-text-fill-color': `${colors.black} !important`,
    },
    '& .MuiInputLabel-root': {
      fontFamily: `${fonts.fontType.roboto} !important`,
      fontSize: '0.9vw !important',
      color: `${colors.primary} !important`,
    },
    '& .MuiOutlinedInput-root': {
      background: '#fff',
      borderRadius: '0.4vw',
      '@media (max-width: 600px)': {
        height: '55px !important',
      },
      color: colors.placeHolderColor,
      '@media (min-width: 1200px)': {
        fontSize: '0.9vw !important',
        height: '2.7vw',
      },
      outline: 'none',
      '& .MuiOutlinedInput-notchedOutline': {
        border: `0.1vw solid ${colors.primary}`,
      },
      '&:hover fieldset': {
        border: `0.2vw solid ${colors.primary} !important`,
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `0.2vw solid ${colors.primary}`,
      },
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        border: `0.1vw solid ${colors.errorText}`,
      },
      '&.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `0.2vw solid ${colors.errorText}`,
      },
      '& input': {
        '@media (min-width: 1200px) and (max-width: 1599px)': {
          padding: '0.7vw',
          fontSize: '0.9vw !important',
        },
        fontFamily: `${fonts.fontType.roboto} !important`,
        color: `${colors.black}!important`,
        textOverflow: 'ellipsis',
      },
    },
    '& .MuiFormHelperText-contained': {
      margin: 0,
    },
  },

  errorText: {
    color: colors.errorText,
    fontSize: '0.9vw',
  },

  errorCont: {
    marginTop: '3vh',
    marginBottom: '3vh',
  },

  formControlGridDropdown: {
    '@media (min-width: 768px) and (max-width: 1199px)': {
      paddingLeft: '16px !important',
    },
    '@media (max-width: 767px)': {
      marginRight: 0,
      paddingLeft: '0px !important',
    },
    '& .MuiOutlinedInput-input.Mui-disabled': {
      background: `${colors.disabledField} !important`,
      '-webkit-text-fill-color': `${colors.black} !important`,
    },
    '& .MuiInputLabel-root': {
      fontFamily: `${fonts.fontType.roboto} !important`,
      fontSize: '0.9vw !important',
      color: `${colors.primary} !important`,
      position: 'absolute',
      top: '2%',
      left: '-2%',
      transform: 'translateY(-50%)',
      pointerEvents: 'none',
      zIndex: 1,
      background: '#fff',
      padding: '0 8px',
      transition: 'transform 200ms, font-size 200ms',
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(14px, -10px) scale(0.8)',
      top: '15% !important',
      fontSize: '12px !important',
      background: '#fff',
    },
    '& .MuiOutlinedInput-root': {
      position: 'relative',
      '&:focus-within .MuiInputLabel-root': {
        transform: 'translate(-4px, -50%) scale(0.8)',
        fontSize: '0.8vw',
      },
      background: '#fff',
      borderRadius: '0.4vw',
      '@media (max-width: 600px)': {
        height: '55px !important',
      },
      color: colors.placeHolderColor,
      '@media (min-width: 1200px)': {
        fontSize: '0.9vw !important',
        height: '2.7vw',
      },
      outline: 'none',
      '& .MuiOutlinedInput-notchedOutline': {
        border: `0.1vw solid ${colors.primary}`,
      },
      '&:hover fieldset': {
        border: `0.2vw solid ${colors.primary} !important`,
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `0.2vw solid ${colors.primary}`,
      },
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        border: `0.1vw solid ${colors.errorText}`,
      },
      '&.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `0.2vw solid ${colors.errorText}`,
      },
      '& input': {
        '@media (min-width: 1200px) and (max-width: 1599px)': {
          padding: '0.7vw',
          fontSize: '0.9vw !important',
        },
        fontFamily: `${fonts.fontType.roboto} !important`,
        color: `${colors.black}!important`,
        textOverflow: 'ellipsis',
      },
    },
    '& .MuiFormHelperText-contained': {
      margin: 0,
    },
  },
  successText: {
    color: colors.green,
  },

  successCont: {
    marginTop: '3vh',
    marginBottom: '3vh',
  },
};

export default styles;
