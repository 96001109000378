/* eslint-disable no-nested-ternary */
import React from 'react';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import {
  Box, Grid, IconButton, Tooltip,
} from '@mui/material';
import {
  getGradeforScore, isNullOrUndefined, renderGradeCell, renderGpaCell, renderAnnualScoreCell,
} from '../../../utils/methods';
import Constant from '../../../store/constant';
import { setLocalStorage } from '../../../utils/localStorageMethod';
import { NavigateRoutes } from '../../../constant';

const getMarksHeader = (t) => (
  <div>
    <span style={{ height: '5vh' }}>
      {t('MARKS')}
    </span>
    <div>{t('Q2')}</div>
  </div>
);

const getHomeworkHeader = (t) => (
  <div>
    <span style={{ height: '5vh', marginLeft: 25 }}>
      {t('HOMEWORK')}
    </span>
    <div>{t('Q2')}</div>
  </div>
);

const getSemester3Header = (t) => (
  <div>
    <span style={{ height: '5vh', left: 0 }}>
      {t('PSTU/SUPP')}
    </span>
    <div>
      {' '}
      <span style={{ left: 50, top: 1 }}>{t('MARKS')}</span>
      {' '}
    </div>
  </div>
);

function getQuarterColumnsData(
  t,
  classes,
  setOpenDialog,
  dispatch,
  navigate,
  visibleFields,
) {
  const columns = [
    {
      field: 'studentName',
      headerName: t('STUDENT_NAME'),
      hide: !visibleFields?.includes(t('STUDENT_NAME')),
      align: 'left',
      headerClassName: 'studentNameHeader',
      cellClassName: 'studentNameCell',
      sortable: true,
      headerAlign: 'left',
    },
    {
      field: 'parentName',
      headerName: t('PARENT_NAME'),
      hide: !visibleFields?.includes(t('PARENT_NAME')),
      align: 'left',
      headerClassName: 'parentHeader',
      cellClassName: 'parentCell',
      sortable: true,
      headerAlign: 'left',
    },
    {
      field: 'studentEmailAddress',
      headerName: t('STUDENT_EMAIL_ADDRESS'),
      hide: !visibleFields?.includes(t('STUDENT_EMAIL_ADDRESS')),
      sortable: true,
      headerAlign: 'left',
      align: 'left',
      headerClassName: 'emailHeader',
      cellClassName: 'emailCell',
    },
    {
      field: 'marksQ1',
      disableColumnResize: 'false',
      hide: !visibleFields?.includes(t('MARKS')),
      sortable: false,
      headerName: t('Q1'),
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'marksHeader',
      cellClassName: 'marksCell',
      renderCell: (cellValues) => (
        <Box
          component="span"
          onClick={(e) => setOpenDialog(e, cellValues, 'marks', 'Q1')}
          className={isNullOrUndefined(cellValues?.row?.marks?.Q1?.passingCriteria) && !cellValues?.row?.marks?.Q1?.isAttended ? classes.marksRed
            : cellValues?.row?.marks?.Q1?.calculatedWeightage >= cellValues?.row?.marks?.Q1?.passingCriteria ? classes.marksGreen
              : cellValues?.row?.marks?.Q1?.calculatedWeightage < parseInt(cellValues?.row?.marks?.Q1?.passingCriteria, 10) - 10 ? classes.marksRed
                : cellValues?.row?.marks?.Q1?.calculatedWeightage >= parseInt(cellValues?.row?.marks?.Q1?.passingCriteria, 10) - 10 ? classes.marksYellow
                  : classes.marksGrey}
        >
          {isNullOrUndefined(cellValues?.row?.marks?.Q1?.passingCriteria) && cellValues?.row?.marks?.Q1?.passingCriteria >= 0
            ? Number.isInteger(cellValues?.row?.marks?.Q1?.calculatedWeightage) ? cellValues?.row?.marks?.Q1?.calculatedWeightage : Number(cellValues?.row?.marks?.Q1?.calculatedWeightage).toFixed(2) : '-'}
          {isNullOrUndefined(cellValues?.row?.marks?.Q1?.passingCriteria) && !cellValues?.row?.marks?.Q1?.isAttended ? '(A)' : ''}
        </Box>
      ),
    },
    {
      field: 'marksQ2',
      hide: !visibleFields?.includes(t('MARKS')),
      headerName: getMarksHeader(t),
      disableColumnResize: 'false',
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'marksHeaderSpan',
      cellClassName: 'marksQ3Cell',
      sortable: false,
      renderCell: (cellValues) => (
        <Box
          component="span"
          onClick={(e) => setOpenDialog(e, cellValues, 'marks', 'Q2')}
          className={isNullOrUndefined(cellValues?.row?.marks?.Q2?.passingCriteria) && !cellValues?.row?.marks?.Q2?.isAttended ? classes.marksRed
            : cellValues?.row?.marks?.Q2?.calculatedWeightage >= cellValues?.row?.marks?.Q2?.passingCriteria ? classes.marksGreen
              : cellValues?.row?.marks?.Q2?.calculatedWeightage < parseInt(cellValues?.row?.marks?.Q2?.passingCriteria, 10) - 10 ? classes.marksRed
                : cellValues?.row?.marks?.Q2?.calculatedWeightage >= parseInt(cellValues?.row?.marks?.Q2?.passingCriteria, 10) - 10 ? classes.marksYellow
                  : classes.marksGrey}
        >
          {isNullOrUndefined(cellValues?.row?.marks?.Q2?.passingCriteria) && cellValues?.row?.marks?.Q2?.passingCriteria >= 0
            ? Number.isInteger(cellValues?.row?.marks?.Q2?.calculatedWeightage) ? cellValues?.row?.marks?.Q2?.calculatedWeightage : Number(cellValues?.row?.marks?.Q2?.calculatedWeightage).toFixed(2) : '-'}
          {isNullOrUndefined(cellValues?.row?.marks?.Q2?.passingCriteria) && !cellValues?.row?.marks?.Q2?.isAttended ? '(A)' : ''}
        </Box>
      ),
    },
    {
      field: 'marksQ3',
      hide: !visibleFields?.includes(t('MARKS')),
      headerName: t('Q3'),
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'marksQ3Header',
      cellClassName: 'marksQ3Cell',
      sortable: false,
      renderCell: (cellValues) => (
        <Box
          component="span"
          onClick={(e) => setOpenDialog(e, cellValues, 'marks', 'Q3')}
          className={isNullOrUndefined(cellValues?.row?.marks?.Q3?.passingCriteria) && !cellValues?.row?.marks?.Q3?.isAttended ? classes.marksRed
            : cellValues?.row?.marks?.Q3?.calculatedWeightage >= cellValues?.row?.marks?.Q3?.passingCriteria ? classes.marksGreen
              : cellValues?.row?.marks?.Q3?.calculatedWeightage < parseInt(cellValues?.row?.marks?.Q3?.passingCriteria, 10) - 10 ? classes.marksRed
                : cellValues?.row?.marks?.Q3?.calculatedWeightage >= parseInt(cellValues?.row?.marks?.Q3?.passingCriteria, 10) - 10
                  ? classes.marksYellow : classes.marksGrey}
        >
          {isNullOrUndefined(cellValues?.row?.marks?.Q3?.passingCriteria) && cellValues?.row?.marks?.Q3?.passingCriteria >= 0
            ? Number.isInteger(cellValues?.row?.marks?.Q3?.calculatedWeightage) ? cellValues?.row?.marks?.Q3?.calculatedWeightage : Number(cellValues?.row?.marks?.Q3?.calculatedWeightage).toFixed(2) : '-'}
          {isNullOrUndefined(cellValues?.row?.marks?.Q3?.passingCriteria) && !cellValues?.row?.marks?.Q3?.isAttended ? '(A)' : ''}
        </Box>
      ),
    },
    {
      field: 'homeworkQ1',
      hide: !visibleFields?.includes(t('HOMEWORK')),
      headerName: t('Q1'),
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'marksHeader',
      cellClassName: 'marksCell',
      sortable: false,
      renderCell: (cellValues) => (
        <Box
          component="span"
          onClick={(e) => setOpenDialog(e, cellValues, 'homework-marks', 'Q1')}
          className={isNullOrUndefined(cellValues?.row?.homeworkMarks?.Q1?.weightage) && cellValues?.row?.homeworkMarks?.Q1?.weightage >= 0
            ? classes.homeworkColor : classes.marksGrey}
        >
          {isNullOrUndefined(cellValues?.row?.homeworkMarks?.Q1?.weightage) && cellValues?.row?.homeworkMarks?.Q1?.weightage >= 0
            ? Number.isInteger(cellValues?.row?.homeworkMarks?.Q1?.calculatedWeightage) ? cellValues?.row?.homeworkMarks?.Q1?.calculatedWeightage : Number(cellValues?.row?.homeworkMarks?.Q1?.calculatedWeightage).toFixed(2) : '-'}
        </Box>
      ),
    },
    {
      field: 'homeworkQ2',
      hide: !visibleFields?.includes(t('HOMEWORK')),
      headerName: getHomeworkHeader(t),
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'homeworkHeaderSpan',
      cellClassName: 'marksQ3Cell',
      sortable: false,
      renderCell: (cellValues) => (
        <Box
          component="span"
          onClick={(e) => setOpenDialog(e, cellValues, 'homework-marks', 'Q2')}
          className={isNullOrUndefined(cellValues?.row?.homeworkMarks?.Q2?.weightage) && cellValues?.row?.homeworkMarks?.Q2?.weightage >= 0
            ? classes.homeworkColor : classes.marksGrey}
        >
          {isNullOrUndefined(cellValues?.row?.homeworkMarks?.Q2?.weightage) && cellValues?.row?.homeworkMarks?.Q2?.weightage >= 0
            ? Number.isInteger(cellValues?.row?.homeworkMarks?.Q2?.calculatedWeightage) ? cellValues?.row?.homeworkMarks?.Q2?.calculatedWeightage : Number(cellValues?.row?.homeworkMarks?.Q2?.calculatedWeightage).toFixed(2) : '-'}
        </Box>
      ),
    },
    {
      field: 'homeworkQ3',
      hide: !visibleFields?.includes(t('HOMEWORK')),
      sortable: false,
      headerName: t('Q3'),
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'marksQ3Header',
      cellClassName: 'marksQ3Cell',
      renderCell: (cellValues) => (
        <Box
          component="span"
          onClick={(e) => setOpenDialog(e, cellValues, 'homework-marks', 'Q3')}
          className={isNullOrUndefined(cellValues?.row?.homeworkMarks?.Q3?.weightage) && cellValues?.row?.homeworkMarks?.Q3?.weightage >= 0
            ? classes.homeworkColor : classes.marksGrey}
        >
          {isNullOrUndefined(cellValues?.row?.homeworkMarks?.Q3?.weightage) && cellValues?.row?.homeworkMarks?.Q3?.weightage >= 0
            ? Number.isInteger(cellValues?.row?.homeworkMarks?.Q3?.calculatedWeightage) ? cellValues?.row?.homeworkMarks?.Q3?.calculatedWeightage : Number(cellValues?.row?.homeworkMarks?.Q3?.calculatedWeightage).toFixed(2) : '-'}
        </Box>
      ),
    },
    {
      field: 'bonus',
      hide: !visibleFields?.includes(t('BONUS_MARKS')),
      sortable: false,
      headerName: t('BONUS_MARKS'),
      headerClassName: 'bonusHeaderSpan',
      cellClassName: 'bonusCell',
      headerAlign: 'center',
      align: 'center',
      renderCell: (cellValues) => (
        <Box
          component="span"
          onClick={(e) => setOpenDialog(e, cellValues, 'bonus', null)}
          className={classes.marksGreen}
        >
          {cellValues?.row?.bonus || 0}
        </Box>
      ),
    },
    {
      field: 'grade',
      hide: !visibleFields?.includes(t('GRADE')),
      headerName: t('GRADE'),
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      headerClassName: 'semGradeHeader',
      cellClassName: 'semGradeCell',
      renderCell: (cellValues) => renderGradeCell(cellValues),
    },
    {
      field: 'gpa',
      hide: !visibleFields?.includes(t('GPA')),
      headerName: t('GPA'),
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      headerClassName: 'semGpaHeader',
      cellClassName: 'quarterGpaCell',
      renderCell: (cellValues) => renderGpaCell(cellValues),
    },
    {
      field: 'annualScore',
      hide: !visibleFields?.includes(t('ANNUAL_SCORE')),
      headerName: t('ANNUAL_SCORE'),
      headerAlign: 'center',
      align: 'center',
      sortable: true,
      headerClassName: 'commonHeader',
      cellClassName: 'commonCell',
      renderCell: (cellValues) => renderAnnualScoreCell(cellValues),
    },
    {
      field: 'actions',
      hide: !visibleFields?.includes(t('ACTIONS')),
      headerName: t('ACTIONS'),
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      headerClassName: 'commonHeader',
      cellClassName: 'quarterActionCells',
      renderCell: (row) => (
        <Grid item xs={12} lg={3} display="flex">
          <Tooltip title={t('SEND_EMAIL')}>
            <IconButton
              className={classes.emailIconColor}
              onClick={() => {
                const { manabadiEmail } = row.row.studentInfo;
                const emailParents = [];
                emailParents.push(row?.row?.parent1Info?.personalEmail?.toString());
                emailParents.push(row?.row?.parent2Info?.personalEmail?.toString());
                dispatch({ type: Constant.RECIPIENTS, payload: [manabadiEmail] });
                dispatch({ type: Constant.MAIL_FILTER, payload: 'Student' });
                dispatch({ type: Constant.MAIL_PARENTS, payload: emailParents });
                setLocalStorage('showLocationFilterRecipients', false);
                setLocalStorage('showLocationAnnouncementsRecipients', false);
                setLocalStorage('showSelectAllinEmail', false);
                localStorage.setItem('sendSingleEmail', true);
                navigate(NavigateRoutes.TEACHER_VIEW_EMAIL);
              }}
            >
              <EmailOutlinedIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      ),
    },
  ];

  return columns;
}

function getAllQuarterFields(t) {
  const allFields = [t('STUDENT_NAME'), t('PARENT_NAME'), t('STUDENT_EMAIL_ADDRESS'), t('MARKS'),
    t('HOMEWORK'), t('BONUS_MARKS'), t('GRADE'), t('GPA'), t('ANNUAL_SCORE'), t('ACTIONS'),
  ];

  return allFields;
}

function getAllSemesterFields2023(t) {
  const allFields = [t('STUDENT_NAME'), t('PARENT_NAME'), t('STUDENT_EMAIL_ADDRESS'),
    t('S1_EXAM'), t('S1_HW'), t('S1_QUIZ1'), t('S1_QUIZ2'), t('S1_ASSIGNMENT'), t('S1_CO-CURRICULAR'), t('S1_TOTAL'), t('S1_GRADE'),
    t('S2_EXAM'), t('S2_HW'), t('S2_QUIZ3'), t('S2_QUIZ4'), t('S2_ASSIGNMENT'), t('S2_CO-CURRICULAR'), t('S2_TOTAL'), t('S2_GRADE'),
    t('PSTU/Supplementary'), t('GRADE'), t('GPA'), t('ANNUAL_SCORE'), t('ACTIONS'), t('GENDER'),
  ];

  return allFields;
}

function getAllSemesterFields(t) {
  const allFields = [t('STUDENT_NAME'), t('PARENT_NAME'), t('STUDENT_EMAIL_ADDRESS'),
    t('S1_EXAM'), t('S1_HW'), t('S1_QUIZ1'), t('S1_ASSIGNMENT'), t('S1_CO-CURRICULAR'), t('S1_TOTAL'), t('S1_GRADE'),
    t('S2_EXAM'), t('S2_HW'), t('S2_QUIZ2'), t('S2_ASSIGNMENT'), t('S2_CO-CURRICULAR'), t('S2_TOTAL'), t('S2_GRADE'),
    t('PSTU/Supplementary'), t('GRADE'), t('GPA'), t('ANNUAL_SCORE'), t('ACTIONS'), t('GENDER'),
  ];

  return allFields;
}

function getQuarterCSVHeaders(t) {
  const ALL_FIELDS = getAllQuarterFields(t);
  const CSV_HEADERS = [
    { name: ALL_FIELDS[1], label: ALL_FIELDS[1], key: 'studentName' },
    { name: ALL_FIELDS[2], label: ALL_FIELDS[2], key: 'age' },
    { name: ALL_FIELDS[16], label: ALL_FIELDS[16], key: 'gender' },
    { name: ALL_FIELDS[3], label: ALL_FIELDS[3], key: 'parentName' },
    { name: ALL_FIELDS[4], label: ALL_FIELDS[4], key: 'phoneNumber' },
    { name: ALL_FIELDS[5], label: ALL_FIELDS[5], key: 'tShirtSize' },
    { name: ALL_FIELDS[6], label: ALL_FIELDS[6], key: 'course' },
    { name: ALL_FIELDS[7], label: ALL_FIELDS[7], key: 'section' },
    { name: ALL_FIELDS[8], label: ALL_FIELDS[8], key: 'newReturning' },
    { name: ALL_FIELDS[9], label: 'Marks-Q1', key: 'marksQ1' },
    { name: ALL_FIELDS[9], label: 'Marks-Q2', key: 'marksQ2' },
    { name: ALL_FIELDS[9], label: 'Marks-Q3', key: 'marksQ3' },
    { name: ALL_FIELDS[10], label: 'Homework-Q1', key: 'homeworkQ1' },
    { name: ALL_FIELDS[10], label: 'Homework-Q2', key: 'homeworkQ2' },
    { name: ALL_FIELDS[10], label: 'Homework-Q3', key: 'homeworkQ3' },
    { name: ALL_FIELDS[11], label: ALL_FIELDS[11], key: 'bonus' },
    { name: ALL_FIELDS[12], label: ALL_FIELDS[12], key: 'grade' },
    { name: ALL_FIELDS[13], label: ALL_FIELDS[13], key: 'gpa' },
    { name: ALL_FIELDS[14], label: ALL_FIELDS[14], key: 'annualScore' },
  ];

  return CSV_HEADERS;
}

function getSemesterCSVHeaders(t) {
  const ALL_FIELDS = getAllSemesterFields(t);
  const CSV_HEADERS = [
    { name: ALL_FIELDS[1], label: ALL_FIELDS[1], key: 'studentName' },
    { name: ALL_FIELDS[2], label: ALL_FIELDS[2], key: 'age' },
    { name: ALL_FIELDS[16], label: ALL_FIELDS[16], key: 'gender' },
    { name: ALL_FIELDS[3], label: ALL_FIELDS[3], key: 'parentName' },
    { name: ALL_FIELDS[4], label: ALL_FIELDS[4], key: 'phoneNumber' },
    { name: ALL_FIELDS[5], label: ALL_FIELDS[5], key: 'tShirtSize' },
    { name: ALL_FIELDS[6], label: ALL_FIELDS[6], key: 'course' },
    { name: ALL_FIELDS[7], label: ALL_FIELDS[7], key: 'section' },
    { name: ALL_FIELDS[8], label: ALL_FIELDS[8], key: 'newReturning' },

    { name: ALL_FIELDS[9], label: 'Marks-S1', key: 'marksS1' },
    { name: ALL_FIELDS[9], label: 'Homework-S1', key: 'homeworkS1' },
    { name: ALL_FIELDS[9], label: 'Quiz1-S1', key: 'quizS1Q1' },
    { name: ALL_FIELDS[9], label: 'Quiz2-S1', key: 'quizS1Q2' },
    { name: ALL_FIELDS[9], label: 'Assignment-S1', key: 'assignmentS1' },
    { name: ALL_FIELDS[9], label: 'Co-Curricular-S1', key: 'curricularS1' },
    { name: ALL_FIELDS[9], label: 'Total-S1', key: 'S1Total' },
    { name: ALL_FIELDS[9], label: 'Grade-S1', key: 'gradeS1' },

    { name: ALL_FIELDS[10], label: 'Marks-S2', key: 'marksS2' },
    { name: ALL_FIELDS[10], label: 'Homework-S2', key: 'homeworkS2' },
    { name: ALL_FIELDS[10], label: 'Quiz1-S2', key: 'quizS2Q1' },
    { name: ALL_FIELDS[10], label: 'Quiz2-S2', key: 'quizS2Q2' },
    { name: ALL_FIELDS[10], label: 'Assignment-S2', key: 'assignmentS2' },
    { name: ALL_FIELDS[10], label: 'Co-Curricular-S2', key: 'curricularS2' },
    { name: ALL_FIELDS[10], label: 'Total-S2', key: 'S2Total' },
    { name: ALL_FIELDS[10], label: 'Grade-S2', key: 'gradeS2' },

    { name: ALL_FIELDS[11], label: 'PSTU/Supplementary', key: 'marksS3' },
    { name: ALL_FIELDS[12], label: ALL_FIELDS[12], key: 'grade' },
    { name: ALL_FIELDS[13], label: ALL_FIELDS[13], key: 'gpa' },
    { name: ALL_FIELDS[14], label: ALL_FIELDS[14], key: 'annualScore' },
  ];

  return CSV_HEADERS;
}

const getTotalQuiz1Marks = (semester, student) => {
  if (semester === 'S1') {
    return ((student?.quizMarks?.['S1Quiz 1'].calculatedWeightage || student?.quizMarks?.['S1Quiz 1'].calculatedWeightage >= 0))
      // eslint-disable-next-line no-unsafe-optional-chaining
      ? (Number((student?.quizMarks['S1Quiz 1']?.calculatedWeightage))) : '-';
  } if (semester === 'S2') {
    return ((student?.quizMarks?.['S2Quiz 1'].calculatedWeightage || student?.quizMarks?.['S2Quiz 1'].calculatedWeightage >= 0))
      ? (Number(student?.quizMarks?.['S2Quiz 1'].calculatedWeightage)) : '-';
  }
  return null;
};

const getTotalQuiz2Marks = (semester, student) => {
  if (semester === 'S1') {
    return ((student?.quizMarks?.['S1Quiz 2'].calculatedWeightage || student?.quizMarks?.['S1Quiz 2'].calculatedWeightage >= 0))
      // eslint-disable-next-line no-unsafe-optional-chaining
      ? (Number((student?.quizMarks['S1Quiz 2']?.calculatedWeightage))) : '-';
  } if (semester === 'S2') {
    return ((student?.quizMarks?.['S2Quiz 2'].calculatedWeightage || student?.quizMarks?.['S2Quiz 2'].calculatedWeightage >= 0))
      ? (Number(student?.quizMarks?.['S2Quiz 2'].calculatedWeightage)) : '-';
  }
  return null;
};

const renderS3Cell = (cellValues, studentsData, setOpenDialog, classes) => {
  return (cellValues?.row?.enrolled_courses?.[0]?.course?.name !== 'BalaBadi - I' && cellValues?.row?.enrolled_courses?.[0]?.course?.name !== 'BalaBadi - II'
    && cellValues?.row?.enrolled_courses?.[0]?.course?.name !== 'Telugu-1' && cellValues?.row?.enrolled_courses?.[0]?.course?.name !== 'Telugu-3')
    ? (cellValues?.row?.enrolled_courses?.[0]?.course?.name === 'Prakasam' || cellValues?.row?.enrolled_courses?.[0]?.course?.name === 'Prabhasam'
    || cellValues?.row?.enrolled_courses?.[0]?.course?.name === 'Telugu-2' || cellValues?.row?.enrolled_courses?.[0]?.course?.name === 'Telugu-4') ? (
      <Box
        component="span"
        onClick={(e) => setOpenDialog(e, cellValues, 'marks', 'S3')}
        className={isNullOrUndefined(cellValues?.row?.marks?.S3?.passingCriteria) && !cellValues?.row?.marks?.S3?.isAttended ? classes.marksRed
          : cellValues?.row?.marks?.S3?.calculatedWeightage >= cellValues?.row?.marks?.S3?.passingCriteria ? classes.marksGreen
            : cellValues?.row?.marks?.S3?.calculatedWeightage < parseInt(cellValues?.row?.marks?.S3?.passingCriteria, 10) - 10 ? classes.marksRed
              : cellValues?.row?.marks?.S3?.calculatedWeightage >= parseInt(cellValues?.row?.marks?.S3?.passingCriteria, 10) - 10 ? classes.marksYellow
                : classes.marksGrey}
      >
        {isNullOrUndefined(cellValues?.row?.marks?.S3?.passingCriteria) && cellValues?.row?.marks?.S3?.passingCriteria >= 0
          ? Number.isInteger(cellValues?.row?.marks?.S3?.calculatedWeightage) ? cellValues?.row?.marks?.S3?.calculatedWeightage : Number(cellValues?.row?.marks?.S3?.calculatedWeightage).toFixed(2) : '-'}
        {isNullOrUndefined(cellValues?.row?.marks?.S3?.passingCriteria) && !cellValues?.row?.marks?.S3?.isAttended ? '(A)' : ''}
      </Box>
      ) : (cellValues?.row?.enrolled_courses?.[0]?.result === 'FAIL' || (cellValues?.row?.marks.S3?.calculatedWeightage > 0 && cellValues?.row?.marks.S3?.isAttended) || (cellValues?.row?.marks.S3?.calculatedWeightage === 0 && cellValues?.row?.marks.S3?.isAttended !== null)) ? (
        <Box
          component="span"
          onClick={(e) => setOpenDialog(e, cellValues, 'marks', 'S3')}
          className={isNullOrUndefined(cellValues?.row?.marks?.S3?.passingCriteria) && !cellValues?.row?.marks?.S3?.isAttended ? classes.marksRed
            : cellValues?.row?.marks?.S3?.calculatedWeightage >= cellValues?.row?.marks?.S3?.passingCriteria ? classes.marksGreen
              : cellValues?.row?.marks?.S3?.calculatedWeightage < parseInt(cellValues?.row?.marks?.S3?.passingCriteria, 10) - 10 ? classes.marksRed
                : cellValues?.row?.marks?.S3?.calculatedWeightage >= parseInt(cellValues?.row?.marks?.S3?.passingCriteria, 10) - 10 ? classes.marksYellow
                  : classes.marksGrey}
        >
          {isNullOrUndefined(cellValues?.row?.marks?.S3?.passingCriteria) && cellValues?.row?.marks?.S3?.passingCriteria >= 0
            ? Number.isInteger(cellValues?.row?.marks?.S3?.calculatedWeightage) ? cellValues?.row?.marks?.S3?.calculatedWeightage : Number(cellValues?.row?.marks?.S3?.calculatedWeightage).toFixed(2) : '-'}
          {isNullOrUndefined(cellValues?.row?.marks?.S3?.passingCriteria) && !cellValues?.row?.marks?.S3?.isAttended ? '(A)' : ''}
        </Box>
      ) : ((cellValues?.row?.marks.S1?.isAttended === false > 0 && cellValues?.row?.marks.S2?.isAttended === false)) ? (
        <Box
          component="span"
          onClick={(e) => setOpenDialog(e, cellValues, 'marks', 'S3')}
          className={isNullOrUndefined(cellValues?.row?.marks?.S3?.passingCriteria) && !cellValues?.row?.marks?.S3?.isAttended ? classes.marksRed
            : cellValues?.row?.marks?.S3?.calculatedWeightage >= cellValues?.row?.marks?.S3?.passingCriteria ? classes.marksGreen
              : cellValues?.row?.marks?.S3?.calculatedWeightage < parseInt(cellValues?.row?.marks?.S3?.passingCriteria, 10) - 10 ? classes.marksRed
                : cellValues?.row?.marks?.S3?.calculatedWeightage >= parseInt(cellValues?.row?.marks?.S3?.passingCriteria, 10) - 10 ? classes.marksYellow
                  : classes.marksGrey}
        >
          {isNullOrUndefined(cellValues?.row?.marks?.S3?.passingCriteria) && cellValues?.row?.marks?.S3?.passingCriteria >= 0
            ? Number.isInteger(cellValues?.row?.marks?.S3?.calculatedWeightage) ? cellValues?.row?.marks?.S3?.calculatedWeightage : Number(cellValues?.row?.marks?.S3?.calculatedWeightage).toFixed(2) : '-'}
          {isNullOrUndefined(cellValues?.row?.marks?.S3?.passingCriteria) && !cellValues?.row?.marks?.S3?.isAttended ? '(A)' : ''}
        </Box>
      ) : (
        'N/A'
      ) : (
      'N/A'
    );
};

function getSemesterColumnsData(
  t,
  classes,
  setOpenDialog,
  dispatch,
  navigate,
  gradesData,
  studentsData,
  visibleFields,
  selectedAcadYear,
) {
  const columns = [
    {
      field: 'studentName',
      headerName: t('STUDENT_NAME'),
      hide: !visibleFields?.includes(t('STUDENT_NAME')),
      align: 'left',
      headerClassName: 'studentNameHeader',
      cellClassName: 'studentNameCell',
      sortable: true,
      headerAlign: 'left',
    },
    {
      field: 'parentName',
      headerName: t('PARENT_NAME'),
      hide: !visibleFields?.includes(t('PARENT_NAME')),
      align: 'left',
      headerClassName: 'parentHeader',
      cellClassName: 'parentCell',
      sortable: true,
      headerAlign: 'left',
    },
    {
      field: 'studentEmailAddress',
      headerName: t('STUDENT_EMAIL_ADDRESS'),
      hide: !visibleFields?.includes(t('STUDENT_EMAIL_ADDRESS')),
      sortable: true,
      headerAlign: 'left',
      align: 'left',
      headerClassName: 'emailHeader',
      cellClassName: 'emailCell',
    },
    {
      field: 'marksS1',
      disableColumnResize: 'false',
      hide: !visibleFields?.includes(t('S1_EXAM')),
      sortable: false,
      headerName: `S1 ${t('EXAM')}`,
      align: 'center',
      headerClassName: 'marksHeader',
      cellClassName: 'marksCell',
      headerAlign: 'center',
      renderCell: (cellValues) => (
        <Box
          component="span"
          onClick={(e) => setOpenDialog(e, cellValues, 'marks', 'S1')}
          className={isNullOrUndefined(cellValues?.row?.marks?.S1?.passingCriteria) && !cellValues?.row?.marks?.S1?.isAttended ? classes.marksRed
            : cellValues?.row?.marks?.S1?.calculatedWeightage >= cellValues?.row?.marks?.S1?.passingCriteria ? classes.marksGreen
              : cellValues?.row?.marks?.S1?.calculatedWeightage < parseInt(cellValues?.row?.marks?.S1?.passingCriteria, 10) - 10 ? classes.marksRed
                : cellValues?.row?.marks?.S1?.calculatedWeightage >= parseInt(cellValues?.row?.marks?.S1?.passingCriteria, 10) - 10 ? classes.marksYellow
                  : classes.marksGrey}
        >
          {isNullOrUndefined(cellValues?.row?.marks?.S1?.passingCriteria) && cellValues?.row?.marks?.S1?.passingCriteria >= 0
            ? Number.isInteger(cellValues?.row?.marks?.S1?.calculatedWeightage) ? cellValues?.row?.marks?.S1?.calculatedWeightage : Number(cellValues?.row?.marks?.S1?.calculatedWeightage).toFixed(2) : '-'}
          {isNullOrUndefined(cellValues?.row?.marks?.S1?.passingCriteria) && !cellValues?.row?.marks?.S1?.isAttended ? '(A)' : ''}
        </Box>
      ),
    },
    {
      field: 'homeworkS1',
      hide: !visibleFields?.includes(t('S1_HW')),
      sortable: false,
      headerName: `S1 ${t('HW')}`,
      align: 'center',
      headerClassName: 'marksHeader',
      cellClassName: 'marksCell',
      headerAlign: 'center',
      renderCell: (cellValues) => (
        <Box
          component="span"
          onClick={(e) => setOpenDialog(e, cellValues, 'homework-marks', 'S1')}
          className={isNullOrUndefined(cellValues?.row?.homeworkMarks?.S1?.weightage) && cellValues?.row?.homeworkMarks?.S1?.weightage >= 0
            ? classes.homeworkColor : classes.marksGrey}
        >
          {isNullOrUndefined(cellValues?.row?.homeworkMarks?.S1?.weightage) && cellValues?.row?.homeworkMarks?.S1?.weightage >= 0
            ? Number.isInteger(cellValues?.row?.homeworkMarks?.S1?.calculatedWeightage) ? cellValues?.row?.homeworkMarks?.S1?.calculatedWeightage : Number(cellValues?.row?.homeworkMarks?.S1?.calculatedWeightage).toFixed(2) : '-'}
        </Box>
      ),
    },
    {
      field: 'quizS1Q1',
      hide: !visibleFields?.includes(t('S1_QUIZ1')),
      sortable: false,
      headerName: `S1 ${t('QUIZ1')}`,
      align: 'center',
      headerClassName: 'marksHeader',
      cellClassName: 'marksCell',
      headerAlign: 'center',
      renderCell: (cellValues) => (
        <Box
          component="span"
          onClick={(e) => setOpenDialog(e, cellValues, 'quiz1-marks', 'S1')}
          className={((isNullOrUndefined(cellValues?.row?.quizMarks?.['S1Quiz 1']?.weightage) && cellValues?.row?.quizMarks?.['S1Quiz 1']?.weightage >= 0))
            ? classes.quizColor : classes.marksGrey}
        >
          {((isNullOrUndefined(cellValues?.row?.quizMarks?.['S1Quiz 1']?.weightage) && cellValues?.row?.quizMarks?.['S1Quiz 1']?.weightage >= 0))
            ? getTotalQuiz1Marks('S1', cellValues?.row) : '-'}
        </Box>
      ),
    },
    {
      field: 'quizS1Q2',
      hide: (selectedAcadYear !== '2023-2024') || !visibleFields?.includes(t('S1_QUIZ2')),
      sortable: false,
      headerName: `S1 ${t('QUIZ2')}`,
      align: 'center',
      headerClassName: 'marksHeader',
      cellClassName: 'marksCell',
      headerAlign: 'center',
      renderCell: (cellValues) => (
        <Box
          component="span"
          onClick={(e) => setOpenDialog(e, cellValues, 'quiz2-marks', 'S1')}
          className={((isNullOrUndefined(cellValues?.row?.quizMarks?.['S1Quiz 2']?.weightage) && cellValues?.row?.quizMarks?.['S1Quiz 2']?.weightage >= 0))
            ? classes.quizColor : classes.marksGrey}
        >
          {((isNullOrUndefined(cellValues?.row?.quizMarks?.['S1Quiz 2']?.weightage) && cellValues?.row?.quizMarks?.['S1Quiz 2']?.weightage >= 0))
            ? getTotalQuiz2Marks('S1', cellValues?.row) : '-'}
        </Box>
      ),
    },
    {
      field: 'assignmentS1',
      hide: !visibleFields?.includes(t('S1_ASSIGNMENT')),
      sortable: false,
      headerName: `S1 ${t('ASS..MENT')}`,
      align: 'center',
      headerClassName: 'marksHeaderSpan',
      cellClassName: 'marksCell',
      headerAlign: 'left',
      renderCell: (cellValues) => (
        <Box
          component="span"
          onClick={(e) => setOpenDialog(e, cellValues, 'assignments', 'S1')}
          className={isNullOrUndefined(cellValues?.row?.assignmentMarks?.S1?.weightage) && cellValues?.row?.assignmentMarks?.S1?.weightage >= 0
            ? classes.assignmentsColor : classes.marksGrey}
        >
          {isNullOrUndefined(cellValues?.row?.assignmentMarks?.S1?.weightage) && cellValues?.row?.assignmentMarks?.S1?.weightage >= 0
            ? Number.isInteger(cellValues?.row?.assignmentMarks?.S1?.calculatedWeightage) ? cellValues?.row?.assignmentMarks?.S1?.calculatedWeightage : Number(cellValues?.row?.assignmentMarks?.S1?.calculatedWeightage).toFixed(2) : '-'}
        </Box>
      ),
    },
    {
      field: 'curricularS1',
      hide: !visibleFields?.includes(t('S1_CO-CURRICULAR')),
      sortable: false,
      headerName: `S1 ${t('Co-curricular')}`,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'marksHeader',
      cellClassName: 'marksCell',
      renderCell: (cellValues) => (
        <Box
          component="span"
          onClick={(e) => setOpenDialog(e, cellValues, 'curricular-marks', 'S1')}
          className={isNullOrUndefined(cellValues?.row?.attendenceActivitiesMarks?.S1?.weightage) && cellValues?.row?.attendenceActivitiesMarks?.S1?.weightage >= 0
            ? classes.curricularColor : classes.marksGrey}
        >
          {isNullOrUndefined(cellValues?.row?.attendenceActivitiesMarks?.S1?.weightage) && cellValues?.row?.attendenceActivitiesMarks?.S1?.weightage >= 0
            ? Number.isInteger(cellValues?.row?.attendenceActivitiesMarks?.S1?.calculatedWeightage) ? cellValues?.row?.attendenceActivitiesMarks?.S1?.calculatedWeightage : Number(cellValues?.row?.attendenceActivitiesMarks?.S1?.calculatedWeightage).toFixed(2) : '-'}
        </Box>
      ),
    },
    {
      field: 'totalS1',
      hide: !visibleFields?.includes(t('S1_TOTAL')),
      headerName: `S1 ${t('Total')}`,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'marksHeader',
      cellClassName: 'marksCell',
      sortable: false,
      renderCell: (cellValues) => (
        <span
          className={classes.marksGrey}
        >
          {(cellValues?.row?.S1Total)}
        </span>
      ),
    },
    {
      field: 'gradeS1',
      hide: !visibleFields?.includes(t('S1_GRADE')),
      headerName: `S1 ${t('GRADE')}`,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'marksHeader',
      cellClassName: 'borderCell',
      sortable: false,
      renderCell: (cellValues) => (
        <span
          className={classes.marksGrey}
        >
          {getGradeforScore(cellValues?.row?.S1Total, gradesData?.gradeData)}
        </span>
      ),
    },
    {
      field: 'marksS2',
      hide: !visibleFields?.includes(t('S2_EXAM')),
      headerName: `S2 ${t('EXAM')}`,
      headerAlign: 'center',
      disableColumnResize: 'false',
      align: 'center',
      headerClassName: 'marksHeader',
      cellClassName: 'marksCell',
      sortable: false,
      renderCell: (cellValues) => (
        <Box
          component="span"
          onClick={(e) => setOpenDialog(e, cellValues, 'marks', 'S2')}
          className={isNullOrUndefined(cellValues?.row?.marks?.S2?.passingCriteria) && !cellValues?.row?.marks?.S2?.isAttended ? classes.marksRed
            : cellValues?.row?.marks?.S2?.calculatedWeightage >= cellValues?.row?.marks?.S2?.passingCriteria ? classes.marksGreen
              : cellValues?.row?.marks?.S2?.calculatedWeightage < parseInt(cellValues?.row?.marks?.S2?.passingCriteria, 10) - 10 ? classes.marksRed
                : cellValues?.row?.marks?.S2?.calculatedWeightage >= parseInt(cellValues?.row?.marks?.S2?.passingCriteria, 10) - 10 ? classes.marksYellow
                  : classes.marksGrey}
        >
          {isNullOrUndefined(cellValues?.row?.marks?.S2?.passingCriteria) && cellValues?.row?.marks?.S2?.passingCriteria >= 0
            ? Number.isInteger(cellValues?.row?.marks?.S2?.calculatedWeightage) ? cellValues?.row?.marks?.S2?.calculatedWeightage : Number(cellValues?.row?.marks?.S2?.calculatedWeightage).toFixed(2) : '-'}
          {isNullOrUndefined(cellValues?.row?.marks?.S2?.passingCriteria) && !cellValues?.row?.marks?.S2?.isAttended ? '(A)' : ''}
        </Box>
      ),
    },
    {
      field: 'homeworkS2',
      hide: !visibleFields?.includes(t('S2_HW')),
      headerName: `S2 ${t('HW')}`,
      headerAlign: 'center',
      disableColumnResize: 'false',
      align: 'center',
      headerClassName: 'marksHeader',
      cellClassName: 'marksCell',
      sortable: false,
      renderCell: (cellValues) => (
        <Box
          component="span"
          onClick={(e) => setOpenDialog(e, cellValues, 'homework-marks', 'S2')}
          className={isNullOrUndefined(cellValues?.row?.homeworkMarks?.S2?.weightage) && cellValues?.row?.homeworkMarks?.S2?.weightage >= 0
            ? classes.homeworkColor : classes.marksGrey}
        >
          {isNullOrUndefined(cellValues?.row?.homeworkMarks?.S2?.weightage) && cellValues?.row?.homeworkMarks?.S2?.weightage >= 0
            ? Number.isInteger(cellValues?.row?.homeworkMarks?.S2?.calculatedWeightage) ? cellValues?.row?.homeworkMarks?.S2?.calculatedWeightage : Number(cellValues?.row?.homeworkMarks?.S2?.calculatedWeightage).toFixed(2) : '-'}
        </Box>
      ),
    },
    {
      field: 'quizS2Q1',
      hide: (!visibleFields?.includes(t('S2_QUIZ3')) && !visibleFields?.includes(t('S2_QUIZ2'))),
      sortable: false,
      headerName: `S2 ${(selectedAcadYear !== '2023-2024') ? t('QUIZ2') : t('QUIZ3')}`,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'marksHeader',
      cellClassName: 'marksCell',
      renderCell: (cellValues) => (
        <Box
          component="span"
          onClick={(e) => setOpenDialog(e, cellValues, 'quiz1-marks', 'S2')}
          className={((isNullOrUndefined(cellValues?.row?.quizMarks?.['S2Quiz 1']?.weightage) && cellValues?.row?.quizMarks?.['S2Quiz 1']?.weightage >= 0))
            ? classes.quizColor : classes.marksGrey}
        >
          {((isNullOrUndefined(cellValues?.row?.quizMarks?.['S2Quiz 1']?.weightage) && cellValues?.row?.quizMarks?.['S2Quiz 1']?.weightage >= 0))
            ? getTotalQuiz1Marks('S2', cellValues?.row) : '-'}
        </Box>
      ),
    },
    {
      field: 'quizS2Q2',
      sortable: false,
      hide: (selectedAcadYear !== '2023-2024') || !visibleFields?.includes(t('S2_QUIZ4')),
      headerName: `S2 ${t('QUIZ4')}`,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'marksHeader',
      cellClassName: 'marksCell',
      renderCell: (cellValues) => (
        <Box
          component="span"
          onClick={(e) => setOpenDialog(e, cellValues, 'quiz2-marks', 'S2')}
          className={((isNullOrUndefined(cellValues?.row?.quizMarks?.['S2Quiz 2']?.weightage) && cellValues?.row?.quizMarks?.['S2Quiz 2']?.weightage >= 0))
            ? classes.quizColor : classes.marksGrey}
        >
          {((isNullOrUndefined(cellValues?.row?.quizMarks?.['S2Quiz 2']?.weightage) && cellValues?.row?.quizMarks?.['S2Quiz 2']?.weightage >= 0))
            ? getTotalQuiz2Marks('S2', cellValues?.row) : '-'}
        </Box>
      ),
    },
    {
      field: 'assignmentS2',
      sortable: false,
      hide: !visibleFields?.includes(t('S2_ASSIGNMENT')),
      headerName: `S2 ${t('ASS..MENT')}`,
      headerAlign: 'left',
      align: 'center',
      headerClassName: 'marksHeaderSpan',
      cellClassName: 'marksCell',
      renderCell: (cellValues) => (
        <Box
          component="span"
          onClick={(e) => setOpenDialog(e, cellValues, 'assignments', 'S2')}
          className={isNullOrUndefined(cellValues?.row?.assignmentMarks?.S2?.weightage) && cellValues?.row?.assignmentMarks?.S2?.weightage >= 0
            ? classes.assignmentsColor : classes.marksGrey}
        >
          {isNullOrUndefined(cellValues?.row?.assignmentMarks?.S2?.weightage) && cellValues?.row?.assignmentMarks?.S2?.weightage >= 0
            ? Number.isInteger(cellValues?.row?.assignmentMarks?.S2?.calculatedWeightage) ? cellValues?.row?.assignmentMarks?.S2?.calculatedWeightage : Number(cellValues?.row?.assignmentMarks?.S2?.calculatedWeightage).toFixed(2) : '-'}
        </Box>
      ),
    },
    {
      field: 'curricularS2',
      sortable: false,
      hide: !visibleFields?.includes(t('S2_CO-CURRICULAR')),
      headerName: `S2 ${t('Co-curricular')}`,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'marksHeader',
      cellClassName: 'marksCell',
      renderCell: (cellValues) => (
        <Box
          component="span"
          onClick={(e) => setOpenDialog(e, cellValues, 'curricular-marks', 'S2')}
          className={isNullOrUndefined(cellValues?.row?.attendenceActivitiesMarks?.S2?.weightage) && cellValues?.row?.attendenceActivitiesMarks?.S2?.weightage >= 0
            ? classes.curricularColor : classes.marksGrey}
        >
          {isNullOrUndefined(cellValues?.row?.attendenceActivitiesMarks?.S2?.weightage) && cellValues?.row?.attendenceActivitiesMarks?.S2?.weightage >= 0
            ? Number.isInteger(cellValues?.row?.attendenceActivitiesMarks?.S2?.calculatedWeightage) ? cellValues?.row?.attendenceActivitiesMarks?.S2?.calculatedWeightage : Number(cellValues?.row?.attendenceActivitiesMarks?.S2?.calculatedWeightage).toFixed(2) : '-'}
        </Box>
      ),
    },
    {
      field: 'totalS2',
      hide: !visibleFields?.includes(t('S2_TOTAL')),
      headerName: `S2 ${t('Total')}`,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'marksHeader',
      cellClassName: 'marksCell',
      sortable: false,
      renderCell: (cellValues) => (
        <span
          className={classes.marksGrey}
        >
          {(cellValues?.row?.S2Total)}
        </span>
      ),
    },
    {
      field: 'gradeS2',
      hide: !visibleFields?.includes(t('S2_GRADE')),
      headerName: `S2 ${t('GRADE')}`,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'marksHeader',
      cellClassName: 'borderCell',
      sortable: false,
      renderCell: (cellValues) => (
        <span
          className={classes.marksGrey}
        >
          {getGradeforScore(cellValues?.row?.S2Total, gradesData?.gradeData)}
        </span>
      ),
    },
    {
      field: 'marksS3',
      hide: !visibleFields?.includes(t('PSTU/Supplementary')),
      headerName: getSemester3Header(t),
      headerAlign: 'center',
      disableColumnResize: 'false',
      align: 'center',
      headerClassName: 'marksHeaderSpan',
      cellClassName: 'marksCell',
      sortable: false,
      renderCell: ((cellValues) => renderS3Cell(cellValues, studentsData, setOpenDialog, classes)),
    },
    {
      field: 'grade',
      hide: !visibleFields?.includes(t('GRADE')),
      headerName: t('GRADE'),
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      headerClassName: 'semGradeHeader',
      cellClassName: 'semGradeCell',
      renderCell: (cellValues) => renderGradeCell(cellValues),
    },
    {
      field: 'gpa',
      hide: !visibleFields?.includes(t('GPA')),
      headerName: t('GPA'),
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      headerClassName: 'semGpaHeader',
      cellClassName: 'semGpaCell',
      renderCell: (cellValues) => renderGpaCell(cellValues),
    },
    {
      field: 'annualScore',
      hide: !visibleFields?.includes(t('ANNUAL_SCORE')),
      headerName: t('ANNUAL_SCORE'),
      headerAlign: 'center',
      align: 'center',
      sortable: true,
      headerClassName: 'commonHeader',
      cellClassName: 'commonCell',
      renderCell: (cellValues) => renderAnnualScoreCell(cellValues),
    },
    {
      field: 'actions',
      hide: !visibleFields?.includes(t('ACTIONS')),
      headerName: t('ACTIONS'),
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      headerClassName: 'commonHeader',
      cellClassName: 'semesterActionCells',
      renderCell: (row) => (
        <Grid item xs={12} lg={3} display="flex">
          <Tooltip title={t('SEND_EMAIL')}>
            <IconButton
              className={classes.emailIconColor}
              onClick={() => {
                const { manabadiEmail } = row.row.studentInfo;
                const emailParents = [];
                emailParents.push(row?.row?.parent1Info?.personalEmail?.toString());
                emailParents.push(row?.row?.parent2Info?.personalEmail?.toString());
                dispatch({ type: Constant.RECIPIENTS, payload: [manabadiEmail] });
                dispatch({ type: Constant.MAIL_FILTER, payload: 'Student' });
                dispatch({ type: Constant.MAIL_PARENTS, payload: emailParents });
                setLocalStorage('showLocationFilterRecipients', false);
                setLocalStorage('showLocationAnnouncementsRecipients', false);
                setLocalStorage('showSelectAllinEmail', false);
                localStorage.setItem('sendSingleEmail', true);
                navigate(NavigateRoutes.TEACHER_VIEW_EMAIL);
              }}
            >
              <EmailOutlinedIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      ),
    },
  ];

  return columns;
}

export {
  getQuarterColumnsData,
  getSemesterColumnsData,
  getQuarterCSVHeaders,
  getAllQuarterFields,
  getSemesterCSVHeaders,
  getAllSemesterFields,
  getAllSemesterFields2023,
};
