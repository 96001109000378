/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-nested-ternary */
import React, { useRef, useState, useEffect } from 'react';
import clsx from 'clsx';
import {
  Divider,
  Grid,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from '@mui/material';
import axios from 'axios';
import { useReactToPrint } from 'react-to-print';
import { useSelector, useDispatch } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import PrintIcon from '@mui/icons-material/LocalPrintshopOutlined';
import CloseIcon from '@mui/icons-material/Close';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useTranslation } from 'react-i18next';
import CardMedia from '@mui/material/CardMedia';
import DialogContent from '@mui/material/DialogContent';
import DialogAtom from '../../../components/atoms/dialog';
import LinearProgressBar from '../../../components/atoms/progress-bar';
import useStyles from '../../../custom-hooks/useStyles';
import style from './style';
import Dropdown from '../../../components/atoms/dropdown';
import Cert from '../../../assets/images/cert.png';
import ButtonAtom from '../../../components/atoms/button';
import { Buttons } from '../../../constant';
import { getStudentDetailsByYear } from '../../../store/actions/getStudent';
import { toolTipTheme } from '../../../utils/commonUiComponent';

import {
  calculateAnnualScore, checkFLC, checkPSTU, getCurrentYear, getGradeforScore, isNullOrUndefined,
} from '../../../utils/methods';
import { getLocalStorage } from '../../../utils/localStorageMethod';
import { getGrades } from '../../../store/actions/getGrades';

const upperCaseFirstLetter = (string) => {
  const newString = string?.toString().toLowerCase();
  let response = 'N/A';
  if (newString) {
    response = newString?.charAt(0).toUpperCase() + newString?.slice(1);
  }
  return response;
};

function isValidAnnualScore(validSemesterYear, enrollDetails) {
  if (validSemesterYear) {
    return ((isNullOrUndefined(enrollDetails?.S2Attended) && isNullOrUndefined(enrollDetails?.S1Attended)) || isNullOrUndefined(enrollDetails?.S3Attended));
  }
  return (enrollDetails?.Q1Attended || enrollDetails?.Q2Attended || enrollDetails?.Q3Attended);
}

function getBarColor(classes, marks, passingCriteria, type, percentage) {
  switch (type) {
    case 'marks':
      if (marks < (passingCriteria - 10)) {
        return classes.failColor;
      } if (marks >= (passingCriteria - 10) && marks < passingCriteria) {
        return classes.averageColor;
      } if (marks >= passingCriteria) {
        return classes.passColor;
      }
      return classes.passColor;

    case 'homework':
      if (percentage <= 30) {
        return classes.homeworkColor;
      }
      if (percentage > 30 && percentage < 65) {
        return classes.homeworkColor;
      }
      return classes.homeworkColor;

    case 'attendance':
      if (percentage <= 30) {
        return classes.attendanceColor;
      }
      if (percentage > 30 && percentage < 65) {
        return classes.attendanceColor;
      }
      return classes.attendanceColor;

    case 'assignments':
      if (percentage <= 30) {
        return classes.assignmentsColor;
      }
      if (percentage > 30 && percentage < 65) {
        return classes.assignmentsColor;
      }
      return classes.assignmentsColor;

    case 'quiz1':
      if (percentage <= 30) {
        return classes.quizColor;
      }
      if (percentage > 30 && percentage < 65) {
        return classes.quizColor;
      }
      return classes.quizColor;

    case 'quiz2':
      if (percentage <= 30) {
        return classes.quizColor;
      }
      if (percentage > 30 && percentage < 65) {
        return classes.quizColor;
      }
      return classes.quizColor;

    case 'curricular':
      if (percentage <= 30) {
        return classes.curricularColor;
      }
      if (percentage > 30 && percentage < 65) {
        return classes.curricularColor;
      }
      return classes.curricularColor;

    case 'total':
      if (percentage <= 30) {
        return classes.totalColor;
      }
      if (percentage > 30 && percentage < 65) {
        return classes.totalColor;
      }
      return classes.totalColor;

    default:
      return '';
  }
}

function getImageFromURL(driveURL) {
  let displayImgURL = 'http://drive.google.com/uc?export=view&id=';
  if (driveURL) {
    const mySubString = driveURL?.substring(
      driveURL.indexOf('d/') + 1,
      driveURL.lastIndexOf('/view'),
    );
    const imageId = mySubString?.replace('/', '');
    displayImgURL += imageId;
  }
  return displayImgURL;
}

function ProgressWithTitleSemester(title, enrollDetails, classes, courseName, type = 'marks') {
  const getProgressValue = (quarter, max = false) => {
    const q = quarter === 1 ? 'S1'
      : quarter === 2 ? 'S2' : 'S3';
    if (!enrollDetails || !Object.keys(enrollDetails).length) {
      return 0;
    }

    switch (type) {
      case 'marks':
        if (checkPSTU(courseName)) {
          return enrollDetails[`${q}${max ? 'MaxMarks' : 'Marks'}`] || 0;
        }
        return enrollDetails[`${q}${max ? 'MaxMarks' : 'Marks'}`] || 0;

      case 'homework':
        if (enrollDetails?.homeworkMarks?.[quarter - 1]?.studentHomeworkMarksDetail?.every((course) => course.obtainedMarks === null)) {
          return 0;
        }
        return enrollDetails[`${q}${max ? 'MaxHWMarks' : 'HWMarks'}`] || 0;
      case 'quiz1':
        return enrollDetails[`${q}${max ? 'MaxQuiz1Marks' : 'Quiz1Marks'}`] || 0;
      case 'quiz2':
        return enrollDetails[`${q}${max ? 'MaxQuiz2Marks' : 'Quiz2Marks'}`] || 0;
      case 'assignments':
        return enrollDetails[`${q}${max ? 'MaxAssignmentWeightageMarks' : 'AssignmentMarks'}`] || 0;
      case 'curricular':
        return enrollDetails[`${q}${max ? 'MaxCurricularMarks' : 'CurricularMarks'}`] || 0;
      case 'total':
        return enrollDetails[`${q}${max ? 'MaxTotalMarks' : 'TotalMarks'}`] || 0;
      default:
        return 0;
    }
  };

  const calculatePercentage = (obtained, max) => {
    return obtained && max ? parseInt((obtained / max) * 100, 10) : 0;
  };

  return (
    <Grid container className={classes.container} spacing="2">
      <Grid item xs={3} sm={2}>{title}</Grid>
      {(type !== 'marks' && type !== 'total' && checkFLC(courseName))
        ? (
          <Grid
            xs={3}
            sm={3.4}
          >
            <span style={{ paddingLeft: '0.2vw' }}>N/A</span>
          </Grid>
        )
        : (
          <Grid
            className={clsx(getBarColor(classes, enrollDetails.S1Marks, enrollDetails.S1PassingCriteria, type, calculatePercentage(
              getProgressValue(1),
              getProgressValue(1, true),
            )), classes.progressBar)}
            item
            xs={3}
            sm={3.4}
          >
            <LinearProgressBar
              value={getProgressValue(1)}
              maximumMarks={getProgressValue(1, true)}
              totalPercent
              percentage={calculatePercentage(getProgressValue(1), getProgressValue(1, true))}
              maxPerc={100}
            />
          </Grid>
        )}

      {(type !== 'marks' && type !== 'total' && checkFLC(courseName))
        ? (
          <Grid
            xs={3}
            sm={2.5}
            className={classes.borderRight}
          >
            <span style={{ paddingLeft: '0.4vw' }}>N/A</span>
          </Grid>
        )
        : (

          <Grid
            className={clsx(getBarColor(classes, enrollDetails.S2Marks, enrollDetails.S2PassingCriteria, type, calculatePercentage(
              getProgressValue(2),
              getProgressValue(2, true),
            )), classes.progressBar, classes.borderRight)}
            item
            xs={3}
            sm={2.5}
          >
            <LinearProgressBar
              value={getProgressValue(2)}
              totalPercent
              maximumMarks={getProgressValue(2, true)}
              percentage={calculatePercentage(getProgressValue(2), getProgressValue(2, true))}
              maxPerc={100}
            />
          </Grid>
        )}

      {(type === 'marks' && !checkPSTU(courseName) && courseName !== 'Telugu-1' && courseName !== 'Telugu-3') ? (
        <Grid
          item
          xs={3}
          sm={3.2}
          className={clsx(getBarColor(classes, enrollDetails.S3Marks, enrollDetails.S3PassingCriteria, type, calculatePercentage(
            getProgressValue(3),
            getProgressValue(3, true),
          )), classes.progressBar)}
          style={{ paddingLeft: '3vw' }}
        >
          <LinearProgressBar
            value={getProgressValue(3)}
            totalPercent
            maximumMarks={getProgressValue(3, true)}
            percentage={calculatePercentage(getProgressValue(3), getProgressValue(3, true))}
            maxPerc={100}
          />
        </Grid>
      ) : (type === 'marks' && checkPSTU(courseName)) ? (
        <Grid
          container
          xs={3}
          sm={4}
        >
          {enrollDetails?.category?.length > 0 ? (
            enrollDetails?.category?.map((obj) => (
              <>
                <Grid style={{ paddingRight: '1vw', paddingLeft: '3vw' }}>
                  {obj.title}
                </Grid>
                <Grid
                  item
                  className={clsx(getBarColor(classes, obj.marks, enrollDetails.S3PassingCriteria, type, calculatePercentage(
                    obj.marks,
                    obj.maxMarks,
                  )), classes.progressBar)}
                >
                  <LinearProgressBar
                    value={obj.marks}
                    totalPercent
                    maximumMarks={obj.maxMarks}
                    percentage={calculatePercentage(obj.marks, obj.maxMarks)}
                    maxPerc={obj.maxMarks}
                  />
                </Grid>
              </>

            ))
          ) : (
            <Grid
              item
              className={clsx(getBarColor(classes, 0, enrollDetails.S3PassingCriteria, type, calculatePercentage(
                0,
                0,
              )), classes.progressBar)}
              style={{ paddingLeft: '3vw' }}
            >
              <LinearProgressBar
                value={0}
                totalPercent
                maximumMarks={0}
                percentage={calculatePercentage(0, 0)}
                maxPerc={0}
              />
            </Grid>
          )}

        </Grid>
      ) : (type === 'total' && checkPSTU(courseName)) ? (
        <Grid
          container
          xs={3}
          sm={4}
        >
          {enrollDetails?.category?.length > 0 ? (
            (() => {
              const totalMarks = enrollDetails.category.reduce((sum, obj) => sum + obj.marks, 0);
              const totalMaxMarks = 300;
              const percentage = calculatePercentage(totalMarks, totalMaxMarks);
              return (
                <Grid
                  item
                  className={clsx(getBarColor(classes, totalMarks, enrollDetails.S3PassingCriteria, type, percentage), classes.progressBar)}
                >
                  <LinearProgressBar
                    value={totalMarks}
                    totalPercent
                    maximumMarks={totalMaxMarks}
                    percentage={percentage}
                    maxPerc={totalMaxMarks}
                  />
                </Grid>
              );
            })()
          ) : (
            <Grid
              item
              className={clsx(getBarColor(classes, 0, enrollDetails.S3PassingCriteria, type, calculatePercentage(
                0,
                0,
              )), classes.progressBar)}
              style={{ paddingLeft: '3vw' }}
            >
              <LinearProgressBar
                value={0}
                totalPercent
                maximumMarks={0}
                percentage={calculatePercentage(0, 0)}
                maxPerc={0}
              />
            </Grid>
          )}

        </Grid>
      ) : (type === 'total') ? (<span style={{ paddingLeft: '3vw' }}>  </span>) : (<span style={{ paddingLeft: '3vw' }}>N/A</span>)}

    </Grid>
  );
}

function ProgressWithTitle(title, enrollDetails, classes, type = 'marks') {
  const getProgressValue = (quarter, max = false) => {
    const q = quarter === 1 ? 'Q1' : quarter === 2 ? 'Q2' : 'Q3';
    if (!enrollDetails || !Object.keys(enrollDetails).length || !enrollDetails[`${q}Marks`]) {
      return 0;
    }
    switch (type) {
      case 'marks':
        return enrollDetails[`${q}${max ? 'MaxMarks' : 'Marks'}`] || 0;
      case 'homework':
        if (enrollDetails?.homeworkMarks?.[quarter - 1]?.studentHomeworkMarksDetail?.every((course) => course.obtainedMarks === null)) {
          return 0;
        }
        return enrollDetails[`${q}${max ? 'MaxHWMarks' : 'HWMarks'}`] || 0;
      case 'attendance':
        return enrollDetails[`${q}${max ? 'TotalWeeks' : 'TotalPresent'}`] || 0;
      default:
        return '';
    }
  };

  const calculatePercentage = (obtained, max) => {
    return obtained && max ? parseInt((obtained / max) * 100, 10) : 0;
  };

  return (
    <Grid container className={classes.container} spacing="2">
      <Grid item xs={3} sm={2}>{title}</Grid>
      <Grid
        className={clsx(getBarColor(classes, enrollDetails.Q1Marks, enrollDetails.Q1PassingCriteria, type, calculatePercentage(
          getProgressValue(1),
          getProgressValue(1, true),
        )), classes.progressBar)}
        item
        xs={3}
        sm={3.4}
      >
        <LinearProgressBar
          value={getProgressValue(1)}
          maximumMarks={getProgressValue(1, true)}
          totalPercent
          percentage={calculatePercentage(getProgressValue(1), getProgressValue(1, true))}
          maxPerc={100}
        />
      </Grid>

      <Grid
        className={clsx(getBarColor(classes, enrollDetails.Q2Marks, enrollDetails.Q2PassingCriteria, type, calculatePercentage(
          getProgressValue(2),
          getProgressValue(2, true),
        )), classes.progressBar)}
        item
        xs={3}
        sm={3.4}
      >
        <LinearProgressBar
          value={getProgressValue(2)}
          totalPercent
          maximumMarks={getProgressValue(2, true)}
          percentage={calculatePercentage(getProgressValue(2), getProgressValue(2, true))}
          maxPerc={100}
        />
      </Grid>

      <Grid
        item
        xs={3}
        sm={3.2}
        className={clsx(getBarColor(classes, enrollDetails.Q3Marks, enrollDetails.Q3PassingCriteria, type, calculatePercentage(
          getProgressValue(3),
          getProgressValue(3, true),
        )), classes.progressBar)}
      >
        <LinearProgressBar
          value={getProgressValue(3)}
          totalPercent
          maximumMarks={getProgressValue(3, true)}
          percentage={calculatePercentage(getProgressValue(3), getProgressValue(3, true))}
          maxPerc={100}
        />
      </Grid>
    </Grid>
  );
}

function gradeBanner(key, value, styles) {
  return (
    <Grid container spacing="2" xs={12} className={styles.container}>
      <Grid item xs={5.8} className={styles.label}>
        {key}
      </Grid>
      <Grid item xs={6} className={styles.value}>
        :
        &nbsp;
        &nbsp;
        {value}
      </Grid>
    </Grid>
  );
}
export default function ReportCard({
  formik,
  studentId,
  courseName,
  section,
  hallTicketNumber,
  result,
  studentDetails,
  selectedYear, setSelectedYear,
  studentAccountDetails,
  hallTicketURL,
  certificateURL,
  validSemesterYear,
}) {
  const { t } = useTranslation();
  const classes = useStyles(style)();
  const [assignedYears, setAssignedYears] = useState([]);
  const [yearSelected, setYearSelected] = useState([]);
  const [isYearSelected, setIsYearSelected] = useState(false);
  const [accessToken, setAccessToken] = useState();
  const [isShowCertificate, setIsShowCertificate] = useState(true);
  const gradesData = useSelector((state) => state.getGrade);
  const allEnrollDetails = useSelector((state) => state?.getStudent?.enrollmentDetails);
  const enrollDetails = Object.keys(allEnrollDetails).length ? allEnrollDetails[studentId] ? allEnrollDetails[studentId] : {} : {};

  const getQuarterDetails = (quarter) => {
    const quarterDetails = enrollDetails.homeworkMarks?.filter((obj) => obj?.homeworkPanel?.quarter === quarter);
    const numWeeks = quarterDetails?.[0]?.studentHomeworkMarksDetail.reduce((maxWeek, obj) => {
      const getWeekNumber = (week) => {
        if (!week) return 0;
        const parts = week.split(' ');
        return parts.length > 1 ? parseInt(parts[1], 10) : parseInt(parts[0], 10);
      };
      const week = getWeekNumber(obj.homeworkPanelMarks?.week);
      return week > maxWeek ? week : maxWeek;
    }, 0);
    const homeworkDetails = [];
    if (quarterDetails) {
      const weeksSet = new Set(quarterDetails[0]?.studentHomeworkMarksDetail?.map((detail) => parseInt(detail?.homeworkPanelMarks?.week, 10)));

      for (let i = 1; i <= numWeeks; i++) {
        if (weeksSet.has(i)) {
          const homeworkMarkDetail = quarterDetails[0].studentHomeworkMarksDetail.find((detail) => parseInt(detail?.homeworkPanelMarks?.week, 10) === i);
          homeworkDetails.push(homeworkMarkDetail);
        } else {
          homeworkDetails.push({
            isPresent: false,
            obtainedMarks: 0,
            homeworkPanelMarks: {
              week: i,
            },
          });
        }
      }
    }
    return homeworkDetails;
  };

  const noMarks = () => {
    return <TableCell align="center">-</TableCell>;
  };
  const hasMarks = (quarter) => {
    return getQuarterDetails(quarter)?.length > 0 && <TableCell>&nbsp;</TableCell>;
  };
  const getErrorText = (key, errorText) => (formik.touched[key] && formik.errors[key] ? (
    <span data-testid={key} className={classes.errorText}>
      {formik.errors[key]}
    </span>
  ) : errorText ? (
    <span className={classes.errorText}>
      {errorText}
    </span>
  ) : null);

  const dispatch = useDispatch();

  useEffect(() => {
    const rawToken = JSON.parse(getLocalStorage('accessToken'));
    setAccessToken(rawToken.access_token);
  }, []);

  useEffect(() => {
    if (typeof yearSelected === 'string' && yearSelected) {
      dispatch(getStudentDetailsByYear(yearSelected, studentId));
      dispatch(getGrades());
    }
  }, [selectedYear]);

  useEffect(() => {
    if (!isYearSelected) {
      if (studentAccountDetails && Object.keys(studentAccountDetails).length) {
        const studentData = studentAccountDetails?.studentData;
        const currentStudent = studentData.find((student) => student.id === studentId);
        const academicYears = currentStudent ? currentStudent.academicYears : [];
        const yearOptions = academicYears.map((year) => ({ id: year.academic_year, name: year.academic_year }));

        const currentYear = getCurrentYear()?.id;
        const defaultYear = yearOptions.filter((opt) => parseInt(opt?.name, 10) === currentYear);

        setAssignedYears(yearOptions);
        if (selectedYear && Array.isArray(selectedYear) && !selectedYear.length) {
          const year = defaultYear.length ? defaultYear[0]?.id : yearOptions[0]?.id;
          setSelectedYear(year);
          setYearSelected(year);
        }
      } else {
        const obj = getCurrentYear();
        setAssignedYears([obj]);
      }
    }

    if (courseName === 'BalaBadi - I' || courseName === 'BalaBadi - II' || courseName === 'Pravesam') {
      setIsShowCertificate(true);
    }
  }, []);

  const getGradeValidation = (isAttended, totalMarks, gradeData) => {
    /* eslint-disable no-else-return */
    if (isAttended === true || isAttended === false) {
      return getGradeforScore(totalMarks, gradeData);
    } else {
      return 'N/A';
    }
  };

  const studentEnrollDetails = (details) => {
    const history = details;
    history.category = [];
    history?.marks?.forEach((detail) => {
      let marks = detail?.makeUpMarks || 0;
      if (checkPSTU(courseName)) {
        detail?.studentDetailMarks?.forEach((obj) => {
          marks += obj.obtainedMarks;
          if (detail?.academicPanel?.quarter === 'S3') {
            history.S3Marks = marks;
            history.category.push({
              title: obj?.academicPanelMarks?.notes,
              marks: obj?.obtainedMarks,
              maxMarks: obj?.academicPanelMarks?.marks,
            });
            history.S3Attended = detail.isAttended;
            history.S3MaxMarks = detail.academicPanel.maximumMarks;
            history.S3PassingCriteria = detail.academicPanel.passingCriteria;
            history.S3WeightageMarks = (detail.academicPanel.weightage / 100) * marks;
          }
        });
      } else {
        detail?.studentDetailMarks?.forEach((obj) => {
          marks += obj.obtainedMarks;
        });
        if (detail?.academicPanel?.quarter === 'S3') {
          history.S3Marks = marks;
          history.S3Attended = detail.isAttended;
          history.S3MaxMarks = detail.academicPanel.maximumMarks;
          history.S3PassingCriteria = detail.academicPanel.passingCriteria;
          history.S3WeightageMarks = (detail.academicPanel.weightage / 100) * marks;
        }
      }
      if (detail?.academicPanel?.quarter === 'Q1') {
        history.Q1Marks = marks;
        history.Q1Attended = detail?.isAttended;
        history.Q1MaxMarks = detail.academicPanel.maximumMarks;
        history.Q1PassingCriteria = detail.academicPanel.passingCriteria;
      } else if (detail?.academicPanel?.quarter === 'Q2') {
        history.Q2Marks = marks;
        history.Q2Attended = detail?.isAttended;
        history.Q2MaxMarks = detail.academicPanel.maximumMarks;
        history.Q2PassingCriteria = detail.academicPanel.passingCriteria;
      } else if (detail?.academicPanel?.quarter === 'Q3') {
        history.Q3Marks = marks;
        history.Q3Attended = detail.isAttended;
        history.Q3MaxMarks = detail.academicPanel.maximumMarks;
        history.Q3PassingCriteria = detail.academicPanel.passingCriteria;
      } else if (detail?.academicPanel?.quarter === 'S1') {
        history.S1Marks = marks;
        history.S1Attended = detail?.isAttended;
        history.S1MaxWeightageMarks = ((courseName !== 'Prabhasam' && courseName !== 'Prakasam') ? ((detail.academicPanel.weightage / 100) * detail.academicPanel.maximumMarks) : ((detail.academicPanel.weightage / 100) * detail.academicPanel.maximumMarks));
        history.S1MaxMarks = detail.academicPanel.maximumMarks;
        history.S1PassingCriteria = detail.academicPanel.passingCriteria;
        history.S1WeightageMarks = ((courseName !== 'Prabhasam' && courseName !== 'Prakasam') ? ((detail.academicPanel.weightage / 100) * marks) : ((detail.academicPanel.weightage / 100) * marks));
      } else if (detail?.academicPanel?.quarter === 'S2') {
        history.S2Marks = marks;
        history.S2Attended = detail?.isAttended;
        history.S2MaxWeightageMarks = ((courseName !== 'Prabhasam' && courseName !== 'Prakasam') ? ((detail.academicPanel.weightage / 100) * detail.academicPanel.maximumMarks) : ((detail.academicPanel.weightage / 100) * detail.academicPanel.maximumMarks));
        history.S2MaxMarks = detail.academicPanel.maximumMarks;
        history.S2PassingCriteria = detail.academicPanel.passingCriteria;
        history.S2WeightageMarks = ((courseName !== 'Prabhasam' && courseName !== 'Prakasam') ? ((detail.academicPanel.weightage / 100) * marks) : ((detail.academicPanel.weightage / 100) * marks));
      }
    });

    history?.homeworkMarks?.forEach((detail) => {
      let marks = 0;
      let totalPresent = 0;
      detail?.studentHomeworkMarksDetail?.sort((a, b) => a.homeworkPanelMarks.week - b.homeworkPanelMarks.week);
      detail?.studentHomeworkMarksDetail?.forEach((obj) => {
        marks += obj.obtainedMarks;
      });
      detail?.studentHomeworkMarksDetail?.forEach((obj) => {
        if (obj?.isPresent) totalPresent += 1;
      });
      if (detail?.homeworkPanel?.quarter === 'Q1') {
        history.Q1HWMarks = marks;
        history.Q1MaxHWMarks = detail.homeworkPanel.maximumMarks;
        history.Q1TotalPresent = totalPresent;
        history.Q1TotalWeeks = detail?.studentHomeworkMarksDetail?.length;
      } else if (detail?.homeworkPanel?.quarter === 'Q2') {
        history.Q2HWMarks = marks;
        history.Q2MaxHWMarks = detail.homeworkPanel.maximumMarks;
        history.Q2TotalPresent = totalPresent;
        history.Q2TotalWeeks = detail?.studentHomeworkMarksDetail?.length;
      } else if (detail?.homeworkPanel?.quarter === 'Q3') {
        history.Q3HWMarks = marks;
        history.Q3MaxHWMarks = detail.homeworkPanel.maximumMarks;
        history.Q3TotalPresent = totalPresent;
        history.Q3TotalWeeks = detail?.studentHomeworkMarksDetail?.length;
      } else if (detail?.homeworkPanel?.quarter === 'S1') {
        const numWeeks = detail?.studentHomeworkMarksDetail.reduce((maxWeek, obj) => {
          const getWeekNumber = (week) => {
            if (!week) return 0;
            const parts = week.split(' ');
            return parts.length > 1 ? parseInt(parts[1], 10) : parseInt(parts[0], 10);
          };
          const week = getWeekNumber(obj.homeworkPanelMarks?.week);
          return week > maxWeek ? week : maxWeek;
        }, 0);
        history.S1HWMarks = marks;
        history.S1MaxHWMarks = detail.homeworkPanel.maximumMarks;
        history.S1MaxHWWeightageMarks = ((courseName !== 'Prabhasam' && courseName !== 'Prakasam') ? ((detail.homeworkPanel.weightage / 100) * detail.homeworkPanel.maximumMarks) : ((detail.homeworkPanel.weightage / 100) * detail.homeworkPanel.maximumMarks));
        history.S1TotalPresent = totalPresent;
        history.S1TotalWeeks = numWeeks;
        history.S1WeightageHWMarks = ((courseName !== 'Prabhasam' && courseName !== 'Prakasam') ? ((detail.homeworkPanel.weightage / 100) * marks) : ((detail.homeworkPanel.weightage / 100) * marks));
      } else if (detail?.homeworkPanel?.quarter === 'S2') {
        const numWeeks = detail?.studentHomeworkMarksDetail.reduce((maxWeek, obj) => {
          const getWeekNumber = (week) => {
            if (!week) return 0;
            const parts = week.split(' ');
            return parts.length > 1 ? parseInt(parts[1], 10) : parseInt(parts[0], 10);
          };
          const week = getWeekNumber(obj.homeworkPanelMarks?.week);
          return week > maxWeek ? week : maxWeek;
        }, 0);
        history.S2HWMarks = marks;
        history.S2MaxHWMarks = detail.homeworkPanel.maximumMarks;
        history.S2MaxHWWeightageMarks = ((courseName !== 'Prabhasam' && courseName !== 'Prakasam') ? ((detail.homeworkPanel.weightage / 100) * detail.homeworkPanel.maximumMarks) : ((detail.homeworkPanel.weightage / 100) * detail.homeworkPanel.maximumMarks));
        history.S2TotalPresent = totalPresent;
        history.S2TotalWeeks = numWeeks;
        history.S2WeightageHWMarks = ((courseName !== 'Prabhasam' && courseName !== 'Prakasam') ? ((detail.homeworkPanel.weightage / 100) * marks) : ((detail.homeworkPanel.weightage / 100) * marks));
      } else if (detail?.homeworkPanel?.quarter === 'S3') {
        const numWeeks = detail?.studentHomeworkMarksDetail.reduce((maxWeek, obj) => {
          const getWeekNumber = (week) => {
            if (!week) return 0;
            const parts = week.split(' ');
            return parts.length > 1 ? parseInt(parts[1], 10) : parseInt(parts[0], 10);
          };
          const week = getWeekNumber(obj.homeworkPanelMarks?.week);
          return week > maxWeek ? week : maxWeek;
        }, 0);
        history.S3HWMarks = marks;
        history.S3MaxHWMarks = detail.homeworkPanel.maximumMarks;
        history.S3MaxHWWeightageMarks = ((courseName !== 'Prabhasam' && courseName !== 'Prakasam') ? ((detail.homeworkPanel.weightage / 100) * detail.homeworkPanel.maximumMarks) : ((detail.homeworkPanel.weightage / 100) * detail.homeworkPanel.maximumMarks));
        history.S3TotalPresent = totalPresent;
        history.S3TotalWeeks = numWeeks;
        history.S3WeightageHWMarks = ((courseName !== 'Prabhasam' && courseName !== 'Prakasam') ? ((detail.homeworkPanel.weightage / 100) * marks) : ((detail.homeworkPanel.weightage / 100) * marks));
      }
    });

    history?.assignmentMarks?.forEach((detail) => {
      let marks = 0;
      detail?.studentAssignmentDetailMarks?.forEach((obj) => {
        marks += obj.obtainedMarks;
      });

      if (detail?.assignmentSchema?.quarter === 'S1') {
        history.S1AssignmentMarks = marks;
        history.S1MaxAssignmentMarks = ((courseName !== 'Prabhasam' && courseName !== 'Prakasam') ? ((detail.assignmentSchema.weightage / 100) * detail.assignmentSchema.maximumMarks) : ((detail.assignmentSchema.weightage / 100) * detail.assignmentSchema.maximumMarks));
        history.S1MaxAssignmentWeightageMarks = detail.assignmentSchema.maximumMarks;
        history.S1WeightageAssignMarks = ((courseName !== 'Prabhasam' && courseName !== 'Prakasam') ? ((detail.assignmentSchema.weightage / 100) * marks) : ((detail.assignmentSchema.weightage / 100) * marks));
      } else if (detail?.assignmentSchema?.quarter === 'S2') {
        history.S2AssignmentMarks = marks;
        history.S2MaxAssignmentMarks = ((courseName !== 'Prabhasam' && courseName !== 'Prakasam') ? ((detail.assignmentSchema.weightage / 100) * detail.assignmentSchema.maximumMarks) : ((detail.assignmentSchema.weightage / 100) * detail.assignmentSchema.maximumMarks));
        history.S2MaxAssignmentWeightageMarks = detail.assignmentSchema.maximumMarks;
        history.S2WeightageAssignMarks = ((courseName !== 'Prabhasam' && courseName !== 'Prakasam') ? ((detail.assignmentSchema.weightage / 100) * marks) : ((detail.assignmentSchema.weightage / 100) * marks));
      } else if (detail?.assignmentSchema?.quarter === 'S3') {
        history.S3AssignmentMarks = marks;
        history.S3MaxAssignmentMarks = ((courseName !== 'Prabhasam' && courseName !== 'Prakasam') ? ((detail.assignmentSchema.weightage / 100) * detail.assignmentSchema.maximumMarks) : ((detail.assignmentSchema.weightage / 100) * detail.assignmentSchema.maximumMarks));
        history.S3MaxAssignmentWeightageMarks = detail.assignmentSchema.maximumMarks;
        history.S3WeightageAssignMarks = ((courseName !== 'Prabhasam' && courseName !== 'Prakasam') ? ((detail.assignmentSchema.weightage / 100) * marks) : ((detail.assignmentSchema.weightage / 100) * marks));
      }
    });

    history?.attendenceActivitiesMarks?.forEach((detail) => {
      let marks = 0;
      detail?.attendenceActivitiesSchema?.studentAttendenceActivitiesMarksDetail?.forEach((obj) => {
        marks += obj?.studentAttendenceActivitiesMarksDetail[0].obtainedMarks;
      });

      if (detail?.attendenceActivitiesSchema?.quarter === 'S1') {
        history.S1CurricularMarks = marks;
        history.S1MaxCurricularMarks = detail.attendenceActivitiesSchema.maximumMarks;
        history.S1WeightageCurricularMarks = ((courseName !== 'Prabhasam' && courseName !== 'Prakasam') ? ((detail.attendenceActivitiesSchema.weightage / 100) * marks) : ((detail.attendenceActivitiesSchema.weightage / 100) * marks));
        history.S1MaxCurricularWeightageMarks = ((courseName !== 'Prabhasam' && courseName !== 'Prakasam') ? ((detail.attendenceActivitiesSchema.weightage / 100) * detail.attendenceActivitiesSchema.maximumMarks) : ((detail.attendenceActivitiesSchema.weightage / 100) * detail.attendenceActivitiesSchema.maximumMarks));
      } else if (detail?.attendenceActivitiesSchema?.quarter === 'S2') {
        history.S2CurricularMarks = marks;
        history.S2MaxCurricularMarks = detail.attendenceActivitiesSchema.maximumMarks;
        history.S2MaxCurricularWeightageMarks = ((courseName !== 'Prabhasam' && courseName !== 'Prakasam') ? ((detail.attendenceActivitiesSchema.weightage / 100) * detail.attendenceActivitiesSchema.maximumMarks) : ((detail.attendenceActivitiesSchema.weightage / 100) * detail.attendenceActivitiesSchema.maximumMarks));
        history.S2WeightageCurricularMarks = ((courseName !== 'Prabhasam' && courseName !== 'Prakasam') ? ((detail.attendenceActivitiesSchema.weightage / 100) * marks) : ((detail.attendenceActivitiesSchema.weightage / 100) * marks));
      } else if (detail?.attendenceActivitiesSchema?.quarter === 'S3') {
        history.S3CurricularMarks = marks;
        history.S3MaxCurricularMarks = detail.attendenceActivitiesSchema.maximumMarks;
        history.S3WeightageCurricularMarks = ((courseName !== 'Prabhasam' && courseName !== 'Prakasam') ? ((detail.attendenceActivitiesSchema.weightage / 100) * marks) : ((detail.attendenceActivitiesSchema.weightage / 100) * marks));
        history.S3WeightageCurricularWeighateMarks = ((courseName !== 'Prabhasam' && courseName !== 'Prakasam') ? ((detail.attendenceActivitiesSchema.weightage / 100) * detail.attendenceActivitiesSchema.maximumMarks) : ((detail.attendenceActivitiesSchema.weightage / 100) * detail.attendenceActivitiesSchema.maximumMarks));
      }
    });

    history?.quizPanelQ1Marks?.forEach((detail) => {
      let marks = 0;
      detail?.studentQuizDetailMarks?.forEach((obj) => {
        marks += obj?.obtainedMarks;
      });
      if (detail?.quizSchema?.quarter === 'S1' && detail?.quizSchema?.quiz === 'Quiz 1') {
        history.S1Quiz1Marks = marks;
        history.S1MaxQuiz1WeightageMarks = ((courseName !== 'Prabhasam' && courseName !== 'Prakasam') ? ((detail.quizSchema.weightage / 100) * detail.quizSchema.maximumMarks) : ((detail.quizSchema.weightage / 100) * detail.quizSchema.maximumMarks));
        history.S1MaxQuiz1Marks = detail.quizSchema.maximumMarks;
        history.S1WeightageQuiz1Marks = ((courseName !== 'Prabhasam' && courseName !== 'Prakasam') ? ((detail.quizSchema.weightage / 100) * marks) : ((detail.quizSchema.weightage / 100) * marks));
      } else if (detail?.quizSchema?.quarter === 'S1' && detail?.quizSchema?.quiz === 'Quiz 2') {
        history.S1Quiz2Marks = marks;
        history.S1MaxQuiz2WeightageMarks = ((courseName !== 'Prabhasam' && courseName !== 'Prakasam') ? ((detail.quizSchema.weightage / 100) * detail.quizSchema.maximumMarks) : ((detail.quizSchema.weightage / 100) * detail.quizSchema.maximumMarks));
        history.S1MaxQuiz2Marks = detail.quizSchema.maximumMarks;
        history.S1WeightageQuiz2Marks = ((courseName !== 'Prabhasam' && courseName !== 'Prakasam') ? ((detail.quizSchema.weightage / 100) * marks) : ((detail.quizSchema.weightage / 100) * marks));
      } else if (detail?.quizSchema?.quarter === 'S2' && detail?.quizSchema?.quiz === 'Quiz 1') {
        history.S2Quiz1Marks = marks;
        history.S2MaxQuiz1WeightageMarks = ((courseName !== 'Prabhasam' && courseName !== 'Prakasam') ? ((detail.quizSchema.weightage / 100) * detail.quizSchema.maximumMarks) : ((detail.quizSchema.weightage / 100) * detail.quizSchema.maximumMarks));
        history.S2MaxQuiz1Marks = detail.quizSchema.maximumMarks;
        history.S2WeightageQuiz1Marks = ((courseName !== 'Prabhasam' && courseName !== 'Prakasam') ? ((detail.quizSchema.weightage / 100) * marks) : ((detail.quizSchema.weightage / 100) * marks));
      } else if (detail?.quizSchema?.quarter === 'S2' && detail?.quizSchema?.quiz === 'Quiz 2') {
        history.S2Quiz2Marks = marks;
        history.S2MaxQuiz2WeightageMarks = ((courseName !== 'Prabhasam' && courseName !== 'Prakasam') ? ((detail.quizSchema.weightage / 100) * detail.quizSchema.maximumMarks) : ((detail.quizSchema.weightage / 100) * detail.quizSchema.maximumMarks));
        history.S2MaxQuiz2Marks = detail.quizSchema.maximumMarks;
        history.S2WeightageQuiz2Marks = ((courseName !== 'Prabhasam' && courseName !== 'Prakasam') ? ((detail.quizSchema.weightage / 100) * marks) : ((detail.quizSchema.weightage / 100) * marks));
      }
    });

    history.S1QuizMarks = (history.S1Quiz1Marks || 0) + (history.S1Quiz2Marks || 0);
    history.S1MaxQuizMarks = (history.S1MaxQuiz1Marks || 0) + (history.S1MaxQuiz2Marks || 0);

    history.S2QuizMarks = (history.S2Quiz1Marks || 0) + (history.S2Quiz2Marks || 0);
    history.S2MaxQuizMarks = (history.S2MaxQuiz1Marks || 0) + (history.S2MaxQuiz2Marks || 0);

    history.S1TotalMarks = checkFLC(courseName) ? (history.S1WeightageMarks || 0)
      : ((history.S1WeightageMarks || 0) + (history.S1WeightageHWMarks || 0)
        + (history.S1WeightageAssignMarks || 0) + (history.S1WeightageCurricularMarks || 0)
        + (history.S1WeightageQuiz1Marks || 0) + (history.S1WeightageQuiz2Marks || 0));

    const S1MaxTotalMarks = (history.S1MaxWeightageMarks || 0) + (history.S1MaxHWWeightageMarks || 0)
      + (history.S1MaxAssignmentWeightageMarks || 0) + (history.S1MaxCurricularWeightageMarks || 0)
      + (history.S1MaxQuiz1WeightageMarks || 0) + (history.S1MaxQuiz2WeightageMarks || 0);

    history.S1MaxTotalMarks = S1MaxTotalMarks > 0 ? 100 : 0;

    history.S1Grade = getGradeValidation(history.S1Attended, history.S1TotalMarks, gradesData.gradeData);

    history.S2TotalMarks = checkFLC(courseName) ? (history.S2WeightageMarks || 0)
      : ((history.S2WeightageMarks || 0) + (history.S2WeightageHWMarks || 0)
        + (history.S2WeightageAssignMarks || 0) + (history.S2WeightageCurricularMarks || 0)
        + (history.S2WeightageQuiz1Marks || 0) + (history.S2WeightageQuiz2Marks || 0));

    const S2MaxTotalMarks = (history.S2MaxWeightageMarks || 0) + (history.S2MaxHWWeightageMarks || 0)
      + (history.S2MaxAssignmentWeightageMarks || 0) + (history.S2MaxCurricularWeightageMarks || 0)
      + (history.S2MaxQuiz1WeightageMarks || 0) + (history.S2MaxQuiz2WeightageMarks || 0);

    history.S2MaxTotalMarks = S2MaxTotalMarks > 0 ? 100 : 0;

    history.S2Grade = getGradeValidation(history.S2Attended, history.S2TotalMarks, gradesData.gradeData);

    history.S3TotalMarks = (history.S3WeightageMarks || 0);
    history.S3MaxTotalMarks = 100;

    return history;
  };

  useEffect(() => {
    studentEnrollDetails(enrollDetails);
  }, [enrollDetails]);

  const getQ1Header = () => {
    return validSemesterYear ? t('S1') : t('Q1');
  };

  const getQ2Header = () => {
    return validSemesterYear ? t('S2') : t('Q2');
  };

  const getQ3Header = () => {
    return validSemesterYear
      ? ((courseName === 'Prakasam' || courseName === 'Prabhasam' || courseName === 'Telugu-2' || courseName === 'Telugu-4') ? t('PSTU') : t('Supplementary_camelcase'))
      : t('Q3');
  };

  const getProgressTitle = (val, details, type) => {
    let progress = '';
    if (val === 'HOMEWORK') {
      progress = validSemesterYear ? ProgressWithTitleSemester(t(val), details, classes, courseName, type) : ProgressWithTitle(t(val), details, classes, type);
    } else if (val === 'EXAM') {
      progress = validSemesterYear ? ProgressWithTitleSemester(t(val), details, classes, courseName, type) : ProgressWithTitle(t(val), details, classes, type);
    } else if (val === 'ATTENDANCE') {
      progress = validSemesterYear ? '' : ProgressWithTitle(t(val), details, classes, type);
    } else if (val === 'QUIZ1/3') {
      const title = yearSelected === '2023-2024' ? 'QUIZ1/3' : 'QUIZ1/2';
      progress = validSemesterYear ? ProgressWithTitleSemester(t(title), details, classes, courseName, type) : '';
    } else if (val === 'QUIZ2/4' || val === 'CO-CURR' || val === 'ASSIGNMENTS' || val === 'TOTAL') {
      progress = validSemesterYear ? ProgressWithTitleSemester(t(val), details, classes, courseName, type) : '';
    }
    return progress;
  };

  const getPercentage = (x, y) => {
    const percentage = ((x / y) * 100);
    if (!Number.isNaN(percentage)) {
      return (`${percentage.toFixed(2)}%`);
    }
    return '-';
  };

  const componentRefReportCard = useRef();
  const componentRefCertificate = useRef();
  const componentRefHallTicket = useRef();
  const handlePrintReportCard = useReactToPrint({ content: () => componentRefReportCard.current });
  const [open, setOpen] = useState(false);
  const [openCertificate, setOpenCertificate] = useState(false);
  const [openHallTicket, setopenHallTicket] = useState(false);
  const [hallTicketDetails, setHallTicketDetails] = useState('');
  const [certificateDetails, setCertificateDetails] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const handlePrintHallTicket = () => {
    const url = `https://www.googleapis.com/drive/v3/files/${hallTicketDetails}?alt=media`;
    setErrorMsg('');
    setLoading(true);
    axios.get(url, {
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response?.data) {
          setLoading(false);
          setErrorMsg('');
        }
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(blob);
        const newWindow = window.open(fileURL, '_blank');

        newWindow.print();
      })
      .catch(() => {
        setErrorMsg('Error getting the document');
      });
  };

  const handlePrintCertificate = () => {
    const urlCert = `https://www.googleapis.com/drive/v3/files/${certificateDetails}?alt=media`;
    setErrorMsg('');
    setLoading(true);
    axios.get(urlCert, {
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response?.data) {
          setLoading(false);
          setErrorMsg('');
        }
        const blobCert = new Blob([response.data], { type: 'application/pdf' });

        const fileURLCert = URL.createObjectURL(blobCert);

        const newWindow = window.open(fileURLCert, '_blank');

        newWindow.print();
      })
      .catch(() => {
        setErrorMsg('Error getting the document');
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpenCertificate = () => {
    const id = certificateURL.match(/[-\w]{25,}/);
    setCertificateDetails(id[0]);
    setOpenCertificate(true);
    setErrorMsg('');
    setLoading(false);
  };

  const handleClickOpenHallTicket = () => {
    const id = hallTicketURL.match(/[-\w]{25,}/);
    setHallTicketDetails(id[0]);
    setopenHallTicket(true);
    setErrorMsg('');
    setLoading(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeYear = (year) => {
    setYearSelected(year);
    setIsYearSelected(true);
    setSelectedYear(year);
  };

  const downloadHallTicket = () => {
    return window.open(`https://drive.google.com/u/1/uc?id=${hallTicketDetails}&export=download`, '_blank');
  };

  const downloadCertificate = () => {
    return window.open(`https://drive.google.com/u/1/uc?id=${certificateDetails}&export=download`, '_blank');
  };

  return (
    <form
      noValidate
      autoComplete="off"
    >
      <Grid container spacing="2">
        <Grid container item xs={8} sm={7} md={9}>
          {(isShowCertificate && certificateURL) ? (
            <Grid
              item
              xs={4}
              sm={4}
              md={2.5}
              className={classes.certGrid}
              onClick={() => handleClickOpenCertificate(certificateURL)}
            >
              <CardMedia
                className={classes.profileImg}
                component="img"
                width="15vw"
                image={Cert}
                alt="profile-img"
              />
              <Typography gutterBottom variant="h6" component="div" className={classes.prasunamName}>
                {courseName || ''}
              </Typography>
              {certificateURL && (
                <Typography
                  gutterBottom
                  variant="body"
                  component="div"
                  className={classes.certificateFont}
                  onClick={() => handleClickOpenCertificate(certificateURL)}
                >
                  Certificate
                </Typography>
              )}

            </Grid>
          ) : <div> </div>}
          <Grid item xs={8} sm={8} className={classes.bonusDiv}>
            {!validSemesterYear && gradeBanner(t('BONUS'), enrollDetails.bonus, classes)}
            {gradeBanner(t('GRADES'), isValidAnnualScore(validSemesterYear, enrollDetails) ? enrollDetails.grade : t('N/A'), classes)}
            {gradeBanner(t('GPA'), isValidAnnualScore(validSemesterYear, enrollDetails) ? enrollDetails.gpa : t('N/A'), classes)}
            {!validSemesterYear ? gradeBanner(t('ANNUAL_SCORE'), isValidAnnualScore(validSemesterYear, enrollDetails) ? calculateAnnualScore(courseName, enrollDetails?.Q3MaxMarks, enrollDetails?.annualScore, enrollDetails?.Q3Marks) : t('N/A'), classes)
              : gradeBanner(t('ANNUAL_SCORE'), isValidAnnualScore(validSemesterYear, enrollDetails, courseName) ? (isNullOrUndefined(enrollDetails?.annualScore) && enrollDetails?.annualScore >= 0 ? `${enrollDetails?.annualScore}%` : 'N/A') : t('N/A'), classes)}
            {validSemesterYear && (
              <span>
                {
                  ((isValidAnnualScore(validSemesterYear, enrollDetails, courseName) && result !== null)
                    || (result === t('CANCELLED') || result === t('ATTENDED') || result === t('REPEAT')) || result === t('ABSENT').toUpperCase()) ? (
                      gradeBanner(t('RESULT'), upperCaseFirstLetter(result), classes)
                    ) : (enrollDetails?.S1Attended === false && enrollDetails?.S2Attended === false && enrollDetails?.S3Attended === false) ? (
                      gradeBanner(t('RESULT'), 'Fail', classes)
                    ) : (enrollDetails?.S3Attended === true) ? (
                      gradeBanner(t('RESULT'), upperCaseFirstLetter(result), classes)
                    ) : (
                      gradeBanner(t('RESULT'), t('N/A'), classes)
                    )
                }
              </span>
            )}
            {!validSemesterYear && (
              <span>
                {
                  (result !== null) ? (
                    gradeBanner(t('RESULT'), upperCaseFirstLetter(result), classes)
                  ) : (
                    gradeBanner(t('RESULT'), t('N/A'), classes)
                  )
                }
              </span>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          className={classes.container}
          xs={4}
          sm={5}
          md={3}
          display="flex"
          flexDirection="column"
          alignItems="flex-end"
        >
          <Grid item xs={12} className={classes.alignGridYear}>
            <Dropdown
              id="academicYear"
              variant="standard"
              name="academicYear"
              placeholder={t('YEAR')}
              value={yearSelected}
              options={assignedYears}
              customClass={classes.academicYearDropDown}
              customSelectClass={classes.academicYearDropDown}
              changeCss
              className={classes.academicYearDropDown}
              customFormControlCss={{ width: '100%' }}
              labelId="year"
              handleChange={(e) => {
                handleChangeYear(e.target.value);
              }}
            />
            {getErrorText('academicYear')}
          </Grid>
          <Grid item xs={12} />
          <Grid item xs={12} />
          <Grid
            item
            xs={12}
            className={`${classes.reportCardLabel} ${classes.hallTic}`}
          >
            {(hallTicketNumber !== null && hallTicketURL !== null) ? (
              <span onClick={() => handleClickOpenHallTicket(hallTicketURL)} className={classes.cursorPointer}>
                {t('HALL_TICKET_NUMBER')}
                {' '}
                <span className={classes.hallTicketNum}>{hallTicketNumber}</span>
              </span>

            ) : (
              <span className={classes.cursorDefault}>
                {t('HALL_TICKET_NUMBER')}
                {' '}
                {t('N/A')}
              </span>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Divider className={classes.dividerLine} />
      <Grid container className={classes.Detailscontainer} direction="row" spacing="2">
        <Grid item lg={2} xs={3} md={2} className={classes.reportCardLabelHeader}>
          {t('DETAILS')}
        </Grid>
        <Grid item lg={3.333} xs={3} md={3.333} className={classes.reportCardLabelHeader}>
          {getQ1Header(t)}
        </Grid>
        <Grid item lg={3.333} xs={3} md={3.333} className={classes.reportCardLabelHeader}>
          {getQ2Header(t)}
        </Grid>
        <Grid item lg={3.333} xs={3} md={3.333} className={classes.reportCardLabelHeader}>
          {getQ3Header()}
        </Grid>
        <Grid item xs={12} className={classes.reportCardLabel}>
          {
            getProgressTitle('EXAM', enrollDetails, 'marks')
          }
          {
            getProgressTitle('HOMEWORK', enrollDetails, 'homework')
          }
          {
            getProgressTitle('QUIZ1/3', enrollDetails, 'quiz1')
          }
          {yearSelected === '2023-2024' && (
            getProgressTitle('QUIZ2/4', enrollDetails, 'quiz2')
          )}
          {
            getProgressTitle('ASSIGNMENTS', enrollDetails, 'assignments')
          }
          {
            getProgressTitle('CO-CURR', enrollDetails, 'curricular')
          }
          {
            getProgressTitle('ATTENDANCE', enrollDetails, 'attendance')
          }
          <Divider className={classes.dividerLine} />
          {
            getProgressTitle('TOTAL', enrollDetails, 'total')
          }
        </Grid>

        {validSemesterYear && (
          <Grid container xs={12} className={classes.gradeLabel}>
            <Grid item xs={2}>Grade</Grid>
            <Grid item xs={3.5}>{studentEnrollDetails(enrollDetails).S1Attended === false ? 'D' : studentEnrollDetails(enrollDetails).S1Grade}</Grid>
            <Grid item xs={2.38} className={classes.borderRight}>{studentEnrollDetails(enrollDetails).S2Attended === false ? 'D' : studentEnrollDetails(enrollDetails).S2Grade}</Grid>
          </Grid>
        )}

      </Grid>

      <Grid item sx={{ textAlign: 'center', marginTop: '1vw' }}>
        <ButtonAtom
          btntype={Buttons.PRIMARY}
          name={t('REPORT_CARD')}
          onClick={handleClickOpen}
        />
      </Grid>
      <DialogAtom
        isOpen={open}
        dialogHeading={t('REPORT_CARD')}
        secHandle={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        maxWidth="lg"
        customClass={classes.reportDialog}
        content={(
          <DialogContent
            p={0}
            ref={componentRefReportCard}
          >
            <Grid container>
              <Grid item xs={8} sm={6}>
                <Box>
                  <Typography variant="h5" gutterBottom component="div" className={classes.studentNameReportCard}>
                    {studentDetails?.studentInfo?.firstName}
                    {' '}
                    {studentDetails?.studentInfo?.lastName}
                  </Typography>
                  <Box className={classes.courseText}>
                    <Typography mr={1} variant="body2" gutterBottom component="div">
                      Course (Section)  :
                    </Typography>
                    <Typography fontWeight="bold" variant="subtitle2" gutterBottom component="div">
                      {courseName || ''}
                      {' '}
                      (
                      {section || ''}
                      )
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={4} sm={6}>
                <Box className={classes.printText}>
                  <Box className={classes.printSec} onClick={handlePrintReportCard}>
                    <Typography mr={1} variant="content" gutterBottom component="div">
                      {t('PRINT')}
                    </Typography>
                    <PrintIcon />
                  </Box>
                  <Typography variant="content" gutterBottom component="div" className={classes.hallTicket}>
                    {(hallTicketNumber !== null) ? (
                      <span>
                        {t('HALL_TICKET_NUMBER')}
                        {' '}
                        <span className={classes.hallTicketNum}>{hallTicketNumber}</span>
                      </span>

                    ) : (
                      <span>
                        {t('HALL_TICKET_REQUIRED')}
                      </span>
                    )}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <hr />
            <Grid container>
              <Grid item xs={12} md={3}>
                {!validSemesterYear && gradeBanner(t('BONUS'), enrollDetails.bonus, classes)}
                {gradeBanner(t('GRADES'), isValidAnnualScore(validSemesterYear, enrollDetails) ? enrollDetails.grade : t('N/A'), classes)}
                {gradeBanner(t('GPA'), isValidAnnualScore(validSemesterYear, enrollDetails) ? enrollDetails.gpa : t('N/A'), classes)}
                {!validSemesterYear ? gradeBanner(t('ANNUAL_SCORE'), isValidAnnualScore(validSemesterYear, enrollDetails) ? calculateAnnualScore(courseName, enrollDetails?.Q3MaxMarks, enrollDetails?.annualScore, enrollDetails?.Q3Marks) : t('N/A'), classes)
                  : gradeBanner(t('ANNUAL_SCORE'), isValidAnnualScore(validSemesterYear, enrollDetails) ? (isNullOrUndefined(enrollDetails?.annualScore) && enrollDetails?.annualScore >= 0 ? `${enrollDetails?.annualScore}%` : 'N/A') : t('N/A'), classes)}
                {validSemesterYear && (
                  <span>
                    {
                      (isValidAnnualScore(validSemesterYear, enrollDetails, courseName) && result !== null) ? (
                        gradeBanner(t('RESULT'), upperCaseFirstLetter(result), classes)
                      ) : (
                        gradeBanner(t('RESULT'), t('N/A'), classes)
                      )
                    }
                  </span>
                )}
                {!validSemesterYear && (
                  <span>
                    {
                      (result !== null) ? (
                        gradeBanner(t('RESULT'), upperCaseFirstLetter(result), classes)
                      ) : (
                        gradeBanner(t('RESULT'), t('N/A'), classes)
                      )
                    }
                  </span>
                )}
              </Grid>
              <Grid container item xs={12} md={9} className={classes.container} direction="row" spacing="2">
                <Grid item lg={2} xs={3} md={2} className={classes.reportCardLabelHeader}>
                  {t('DETAILS')}
                </Grid>
                <Grid item lg={3.333} xs={3} md={3.333} className={classes.reportCardLabelHeader}>
                  {getQ1Header(t)}
                </Grid>
                <Grid item lg={3.333} xs={3} md={3.333} className={classes.reportCardLabelHeader}>
                  {getQ2Header(t)}
                </Grid>
                <Grid item lg={3.333} xs={3} md={3.333} className={classes.reportCardLabelHeader}>
                  {getQ3Header()}
                </Grid>
                <Grid item xs={12} className={classes.reportCardLabel}>
                  {
                    getProgressTitle('EXAM', enrollDetails, 'marks')
                  }
                  {
                    getProgressTitle('HOMEWORK', enrollDetails, 'homework')
                  }
                  {
                    getProgressTitle('QUIZ1/3', enrollDetails, 'quiz1')
                  }
                  {yearSelected === '2023-2024' && (
                    getProgressTitle('QUIZ2/4', enrollDetails, 'quiz2')
                  )}
                  {
                    getProgressTitle('ASSIGNMENTS', enrollDetails, 'assignments')
                  }
                  {
                    getProgressTitle('CO-CURR', enrollDetails, 'curricular')
                  }
                  {
                    getProgressTitle('ATTENDANCE', enrollDetails, 'attendance')
                  }
                  <Divider className={classes.dividerLine} />
                  {
                    getProgressTitle('TOTAL', enrollDetails, 'total')
                  }
                </Grid>

                {validSemesterYear && (
                  <Grid container xs={12} className={classes.gradeLabel}>
                    <Grid item xs={2}>Grade</Grid>
                    <Grid item xs={3.5}>{studentEnrollDetails(enrollDetails).S1Attended === false ? 'D' : studentEnrollDetails(enrollDetails).S1Grade}</Grid>
                    <Grid item xs={3.2}>{studentEnrollDetails(enrollDetails).S2Attended === false ? 'D' : studentEnrollDetails(enrollDetails).S2Grade}</Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid className={classes.tableView}>
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead className={classes.tableheadColor}>
                    <TableRow>
                      <TableCell className={classes.tableCellSize} colspan={!validSemesterYear ? (getQuarterDetails('Q1')?.length + 1) : (getQuarterDetails('S1')?.length + 1)}>
                        {validSemesterYear ? t('SEMESTER 1') : t('QUARTER1')}
                        <small> - Score </small>
                        {!validSemesterYear ? (
                          <span>
                            {(studentEnrollDetails(enrollDetails).Q1Marks >= 0) ? (
                              <small>
                                {(studentEnrollDetails(enrollDetails).Q1Attended ? studentEnrollDetails(enrollDetails).Q1Marks
                                  : <small className={classes.red}> 0(A) </small>
                                )}
                              </small>
                            ) : (
                              <small> N/A </small>
                            )}
                          </span>
                        ) : (
                          <span>
                            {(studentEnrollDetails(enrollDetails).S1Marks >= 0) ? (
                              <small>
                                {(studentEnrollDetails(enrollDetails).S1Attended ? studentEnrollDetails(enrollDetails).S1Marks
                                  : <small className={classes.red}> 0(A) </small>
                                )}
                              </small>
                            ) : (
                              <small> N/A </small>
                            )}
                          </span>
                        )}

                      </TableCell>
                      <TableCell align="center">{t('TOTAL')}</TableCell>
                      <TableCell
                        align="center"
                        style={{
                          borderRightwidth: '0px',
                        }}
                        className={classes.totalRow}
                      >
                        {t('TOTAL%')}

                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableHead className={classes.tableheadBg}>
                    {!validSemesterYear ? (
                      <TableRow>
                        <TableCell>{t('WEEK')}</TableCell>
                        {getQuarterDetails('Q1') ? getQuarterDetails('Q1')?.map((stu) => (
                          <TableCell align="center">{stu?.homeworkPanelMarks?.week}</TableCell>
                        )) : noMarks()}
                        {hasMarks('Q1')}
                        <TableCell>&nbsp;</TableCell>
                      </TableRow>
                    ) : (
                      <TableRow>
                        <TableCell>{t('WEEK')}</TableCell>
                        {getQuarterDetails('S1') ? getQuarterDetails('S1')?.map((stu) => (
                          <TableCell align="center">{stu?.homeworkPanelMarks?.week}</TableCell>
                        )) : noMarks()}
                        {hasMarks('S1')}
                        <TableCell className={classes.tableCellUI}>&nbsp;</TableCell>
                        <TableCell />
                      </TableRow>
                    )}
                  </TableHead>
                  <TableBody>
                    {validSemesterYear ? (
                      <TableRow>
                        <TableCell>{t('HW_MARKS')}</TableCell>
                        {getQuarterDetails('S1') ? getQuarterDetails('S1')?.map((stu) => (
                          <TableCell align="center">{stu?.obtainedMarks}</TableCell>
                        )) : noMarks()}
                        <TableCell className={classes.tableCellUI} align="center">{studentEnrollDetails(enrollDetails).S1HWMarks || '-'}</TableCell>
                        {getQuarterDetails('S1')?.length > 0
                          && (
                            <TableCell align="center">
                              {`${!getQuarterDetails('S1').some((course) => course.obtainedMarks === null) ? getPercentage(studentEnrollDetails(enrollDetails).S1HWMarks, studentEnrollDetails(enrollDetails).S1MaxHWMarks) : 'N/A'}`}
                            </TableCell>
                          )}
                        <TableCell />
                      </TableRow>
                    ) : (
                      <TableRow>
                        <TableCell>{t('HW_MARKS')}</TableCell>
                        {getQuarterDetails('Q1') ? getQuarterDetails('Q1')?.map((stu) => (
                          <TableCell align="center">{stu?.obtainedMarks}</TableCell>
                        )) : noMarks()}
                        <TableCell align="center">{studentEnrollDetails(enrollDetails).Q1HWMarks || '-'}</TableCell>
                        {getQuarterDetails('Q1')?.length > 0
                          && (
                            <TableCell align="center">
                              {`${!getQuarterDetails('Q1').some((course) => course.obtainedMarks === null) ? getPercentage(studentEnrollDetails(enrollDetails).Q1HWMarks, studentEnrollDetails(enrollDetails).Q1MaxHWMarks) : 'N/A'}`}
                            </TableCell>
                          )}
                      </TableRow>
                    )}
                  </TableBody>
                  <TableBody>
                    {validSemesterYear ? (
                      <TableRow>
                        <TableCell>{t('Attendance')}</TableCell>
                        {getQuarterDetails('S1')?.map((stu) => (
                          <TableCell align="center">
                            <div class={stu?.isPresent ? classes.setGreen : classes.setRed} />
                          </TableCell>
                        ))}
                        <TableCell align="center">
                          {(studentEnrollDetails(enrollDetails).S1TotalPresent && `${studentEnrollDetails(enrollDetails).S1TotalPresent}/${studentEnrollDetails(enrollDetails).S1TotalWeeks}`)
                            || '-'}
                        </TableCell>

                        <TableCell className={classes.tableCellUIDown} align="center">
                          {getPercentage(studentEnrollDetails(enrollDetails).S1TotalPresent, studentEnrollDetails(enrollDetails).S1TotalWeeks) || '-'}
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow>
                        <TableCell>{t('Attendance')}</TableCell>
                        {getQuarterDetails('Q1')?.map((stu) => (
                          <TableCell align="center">
                            <div class={stu?.isPresent ? classes.setGreen : classes.setRed} />
                          </TableCell>
                        ))}
                        <TableCell align="center">
                          {(studentEnrollDetails(enrollDetails).Q1TotalPresent && `${studentEnrollDetails(enrollDetails).Q1TotalPresent}/${studentEnrollDetails(enrollDetails).Q1TotalWeeks}`)
                            || '-'}
                        </TableCell>

                        <TableCell align="center">
                          {getPercentage(studentEnrollDetails(enrollDetails).Q1TotalPresent, studentEnrollDetails(enrollDetails).Q1TotalWeeks) || '-'}
                        </TableCell>
                      </TableRow>
                    )}

                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid className={classes.tableView}>
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead className={classes.tableheadColor}>
                    {validSemesterYear ? (
                      <TableRow>
                        <TableCell className={classes.tableCellSize} colspan={getQuarterDetails('S2')?.length + 1}>
                          {t('SEMESTER 2')}
                          <small> - Score </small>
                          {(studentEnrollDetails(enrollDetails).S2Marks >= 0) ? (
                            <small>
                              {(studentEnrollDetails(enrollDetails).S2Attended ? studentEnrollDetails(enrollDetails).S2Marks
                                : <small className={classes.red}> 0(A) </small>
                              )}
                            </small>
                          ) : (
                            <small> N/A </small>
                          )}

                        </TableCell>
                        <TableCell align="center">{t('TOTAL')}</TableCell>
                        <TableCell align="center">{t('TOTAL%')}</TableCell>
                      </TableRow>
                    ) : (
                      <TableRow>
                        <TableCell className={classes.tableCellSize} colspan={(getQuarterDetails('Q2')?.length + 1)}>
                          {t('QUARTER2')}
                          <small> - Score </small>
                          {(studentEnrollDetails(enrollDetails).Q2Marks >= 0) ? (
                            <small>
                              {(studentEnrollDetails(enrollDetails).Q2Attended ? studentEnrollDetails(enrollDetails).Q2Marks
                                : <small className={classes.red}> 0(A) </small>
                              )}
                            </small>
                          ) : (
                            <small> N/A </small>
                          )}

                        </TableCell>
                        <TableCell align="center">{t('TOTAL')}</TableCell>
                        <TableCell align="center">{t('TOTAL%')}</TableCell>
                      </TableRow>
                    )}
                  </TableHead>
                  <TableHead className={classes.tableheadBg}>
                    {validSemesterYear ? (
                      <TableRow>
                        <TableCell>{t('WEEK')}</TableCell>
                        {getQuarterDetails('S2') ? getQuarterDetails('S2')?.map((stu) => (
                          <TableCell align="center">{stu?.homeworkPanelMarks?.week}</TableCell>
                        )) : noMarks()}
                        {hasMarks('S2')}
                        <TableCell className={classes.tableCellUI}>&nbsp;</TableCell>
                        <TableCell />
                      </TableRow>
                    ) : (
                      <TableRow>
                        <TableCell>{t('WEEK')}</TableCell>
                        {getQuarterDetails('Q2') ? getQuarterDetails('Q2')?.map((stu) => (
                          <TableCell align="center">{stu?.homeworkPanelMarks?.week}</TableCell>
                        )) : noMarks()}
                        {hasMarks('Q2')}
                        <TableCell>&nbsp;</TableCell>
                      </TableRow>
                    )}
                  </TableHead>
                  <TableBody>
                    {validSemesterYear ? (
                      <TableRow>
                        <TableCell>{t('HW_MARKS')}</TableCell>
                        {getQuarterDetails('S2') ? getQuarterDetails('S2')?.map((stu) => (
                          <TableCell align="center">{stu?.obtainedMarks}</TableCell>
                        )) : noMarks()}
                        <TableCell className={classes.tableCellUI} align="center">{studentEnrollDetails(enrollDetails).S2HWMarks || '-'}</TableCell>
                        {getQuarterDetails('S2')?.length > 0
                          && (
                            <TableCell align="center">
                              {`${!getQuarterDetails('S2').some((course) => course.obtainedMarks === null) ? getPercentage(studentEnrollDetails(enrollDetails).S2HWMarks, studentEnrollDetails(enrollDetails).S2MaxHWMarks) : 'N/A'}`}
                            </TableCell>
                          )}
                        <TableCell />
                      </TableRow>
                    ) : (
                      <TableRow>
                        <TableCell>{t('HW_MARKS')}</TableCell>
                        {getQuarterDetails('Q2') ? getQuarterDetails('Q2')?.map((stu) => (
                          <TableCell align="center">{stu?.obtainedMarks}</TableCell>
                        )) : noMarks()}
                        <TableCell align="center">{studentEnrollDetails(enrollDetails).Q2HWMarks || '-'}</TableCell>
                        {getQuarterDetails('Q2')?.length > 0
                          && (
                            <TableCell align="center">
                              {`${!getQuarterDetails('Q2').some((course) => course.obtainedMarks === null) ? getPercentage(studentEnrollDetails(enrollDetails).Q2HWMarks, studentEnrollDetails(enrollDetails).Q2MaxHWMarks) : 'N/A'}`}
                            </TableCell>
                          )}
                      </TableRow>
                    )}
                  </TableBody>
                  <TableBody>
                    {validSemesterYear ? (
                      <TableRow>
                        <TableCell>{t('Attendance')}</TableCell>
                        {getQuarterDetails('S2') ? getQuarterDetails('S2')?.map((stu) => (
                          <TableCell align="center">
                            <div class={stu?.isPresent ? classes.setGreen : classes.setRed} />
                          </TableCell>
                        )) : noMarks()}
                        {getQuarterDetails('S2')?.length > 0 ? <TableCell align="center">{`${studentEnrollDetails(enrollDetails).S2TotalPresent}/${studentEnrollDetails(enrollDetails).S2TotalWeeks}` || 'N/A'}</TableCell>
                          : <TableCell className={classes.tableCellUI} align="center"> - </TableCell>}
                        {getQuarterDetails('S2')?.length > 0
                          && (
                            <TableCell align="center">
                              {`${getPercentage(studentEnrollDetails(enrollDetails).S2TotalPresent, studentEnrollDetails(enrollDetails).S2TotalWeeks)}` || 'N/A'}
                            </TableCell>
                          )}
                      </TableRow>
                    ) : (
                      <TableRow>
                        <TableCell>{t('Attendance')}</TableCell>
                        {getQuarterDetails('Q2') ? getQuarterDetails('Q2')?.map((stu) => (
                          <TableCell align="center">
                            <div class={stu?.isPresent ? classes.setGreen : classes.setRed} />
                          </TableCell>
                        )) : noMarks()}
                        {getQuarterDetails('Q2')?.length > 0 ? <TableCell align="center">{`${studentEnrollDetails(enrollDetails).Q2TotalPresent}/${studentEnrollDetails(enrollDetails).Q2TotalWeeks}` || 'N/A'}</TableCell>
                          : <TableCell align="center"> - </TableCell>}
                        {getQuarterDetails('Q2')?.length > 0
                          && (
                            <TableCell align="center">
                              {`${getPercentage(studentEnrollDetails(enrollDetails).Q2TotalPresent, studentEnrollDetails(enrollDetails).Q2TotalWeeks)}` || 'N/A'}
                            </TableCell>
                          )}
                      </TableRow>
                    )}

                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            {
              !validSemesterYear && (
                <Grid className={classes.tableView}>
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead className={classes.tableheadColor}>
                        <TableRow>

                          <TableCell className={classes.tableCellSize} colspan={getQuarterDetails('Q3')?.length + 1}>
                            {t('QUARTER3')}
                            <small> - Score </small>
                            {(studentEnrollDetails(enrollDetails).Q3Marks >= 0) ? (
                              <small>
                                {(studentEnrollDetails(enrollDetails).Q3Attended ? studentEnrollDetails(enrollDetails).Q3Marks
                                  : <small className={classes.red}> 0(A) </small>
                                )}
                              </small>
                            ) : (
                              <small> N/A </small>
                            )}
                          </TableCell>
                          <TableCell align="center">{t('TOTAL')}</TableCell>
                          <TableCell align="center">{t('TOTAL%')}</TableCell>

                        </TableRow>
                      </TableHead>
                      <TableHead className={classes.tableheadBg}>
                        <TableRow>
                          <TableCell>{t('WEEK')}</TableCell>
                          {getQuarterDetails('Q3') ? getQuarterDetails('Q3')?.map((stu) => (
                            <TableCell align="center">{stu?.homeworkPanelMarks?.week}</TableCell>
                          )) : noMarks()}
                          {hasMarks('Q3')}
                          <TableCell>&nbsp;</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>{t('HW_MARKS')}</TableCell>
                          {getQuarterDetails('Q3') ? getQuarterDetails('Q3')?.map((stu) => (
                            <TableCell align="center">{stu?.obtainedMarks}</TableCell>
                          )) : noMarks()}
                          <TableCell align="center">{studentEnrollDetails(enrollDetails).Q3HWMarks || '-'}</TableCell>
                          {getQuarterDetails('Q3')?.length > 0
                            && (
                              <TableCell align="center">
                                {`${!getQuarterDetails('Q3').some((course) => course.obtainedMarks === null) ? getPercentage(studentEnrollDetails(enrollDetails).Q3HWMarks, studentEnrollDetails(enrollDetails).Q3MaxHWMarks) : 'N/A'}`}
                              </TableCell>
                            )}
                        </TableRow>
                      </TableBody>
                      <TableBody>
                        <TableRow>
                          <TableCell>{t('Attendance')}</TableCell>
                          {getQuarterDetails('Q3') ? getQuarterDetails('Q3')?.map((stu) => (
                            <TableCell align="center">
                              <div class={stu?.isPresent ? classes.setGreen : classes.setRed} />
                            </TableCell>
                          )) : noMarks()}
                          {getQuarterDetails('Q3')?.length > 0 ? <TableCell align="center">{`${studentEnrollDetails(enrollDetails).Q3TotalPresent}/${studentEnrollDetails(enrollDetails).Q3TotalWeeks}` || 'N/A'}</TableCell>
                            : <TableCell align="center"> - </TableCell>}
                          {getQuarterDetails('Q3')?.length > 0
                            && (
                              <TableCell align="center">
                                {`${getPercentage(studentEnrollDetails(enrollDetails).Q3TotalPresent, studentEnrollDetails(enrollDetails).Q3TotalWeeks)}` || 'N/A'}
                              </TableCell>
                            )}
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              )
            }
          </DialogContent>
        )}
      />

      <DialogAtom
        isOpen={openCertificate}
        maxWidth="lg"
        customClass={classes.imageModal}
        closeOnBlur={() => setOpenCertificate(false)}
        content={(
          <DialogContent
            p={0}
          >
            <Grid container className={classes.imageSec}>
              <Grid item xs={12} className={classes.rightIcons} display="flex" alignItems="center" justifyContent="flex-end">
                <ThemeProvider theme={toolTipTheme}>
                  {
                    (loading && !errorMsg)
                    && (
                      <span style={{ color: 'green' }}>loading...</span>
                    )
                  }
                  {
                    (errorMsg.length > 0)
                    && (
                      <span style={{ color: 'red' }}>{errorMsg}</span>
                    )
                  }

                  <Tooltip title={t('PRINT')}>
                    <IconButton onClick={() => handlePrintCertificate()}>
                      <PrintIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t('DOWNLOAD')}>
                    <IconButton className={classes.downloadIcon} onClick={() => downloadCertificate()}>

                      <FileDownloadOutlinedIcon />

                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t('CLOSE')}>
                    <IconButton
                      className={classes.emailIcon}
                      onClick={() => setOpenCertificate(false)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </ThemeProvider>
              </Grid>
              <CardMedia
                ref={componentRefCertificate}
                component="iframe"
                sx={{ height: '50rem' }}
                image={`https://drive.google.com/file/d/${certificateDetails}/preview`}
                alt="certificate"
              />
            </Grid>
            <a href={getImageFromURL(certificateURL)}>Image</a>
          </DialogContent>
        )}
      />

      <DialogAtom
        isOpen={openHallTicket}
        maxWidth="lg"
        customClass={classes.imageModal}
        closeOnBlur={() => setopenHallTicket(false)}
        content={(
          <DialogContent
            p={0}
          >
            <Grid container className={classes.imageSec}>
              <Grid item xs={12} className={classes.rightIcons} display="flex" alignItems="center" justifyContent="flex-end">
                <ThemeProvider theme={toolTipTheme}>
                  {
                    (loading && !errorMsg)
                    && (
                      <span style={{ color: 'green' }}>loading...</span>
                    )
                  }
                  {
                    (errorMsg.length > 0)
                    && (
                      <span style={{ color: 'red' }}>{errorMsg}</span>
                    )
                  }
                  <Tooltip title={t('PRINT')}>
                    <IconButton onClick={() => handlePrintHallTicket()}>
                      <PrintIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t('DOWNLOAD')}>
                    <IconButton className={classes.downloadIcon} onClick={() => downloadHallTicket()}>

                      <FileDownloadOutlinedIcon />

                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t('CLOSE')}>
                    <IconButton
                      className={classes.emailIcon}
                      onClick={() => setopenHallTicket(false)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </ThemeProvider>
              </Grid>

              <CardMedia
                ref={componentRefHallTicket}
                component="iframe"
                sx={{ height: '50rem' }}
                image={`https://drive.google.com/file/d/${hallTicketDetails}/preview`}
                alt="hallTicket"
              />
            </Grid>
          </DialogContent>
        )}
      />
    </form>
  );
}
