/* eslint-disable quotes */
/* eslint-disable prefer-destructuring */
import moment from 'moment';
import { colors } from '../../../../theme';
import chartTypes from './types';

const filteredData = (keyName, selectedFilter) => {
  const data = selectedFilter.filter((i) => i?.filterKey === keyName);
  return data;
};

const currentYears = [`${moment().format('YYYY')}-${moment().add(1, 'years').format('YYYY')}`];
const last30Days = [moment().subtract(30, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
const last7Days = [moment().subtract(7, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
const recordLimit = 50000;
function getWeekFirstAndLastDate(academicYear, regWeek) {
  const month = moment().month(regWeek.split(" - ")[0]).format("MM");
  const year = month < 4 ? academicYear.split("-")[1] : academicYear.split("-")[0];
  const monthDay = moment([year, month - 1]);
  const monthfirstWeekMon = monthDay.clone().weekday(1);
  const daysToAdd = (regWeek.split(" - ")[1] - 1) * 7;
  let firstDayOfTheWeek = monthfirstWeekMon.add(daysToAdd, "days");
  let lastDayOfTheWeek = firstDayOfTheWeek.clone().add(6, "days");

  if (firstDayOfTheWeek.format("MM") !== month) {
    firstDayOfTheWeek = firstDayOfTheWeek.endOf("month");
    firstDayOfTheWeek.add(1, "d").set("hour", 0).set("minute", 0);
  } else {
    for (let i = 6; i > 0; i--) {
      const last = firstDayOfTheWeek.clone().add(i, "days");

      if (firstDayOfTheWeek.month() !== last.month()) {
        // eslint-disable-next-line no-continue
        continue;
      }
      lastDayOfTheWeek = last;
      break;
    }
  }

  return [firstDayOfTheWeek.format('YYYY-MM-DD'), lastDayOfTheWeek.format('YYYY-MM-DD')];
}

function addMarksQuery(marks) {
  const labels = marks.map((c) => c?.label);
  if (!labels || (labels.length === 0)) {
    return {};
  }
  const marksObj = {};
  labels.forEach((label) => {
    const value = label.split(' ');
    let isS3;
    if (value[1] === 'Sem') {
      value[1] = `S${value[2]}`;
      value.splice(2, 1);
    } else if (value[1] === 'Supplementary' || value[1] === 'PSTU') {
      isS3 = value[1];
      value[1] = 'S3';
    }
    const quarter = value[1];
    let op = value[2];

    switch (op) {
      case '<':
        op = 'lt';
        break;
      case '=':
        op = 'equals';
        break;
      case '>':
        op = 'gt';
        break;
      default:
        return;
    }
    if (marksObj[quarter]) {
      marksObj[quarter].and[1].or.push({ member: 'LocationPanelScore.totalMarks', operator: op, values: ['50'] });
    } else {
      marksObj[quarter] = {
        and: [
          { member: 'LocationPanelScore.quarter', operator: 'equals', values: [quarter] },
          { member: 'LocationPanelScore.deletedAt', operator: 'equals', values: [null] },
          {
            or: [
              { member: 'LocationPanelScore.totalMarks', operator: op, values: ['50'] },
            ],
          }],
      };
    }

    if (isS3 === 'Supplementary' || isS3 === 'PSTU') {
      let courseValue = null;

      if (isS3 === 'Supplementary') {
        courseValue = ['Pravesam', 'Prasunam', 'Pramodam'];
      } else if (isS3 === 'PSTU') {
        courseValue = ['Prabhasam', 'Prakasam', 'Telugu-2', 'Telugu-4'];
      }

      if (courseValue !== null) {
        marksObj[quarter].and.push({ member: 'Course.name', operator: 'in', values: courseValue });
      }
    }
  });

  const obj = Object.values(marksObj);
  return obj;
}

function addIsAbsentQuery(quarter, academicYear) {
  const Quarter = quarter === 'Supplementary' || quarter === 'PSTU' ? 'S3' : quarter;
  const obj = {
    and: [
      { member: 'LocationPanelScore.isPresent', operator: 'equals', values: ['false'] },
      { member: 'LocationPanelScore.quarter', operator: 'equals', values: [Quarter] },
      { member: 'LocationPanelScore.academicYear', operator: 'equals', values: [academicYear] },
    ],
  };

  const Supplementary = ['Pravesam', 'Prasunam', 'Pramodam'];
  const PSTU = ['Prabhasam', 'Prakasam', 'Telugu-2', 'Telugu-4'];

  if (quarter === 'Supplementary' || quarter === 'PSTU') {
    const courseValues = quarter === 'Supplementary' ? Supplementary : PSTU;
    obj.and.push({ member: 'Course.name', operator: 'in', values: courseValues });
  }

  return obj;
}

function addIsAttendedQuery(quarter, academicYear) {
  const Quarter = quarter === 'Supplementary' || quarter === 'PSTU' ? 'S3' : quarter;
  const obj = {
    and: [
      { member: 'LocationPanelScore.isPresent', operator: 'equals', values: ['true'] },
      { member: 'LocationPanelScore.quarter', operator: 'equals', values: [Quarter] },
      { member: 'LocationPanelScore.academicYear', operator: 'equals', values: [academicYear] },
    ],
  };

  const Supplementary = ['Pravesam', 'Prasunam', 'Pramodam'];
  const PSTU = ['Prabhasam', 'Prakasam', 'Telugu-2', 'Telugu-4'];

  if (quarter === 'Supplementary' || quarter === 'PSTU') {
    const courseValues = quarter === 'Supplementary' ? Supplementary : PSTU;
    obj.and.push({ member: 'Course.name', operator: 'in', values: courseValues });
  }

  return obj;
}

function modifyQuery(query, selectedFilter, selectedOption, acrossYear) {
  const selectedoption = selectedOption === '' ? 'year' : selectedOption;
  const status = filteredData('status', selectedFilter);
  const completionStatus = filteredData('completion_status', selectedFilter);
  const state = filteredData('state', selectedFilter);
  const courseName = filteredData('course_name', selectedFilter);
  const regWeek = filteredData('reg_week', selectedFilter);
  const createdDate = filteredData('created_date', selectedFilter);
  const centerName = filteredData('center_name', selectedFilter);
  const academicYear = filteredData('academic_year', selectedFilter);
  const returning = filteredData('returning', selectedFilter);

  const section = filteredData('section', selectedFilter);
  const hasPic = filteredData('has_pic', selectedFilter);
  const examCenter = filteredData('exam_center', selectedFilter);
  const marks = filteredData('marks', selectedFilter);
  const regionName = filteredData('region_name', selectedFilter);

  const country = filteredData('country', selectedFilter);
  const geoRegion = filteredData('geo_region', selectedFilter);
  const isAbsent = filteredData('isAbsent', selectedFilter);
  const isAttended = filteredData('isAttended', selectedFilter);

  const countryFilter = {
    member: 'Region.country',
    operator: 'equals',
    values: country.length ? country.map((c) => c?.label) : ['United States', 'Canada', 'USA'],
  };
  const geoRegionFilter = {
    member: 'Region.geoRegion',
    operator: 'equals',
    values: geoRegion.map((c) => c?.label),
  };
  if (isAbsent.length) {
    const valuee = isAbsent[0].label;
    let quarter;
    switch (valuee) {
      case "Quarter 1":
        quarter = "Q1";
        break;
      case "Quarter 2":
        quarter = "Q2";
        break;
      case "Quarter 3":
        quarter = "Q3";
        break;
      case "Semester 1":
        quarter = "S1";
        break;
      case "Semester 2":
        quarter = "S2";
        break;
      case "Semester 3":
        quarter = "S3";
        break;
      case "PSTU":
        quarter = "PSTU";
        break;
      case "Supplementary":
        quarter = "Supplementary";
        break;
      default:
        quarter = null;
    }
    const year = academicYear.length > 0 ? academicYear[0].label : '2023-2024';
    const obj = addIsAbsentQuery(quarter, year);

    query.filters.push(obj);
  }

  if (isAttended.length) {
    const valuee = isAttended[0].label;
    let quarter;
    switch (valuee) {
      case "Quarter 1":
        quarter = "Q1";
        break;
      case "Quarter 2":
        quarter = "Q2";
        break;
      case "Quarter 3":
        quarter = "Q3";
        break;
      case "Semester 1":
        quarter = "S1";
        break;
      case "Semester 2":
        quarter = "S2";
        break;
      case "Semester 3":
        quarter = "S3";
        break;
      case "PSTU":
        quarter = "PSTU";
        break;
      case "Supplementary":
        quarter = "Supplementary";
        break;
      default:
        quarter = null;
    }
    const year = academicYear.length > 0 ? academicYear[0].label : '2023-2024';
    const obj = addIsAttendedQuery(quarter, year);

    query.filters.push(obj);
  }

  if (regionName.length) {
    query.filters = [
      ...query.filters,
      { member: 'EnrolledCourse.deletedAt', operator: 'notSet' },
      { member: 'Region.deletedAt', operator: 'notSet' },
      { member: 'Region.name', operator: 'equals', values: regionName.map((c) => c?.label) },
    ];
  }
  if (returning.length) {
    query.filters = [
      ...query.filters,
      { member: 'EnrolledCourse.returningStudent', operator: 'equals', values: returning.length ? returning.map((r) => (r?.label === 'Returning')?.toString()) : ['true', 'false'] },
    ];
  }

  if (completionStatus.length) {
    const isPersuing = completionStatus?.find((i) => i?.label === 'Pursuing');
    if (isPersuing) {
      query.filters = [
        ...query.filters,
        {
          member: 'EnrolledCourse.result',
          operator: 'notSet',
        },
      ];
    } else {
      query.filters = [
        ...query.filters,
        {
          member: 'EnrolledCourse.result',
          operator: 'equals',
          values: completionStatus.length ? completionStatus.map((c) => c?.label) : [''],
        },
      ];
    }
  }

  if (marks.length) {
    const filterObj = addMarksQuery(marks);
    query.filters = [
      ...query.filters,
      { member: 'EnrolledCourse.deletedAt', operator: 'notSet' },
      { member: 'Region.deletedAt', operator: 'notSet' },
      { member: 'EnrolledCourse.deletedAt', operator: 'notSet' }, { member: 'Region.deletedAt', operator: 'notSet' },
      {
        or: filterObj,
      },
    ];
  }
  if (createdDate.length) {
    query.filters = [
      ...query.filters,
      { member: 'EnrolledCourse.deletedAt', operator: 'notSet' },
      { member: 'Region.deletedAt', operator: 'notSet' },
      {
        member: 'EnrolledCourse.createdAt',
        operator: 'equals',
        values: createdDate.map((c) => c?.label),
      },
    ];
  }

  if (state.length) {
    query.filters = [
      ...query.filters,
      {
        member: 'Address.state',
        operator: 'equals',
        values: state.length ? state.map((c) => c?.label) : [''],
      },
    ];
  }

  if (centerName.length) {
    query.filters = [
      ...query.filters,
      { member: 'EnrolledCourse.deletedAt', operator: 'notSet' },
      { member: 'Region.deletedAt', operator: 'notSet' },
      {
        member: 'Location.name',
        operator: 'equals',
        values: centerName.map((c) => c?.label),
      },
    ];
  }

  if (courseName.length) {
    query.filters = [
      ...query.filters,
      { member: 'EnrolledCourse.deletedAt', operator: 'notSet' },
      { member: 'Region.deletedAt', operator: 'notSet' },
      {
        member: 'Course.name',
        operator: 'equals',
        values: courseName.map((c) => c?.label),
      },
      {
        member: 'Course.name',
        operator: 'set',
      },
    ];
  }
  if (country.length) {
    query.filters = [
      ...query.filters,
      { member: 'EnrolledCourse.deletedAt', operator: 'notSet' },
      { member: 'Region.deletedAt', operator: 'notSet' },
      countryFilter,
    ];
  }

  if (geoRegion.length) {
    query.filters = [
      ...query.filters,
      { member: 'EnrolledCourse.deletedAt', operator: 'notSet' },
      { member: 'Region.deletedAt', operator: 'notSet' },
      geoRegionFilter,
    ];
  }

  if (regWeek.length && (createdDate.length === 0)) {
    query.filters = [
      ...query.filters,
      { member: 'EnrolledCourse.deletedAt', operator: 'notSet' }, { member: 'Region.deletedAt', operator: 'notSet' },
    ];
    if (selectedoption === 'acrossyear') {
      const dates = regWeek.map((a) => acrossYear.map((year) => getWeekFirstAndLastDate(year, a.label))).flat();
      query.timeDimensions = [{
        dimension: 'EnrolledCourse.createdAt',
        granularity: 'week',
        compareDateRange: dates,
      }];
    } else {
      const year = academicYear.map((a) => a?.label)[0];
      const dates = regWeek.map((a) => getWeekFirstAndLastDate(year, a.label));
      query.timeDimensions = [{
        dimension: 'EnrolledCourse.createdAt',
        granularity: 'week',
        compareDateRange: dates,
      }];
    }
  }
  if (section.length) {
    query.filters = [
      ...query.filters,
      { member: 'GoogleClass.deletedAt', operator: 'equals', values: [null] },
      { member: 'GoogleClass.section', operator: 'equals', values: section.map((c) => c?.label) },
    ];
  }
  if (hasPic.length && hasPic.length !== 2) {
    const pic = hasPic.map((c) => c?.label)[0];
    query.filters = [
      ...query.filters,
      { member: 'Region.deletedAt', operator: 'notSet' }, { member: 'EnrolledCourse.deletedAt', operator: 'notSet' },
      { member: 'User.profilePhoto', operator: pic === 'true' ? 'set' : 'notSet' },
    ];
  }
  if (examCenter.length) {
    query.filters = [
      ...query.filters,
      { member: 'EnrolledCourse.deletedAt', operator: 'notSet' },
      { member: 'Region.deletedAt', operator: 'notSet' },
      { member: 'ExamCenter.name', operator: 'equals', values: examCenter.map((c) => c?.label) },
    ];
  }
  if (status.length) {
    query.filters = [
      ...query.filters,
      { member: 'Region.deletedAt', operator: 'notSet' },
      { member: 'EnrolledCourse.deletedAt', operator: 'notSet' },
      { member: 'EnrolledCourse.isActive', operator: 'equals', values: status.map((c) => c?.label) }];
  }
  if (regWeek.length) query.timezone = "asia/kolkata";

  query.filters = [
    ...query.filters,
    {
      member: 'Course.name',
      operator: 'set',
    },
  ];

  return query;
}

function getQuery(type, selectedOption, latestYears, selectedFilter, locationIds, isRegionalCoordinator = false) {
  const selectedoption = selectedOption === '' ? 'year' : selectedOption;
  const academicYear = filteredData('academic_year', selectedFilter);
  const country = filteredData('country', selectedFilter);
  const returning = filteredData('returning', selectedFilter);
  const regionName = filteredData('region_name', selectedFilter);

  const countryFilter = {
    member: 'Region.country',
    operator: 'equals',
    values: country.length ? country.map((c) => c?.label) : ['United States', 'Canada', 'USA'],
  };
  const status = filteredData('status', selectedFilter);
  const isInactive = status?.filter((i) => i?.label === "false");
  const courseStatusFilter = {
    member: 'EnrolledCourse.courseStatus',
    operator: isInactive?.length ? 'equals' : 'notEquals',
    values: ['CANCELLED'],
  };
  let query = '';

  const enrollGraphOption = selectedFilter?.find((i) => i.filterKey === 'academic_year')?.label;
  const yearOption = enrollGraphOption?.split('-') || currentYears[0]?.split('-');
  const currentYear = (yearOption?.length && yearOption[1]) || 2023;

  const acrossYearArray = [];
  for (let i = 0; i < 4; i++) {
    acrossYearArray.push(Number(currentYear) - i);
  }

  const formattedArray = acrossYearArray?.length && acrossYearArray?.map((i) => {
    return (`${i - 1}-${i}`);
  });
  const acrossYear = formattedArray?.length && formattedArray.reverse();
  const startYear = acrossYear[0]?.split('-');
  const endYear = acrossYear[acrossYear.length - 1]?.split('-');
  const dateRange = startYear?.length && endYear?.length
    ? [`${startYear[1]}-01-01`, `${endYear[1]}-12-31`]
    : ['', ''];
  // ---------------------------across year----------------------------------------------

  if (type === chartTypes.ENROLLMENT_GROWTH_ACROSS_YEAR) {
    query = {
      measures: ['EnrolledCourse.count'],
      dimensions: ['EnrolledCourse.academicYear'],
      order: [['EnrolledCourse.academicYear', 'desc']],
      filters: [
        courseStatusFilter,
        {
          member: 'EnrolledCourse.academicYear',
          operator: 'equals',
          values: acrossYear,
        },
      ],
    };
  }
  if (type === chartTypes.RETURNING_BREAKDOWN_ACROSS_YEAR) {
    query = {
      limit: recordLimit,
      dimensions: [
        'EnrolledCourse.academicYear',
        'EnrolledCourse.returningStudent',
      ],
      order: [['EnrolledCourse.academicYear', 'desc']],
      measures: ['EnrolledCourse.count'],
      filters: [
        {
          member: 'EnrolledCourse.returningStudent',
          operator: 'equals',
          values: returning.length ? returning.map((r) => (r?.label === 'Returning')?.toString()) : ['true', 'false'],
        },
        courseStatusFilter,
        {
          member: 'EnrolledCourse.academicYear',
          operator: 'equals',
          values: acrossYear,
        },
      ],
    };
  }
  if (type === chartTypes.COURSE_ENROLLMENT_BREAKDOWN_BY_REGION_ACROSS_YEAR) {
    query = {
      dimensions: ['EnrolledCourse.academicYear', 'Region.geoRegion'],
      order: [['EnrolledCourse.academicYear', 'desc']],
      measures: ['EnrolledCourse.count'],
      filters: [
        {
          member: 'EnrolledCourse.academicYear',
          operator: 'equals',
          values: acrossYear,
        },
        {
          member: 'Region.geoRegion',
          operator: 'set',
          values: regionName.length ? regionName.map((r) => r?.label) : [''],
        },
        courseStatusFilter,
      ],
    };
  }
  // 3rd
  if (type === chartTypes.ENROLLMENT_BREAKDOWN_ACROSS_YEAR) {
    query = {
      measures: ['EnrolledCourse.count'],
      limit: recordLimit,
      timeDimensions: [
        {
          dimension: 'EnrolledCourse.createdAt',
          granularity: 'day',
          dateRange,
        },
      ],
      filters: [
        {
          member: 'EnrolledCourse.academicYear',
          operator: 'equals',
          values: acrossYear,
        },
        courseStatusFilter,
      ],
    };
  }
  // 4th
  if (type === chartTypes.COURSE_ENROLLMENT_BREAKDOWN_ACROSS_YEAR) {
    query = {
      dimensions: [
        'Course.name',
        'EnrolledCourse.academicYear',
        'Course.level',
      ],
      order: [
        ['Course.level', 'asc'],
      ],
      measures: ['EnrolledCourse.count'],
      filters: [
        {
          member: 'EnrolledCourse.academicYear',
          operator: 'equals',
          values: acrossYear,
        },
        courseStatusFilter,
      ],
    };
  }
  // ---------------------------year----------------------------------------------

  if (type === chartTypes.RETURNING_BREAKDOWN && selectedoption === 'year') {
    query = {
      limit: recordLimit,
      dimensions: ['EnrolledCourse.returningStudent'],
      order: { 'EnrolledCourse.createdAt': 'asc' },
      measures: ['EnrolledCourse.count'],
      filters: [
        { member: "Region.deletedAt", operator: "notSet" },
        {
          member: 'EnrolledCourse.returningStudent',
          operator: 'equals',
          values: returning.length ? returning.map((r) => (r?.label === 'Returning')?.toString()) : ['true', 'false'],
        },
        {
          member: 'EnrolledCourse.academicYear',
          operator: 'equals',
          values: academicYear.length ? academicYear.map((a) => a?.label) : [''],
        },
        courseStatusFilter,
      ],
    };
  }
  if (type === chartTypes.GEO_REGION_BREAK_DOWN && selectedoption === 'year') {
    query = {
      measures: ['EnrolledCourse.count'],
      limit: recordLimit,
      filters: [
        { member: 'EnrolledCourse.deletedAt', operator: 'notSet' },
        { member: 'Region.deletedAt', operator: 'notSet' },
        {
          member: 'EnrolledCourse.academicYear',
          operator: 'equals',
          values: academicYear.length ? academicYear.map((a) => a?.label) : [''],
        },
        { member: 'Region.geoRegion', operator: 'set', values: regionName.length ? regionName.map((r) => r?.label) : [''] },
        {
          member: 'EnrolledCourse.returningStudent',
          operator: 'equals',
          values: returning.length ? returning.map((r) => (r?.label === 'Returning')?.toString()) : ['true', 'false'],
        },
        courseStatusFilter,
      ],
      dimensions: ['Region.geoRegion'],
      order: { 'EnrolledCourse.count': 'desc' },
    };
  }
  if (
    type === chartTypes.ENROLLMENT_BREAKDOWN_BY_WEEK
    && selectedoption === 'year'
  ) {
    query = {
      limit: recordLimit,
      dimensions: ['EnrolledCourse.returningStudent'],
      order: [['EnrolledCourse.createdAt', 'desc']],
      measures: ['EnrolledCourse.count'],
      filters: [
        {
          member: 'EnrolledCourse.returningStudent',
          operator: 'equals',
          values: returning.length ? returning.map((r) => (r?.label === 'Returning')?.toString()) : ['true', 'false'],
        },
        {
          member: 'EnrolledCourse.academicYear',
          operator: 'equals',
          values: academicYear.length ? academicYear.map((a) => a?.label) : [''],
        },
        courseStatusFilter,
      ],
      timeDimensions: [
        {
          dimension: 'EnrolledCourse.createdAt',
          granularity: 'week',
        },
      ],
    };
  }
  if (
    type === chartTypes.COURSE_ENROLLMENT_BREAKDOWN
    && selectedoption === 'year'
  ) {
    query = {
      order: [['Course.level', 'asc']],
      measures: ['EnrolledCourse.count'],
      limit: recordLimit,
      dimensions: [
        'Course.name',
        'EnrolledCourse.academicYear',
        'Course.level',
      ],
      filters: [
        {
          member: 'EnrolledCourse.academicYear',
          operator: 'equals',
          values: academicYear.length ? academicYear.map((a) => a?.label) : [''],
        },
        {
          member: 'Course.name',
          operator: 'set',
        },
        {
          member: 'EnrolledCourse.returningStudent',
          operator: 'equals',
          values: returning.length ? returning.map((r) => (r?.label === 'Returning')?.toString()) : ['true', 'false'],
        },
        courseStatusFilter,
      ],
    };
  }
  if (type === chartTypes.MAP_VIEW && selectedoption === 'year') {
    query = {
      dimensions: [
        'Location.name',
        'Region.country',
        'Address.state',
        'Address.latitude',
        'Address.longitude',
      ],
      order: { 'EnrolledCourse.count': 'desc' },
      filters: [
        {
          member: 'EnrolledCourse.deletedAt',
          operator: 'notSet',
        },
        {
          member: 'Region.deletedAt',
          operator: 'notSet',
        },
        {
          member: 'EnrolledCourse.returningStudent',
          operator: 'equals',
          values: returning.length ? returning.map((c) => (c?.label === 'Returning')?.toString()) : ['true', 'false'],
        },
        countryFilter,
        { member: 'Address.state', operator: 'set' },
        { member: 'Address.latitude', operator: 'set' },
        { member: 'Address.longitude', operator: 'set' },
        {
          member: 'EnrolledCourse.academicYear',
          operator: 'equals',
          values: academicYear.length ? academicYear.map((a) => a?.label) : [''],
        },
        courseStatusFilter,
      ],
      measures: ['EnrolledCourse.count'],
    };
  }
  // ---------------------------month----------------------------------------------

  if (type === chartTypes.MAP_VIEW && selectedoption === 'month') {
    query = {
      dimensions: [
        'Location.name',
        'Region.country',
        'Address.state',
        'Address.latitude',
        'Address.longitude',
      ],
      order: { 'EnrolledCourse.count': 'desc' },
      filters: [
        {
          member: 'EnrolledCourse.deletedAt',
          operator: 'notSet',
        },
        {
          member: 'Region.deletedAt',
          operator: 'notSet',
        },
        {
          member: 'EnrolledCourse.returningStudent',
          operator: 'equals',
          values: returning.length ? returning.map((r) => (r?.label === 'Returning')?.toString()) : ['true', 'false'],
        },
        countryFilter,
        { member: 'Address.state', operator: 'set' },
        { member: 'Address.latitude', operator: 'set' },
        { member: 'Address.longitude', operator: 'set' },
        {
          member: 'EnrolledCourse.academicYear',
          operator: 'equals',
          values: academicYear.length ? academicYear.map((a) => a?.label) : [''],
        },
        courseStatusFilter,
      ],
      measures: ['EnrolledCourse.count'],
      timeDimensions: [
        {
          dimension: 'EnrolledCourse.createdAt',
          dateRange: last30Days,

        },
      ],
    };
  }
  if (
    type === chartTypes.ENROLLMENT_BREAKDOWN_BY_WEEK
    && selectedoption === 'month'
  ) {
    query = {
      limit: recordLimit,
      dimensions: ['EnrolledCourse.returningStudent'],
      measures: ['EnrolledCourse.count'],
      filters: [
        {
          member: 'EnrolledCourse.returningStudent',
          operator: 'equals',
          values: returning.length ? returning.map((r) => (r?.label === 'Returning')?.toString()) : ['true', 'false'],
        },
        courseStatusFilter,
        {
          member: 'EnrolledCourse.academicYear',
          operator: 'equals',
          values: academicYear.length ? academicYear.map((a) => a?.label) : [''],
        },
      ],
      timeDimensions: [
        {
          dimension: 'EnrolledCourse.createdAt',
          granularity: 'week',
          dateRange: last30Days,

        },
      ],
      order: [['EnrolledCourse.createdAt', 'desc']],
    };
  }

  if (
    type === chartTypes.COURSE_ENROLLMENT_BREAKDOWN
    && selectedoption === 'month'
  ) {
    query = {
      order: [['Course.level', 'asc']],
      measures: ['EnrolledCourse.count'],
      limit: recordLimit,
      filters: [
        {
          member: 'EnrolledCourse.academicYear',
          operator: 'equals',
          values: academicYear.length ? academicYear.map((a) => a?.label) : [''],
        },
        {
          member: 'EnrolledCourse.returningStudent',
          operator: 'equals',
          values: returning.length ? returning.map((r) => (r?.label === 'Returning')?.toString()) : ['true', 'false'],
        },
        courseStatusFilter,
      ],
      dimensions: [
        'Course.name',
        'EnrolledCourse.academicYear',
        'Course.level',
      ],
      timeDimensions: [
        {
          dimension: 'EnrolledCourse.createdAt',
          dateRange: last30Days,

        },
      ],
    };
  }
  if (type === chartTypes.RETURNING_BREAKDOWN && selectedoption === 'month') {
    query = {
      limit: recordLimit,
      dimensions: ['EnrolledCourse.returningStudent'],
      order: { 'EnrolledCourse.createdAt': 'asc' },
      measures: ['EnrolledCourse.count'],
      filters: [
        { member: "Region.deletedAt", operator: "notSet" },
        {
          member: 'EnrolledCourse.academicYear',
          operator: 'equals',
          values: academicYear.length ? academicYear.map((a) => a?.label) : [''],
        },
        {
          member: 'EnrolledCourse.returningStudent',
          operator: 'equals',
          values: returning.length ? returning.map((r) => (r?.label === 'Returning')?.toString()) : ['true', 'false'],
        },
        courseStatusFilter,
      ],
      timeDimensions: [
        {
          dimension: 'EnrolledCourse.createdAt',
          dateRange: last30Days,

        },
      ],
    };
  }
  if (type === chartTypes.GEO_REGION_BREAK_DOWN && selectedoption === 'month') {
    query = {
      order: { 'EnrolledCourse.count': 'desc' },
      measures: ['EnrolledCourse.count'],
      limit: recordLimit,
      timeDimensions: [
        {
          dimension: 'EnrolledCourse.createdAt',
          dateRange: last30Days,

        },
      ],
      dimensions: ['Region.geoRegion'],
      filters: [
        { member: 'EnrolledCourse.deletedAt', operator: 'notSet' },
        { member: 'Region.deletedAt', operator: 'notSet' },
        {
          member: 'EnrolledCourse.academicYear',
          operator: 'equals',
          values: academicYear.length ? academicYear.map((a) => a?.label) : [''],
        },
        { member: 'Region.geoRegion', operator: 'set', values: regionName.length ? regionName.map((r) => r?.label) : [''] },
        {
          member: 'EnrolledCourse.returningStudent',
          operator: 'equals',
          values: returning.length ? returning.map((r) => (r?.label === 'Returning')?.toString()) : ['true', 'false'],
        },
        courseStatusFilter,
      ],
    };
  }

  // ---------------------------week----------------------------------------------

  if (
    type === chartTypes.COURSE_ENROLLMENT_BREAKDOWN
    && selectedoption === 'week'
  ) {
    query = {
      order: [['Course.level', 'asc']],
      measures: ['EnrolledCourse.count'],
      limit: recordLimit,
      dimensions: [
        'Course.name',
        'EnrolledCourse.academicYear',
        'Course.level',
      ],
      filters: [
        {
          member: 'EnrolledCourse.academicYear',
          operator: 'equals',
          values: academicYear.length ? academicYear.map((a) => a?.label) : [''],
        },
        {
          member: 'EnrolledCourse.returningStudent',
          operator: 'equals',
          values: returning.length ? returning.map((r) => (r?.label === 'Returning')?.toString()) : ['true', 'false'],
        },
        courseStatusFilter,
      ],
      timeDimensions: [
        {
          dimension: 'EnrolledCourse.createdAt',
          dateRange: last7Days,
        },
      ],
    };
  }
  if (type === chartTypes.RETURNING_BREAKDOWN && selectedoption === 'week') {
    query = {
      limit: recordLimit,
      dimensions: ['EnrolledCourse.returningStudent'],
      order: { 'EnrolledCourse.createdAt': 'asc' },
      measures: ['EnrolledCourse.count'],
      filters: [
        { member: "Region.deletedAt", operator: "notSet" },
        {
          member: 'EnrolledCourse.academicYear',
          operator: 'equals',
          values: academicYear.length ? academicYear.map((a) => a?.label) : [''],
        },
        {
          member: 'EnrolledCourse.returningStudent',
          operator: 'equals',
          values: returning.length ? returning.map((r) => (r?.label === 'Returning')?.toString()) : ['true', 'false'],
        },
        courseStatusFilter,
      ],
      timeDimensions: [
        {
          dimension: 'EnrolledCourse.createdAt',
          dateRange: last7Days,
        },
      ],
    };
  }
  if (type === chartTypes.GEO_REGION_BREAK_DOWN && selectedoption === 'week') {
    query = {
      measures: ['EnrolledCourse.count'],
      limit: recordLimit,
      filters: [
        { member: 'EnrolledCourse.deletedAt', operator: 'notSet' },
        { member: 'Region.deletedAt', operator: 'notSet' },
        {
          member: 'EnrolledCourse.academicYear',
          operator: 'equals',
          values: academicYear.length ? academicYear.map((a) => a?.label) : [''],
        },
        { member: 'Region.geoRegion', operator: 'set', values: regionName.length ? regionName.map((r) => r?.label) : [''] },
        {
          member: 'EnrolledCourse.returningStudent',
          operator: 'equals',
          values: returning.length ? returning.map((r) => (r?.label === 'Returning')?.toString()) : ['true', 'false'],
        },
        courseStatusFilter,
      ],
      dimensions: ['Region.geoRegion'],
      order: { 'EnrolledCourse.count': 'desc' },
      timeDimensions: [
        {
          dimension: 'EnrolledCourse.createdAt',
          dateRange: last7Days,

        },
      ],
    };
  }
  if (type === chartTypes.MAP_VIEW && selectedoption === 'week') {
    query = {
      dimensions: [
        'Location.name',
        'Region.country',
        'Address.state',
        'Address.latitude',
        'Address.longitude',
      ],
      order: { 'EnrolledCourse.count': 'desc' },
      filters: [
        {
          member: 'EnrolledCourse.deletedAt',
          operator: 'notSet',
        },
        {
          member: 'Region.deletedAt',
          operator: 'notSet',
        },
        {
          member: 'EnrolledCourse.returningStudent',
          operator: 'equals',
          values: returning.length ? returning.map((r) => (r?.label === 'Returning')?.toString()) : ['true', 'false'],
        },
        countryFilter,
        { member: 'Address.state', operator: 'set' },
        { member: 'Address.latitude', operator: 'set' },
        { member: 'Address.longitude', operator: 'set' },
        {
          member: 'EnrolledCourse.academicYear',
          operator: 'equals',
          values: academicYear.length ? academicYear.map((a) => a?.label) : [''],
        },
        courseStatusFilter,
      ],
      measures: ['EnrolledCourse.count'],
      timeDimensions: [
        { dimension: 'EnrolledCourse.createdAt', dateRange: last7Days },
      ],
    };
  }
  if (
    type === chartTypes.ENROLLMENT_BREAKDOWN_BY_WEEK
    && selectedoption === 'week'
  ) {
    query = {
      limit: recordLimit,
      dimensions: ['EnrolledCourse.returningStudent'],
      measures: ['EnrolledCourse.count'],
      filters: [
        {
          member: 'EnrolledCourse.returningStudent',
          operator: 'equals',
          values: returning.length ? returning.map((r) => (r?.label === 'Returning')?.toString()) : ['true', 'false'],
        },
        courseStatusFilter,
        {
          member: 'EnrolledCourse.academicYear',
          operator: 'equals',
          values: academicYear.length ? academicYear.map((a) => a?.label) : [''],
        },
      ],
      timeDimensions: [
        {
          dimension: 'EnrolledCourse.createdAt',
          granularity: 'week',
          dateRange: last30Days,

        },
      ],
      order: [['EnrolledCourse.createdAt', 'desc']],
    };
  }
  // ---------------------------today----------------------------------------------
  if (
    type === chartTypes.COURSE_ENROLLMENT_BREAKDOWN
    && selectedoption === 'today'
  ) {
    query = {
      order: [['Course.level', 'asc']],
      measures: ['EnrolledCourse.count'],
      limit: recordLimit,
      dimensions: [
        'Course.name',
        'EnrolledCourse.academicYear',
        'Course.level',
      ],
      filters: [
        courseStatusFilter,
        {
          member: 'EnrolledCourse.academicYear',
          operator: 'equals',
          values: academicYear.length ? academicYear.map((a) => a?.label) : [''],
        },
        {
          member: 'EnrolledCourse.returningStudent',
          operator: 'equals',
          values: returning.length ? returning.map((r) => (r?.label === 'Returning')?.toString()) : ['true', 'false'],
        },
      ],
      timeDimensions: [
        { dimension: 'EnrolledCourse.createdAt', dateRange: 'Today' },
      ],
    };
  }
  if (type === chartTypes.RETURNING_BREAKDOWN && selectedoption === 'today') {
    query = {
      limit: recordLimit,
      dimensions: ['EnrolledCourse.returningStudent'],
      order: { 'EnrolledCourse.createdAt': 'asc' },
      measures: ['EnrolledCourse.count'],
      filters: [
        { member: "Region.deletedAt", operator: "notSet" },
        {
          member: 'EnrolledCourse.returningStudent',
          operator: 'equals',
          values: returning.length ? returning.map((r) => (r?.label === 'Returning')?.toString()) : ['true', 'false'],
        },
        {
          member: 'EnrolledCourse.academicYear',
          operator: 'equals',
          values: academicYear.length ? academicYear.map((a) => a?.label) : [''],
        },
        courseStatusFilter,
      ],
      timeDimensions: [
        { dimension: 'EnrolledCourse.createdAt', dateRange: 'Today' },
      ],
    };
  }

  if (type === chartTypes.GEO_REGION_BREAK_DOWN && selectedoption === 'today') {
    query = {
      measures: ['EnrolledCourse.count'],
      limit: recordLimit,
      filters: [
        { member: 'EnrolledCourse.deletedAt', operator: 'notSet' },
        { member: 'Region.deletedAt', operator: 'notSet' },
        { member: 'Region.geoRegion', operator: 'set', values: regionName.length ? regionName.map((r) => r?.label) : [''] },
        {
          member: 'EnrolledCourse.returningStudent',
          operator: 'equals',
          values: returning.length ? returning.map((r) => (r?.label === 'Returning')?.toString()) : ['true', 'false'],
        },
        {
          member: 'EnrolledCourse.academicYear',
          operator: 'equals',
          values: academicYear.length ? academicYear.map((a) => a?.label) : [''],
        },
        courseStatusFilter,
      ],
      dimensions: ['Region.geoRegion'],
      order: { 'EnrolledCourse.count': 'desc' },
      timeDimensions: [
        { dimension: 'EnrolledCourse.createdAt', dateRange: 'Today' },
      ],
    };
  }
  if (type === chartTypes.MAP_VIEW && selectedoption === 'today') {
    query = {
      dimensions: [
        'Location.name',
        'Region.country',
        'Address.state',
        'Address.latitude',
        'Address.longitude',
      ],
      order: { 'EnrolledCourse.count': 'desc' },
      filters: [
        {
          member: 'EnrolledCourse.deletedAt',
          operator: 'notSet',
        },
        {
          member: 'Region.deletedAt',
          operator: 'notSet',
        },
        {
          member: 'EnrolledCourse.returningStudent',
          operator: 'equals',
          values: returning.length ? returning.map((r) => (r?.label === 'Returning')?.toString()) : ['true', 'false'],
        },
        countryFilter,
        { member: 'Address.state', operator: 'set' },
        { member: 'Address.latitude', operator: 'set' },
        { member: 'Address.longitude', operator: 'set' },
        {
          member: 'EnrolledCourse.academicYear',
          operator: 'equals',
          values: academicYear.length ? academicYear.map((a) => a?.label) : [''],
        },
        courseStatusFilter,
      ],
      measures: ['EnrolledCourse.count'],
      timeDimensions: [
        { dimension: 'EnrolledCourse.createdAt', dateRange: 'Today' },
      ],
    };
  }
  if (
    type === chartTypes.ENROLLMENT_BREAKDOWN_BY_WEEK
    && selectedoption === 'today'
  ) {
    query = {
      limit: recordLimit,
      dimensions: ['EnrolledCourse.returningStudent'],
      measures: ['EnrolledCourse.count'],
      filters: [
        {
          member: 'EnrolledCourse.returningStudent',
          operator: 'equals',
          values: returning.length ? returning.map((r) => (r?.label === 'Returning')?.toString()) : ['true', 'false'],
        },
        courseStatusFilter,
        {
          member: 'EnrolledCourse.academicYear',
          operator: 'equals',
          values: academicYear.length ? academicYear.map((a) => a?.label) : [''],
        },
      ],
      timeDimensions: [
        {
          dimension: 'EnrolledCourse.createdAt',
          granularity: 'week',
          dateRange: last30Days,

        },
      ],
      order: [['EnrolledCourse.createdAt', 'desc']],
    };
  }

  if (query) {
    query = modifyQuery(query, selectedFilter, selectedoption, acrossYear);
    if (locationIds.length > 0 && isRegionalCoordinator) {
      query.filters.push({
        member: 'EnrolledCourse.locationId',
        operator: 'equals',
        values: locationIds,
      });
    }
    if (locationIds.length === 0 && isRegionalCoordinator) {
      query.filters.push({
        member: 'EnrolledCourse.locationId',
        operator: 'notSet',
      });
    }
    if (isRegionalCoordinator) {
      query.filters = [
        ...query.filters,
        { member: "Region.isActive", operator: "equals", values: ["true"] },
      ];
    }
  }
  return query;
}

function getEnrollQuery(selectedOption, selectedFilter, locationIds, isRegionalCoordinator = false) {
  if (!selectedFilter) {
    return '';
  }
  const selectedoption = selectedOption === '' ? 'year' : selectedOption;
  const academicYear = filteredData('academic_year', selectedFilter);
  const returning = filteredData('returning', selectedFilter);
  const status = filteredData('status', selectedFilter);

  const isInactive = status?.filter((i) => i?.label === "false");

  const courseStatusFilter = {
    member: 'EnrolledCourse.courseStatus',
    operator: isInactive?.length ? 'equals' : 'notEquals',
    values: ['CANCELLED'],
  };
  let query;
  const returningFilter = {
    member: 'EnrolledCourse.returningStudent',
    operator: 'equals',
    values: returning.length ? returning.map((r) => (r?.label === 'Returning')?.toString()) : ['true', 'false'],
  };
  if (selectedoption === 'acrossyear') {
    query = {
      measures: ['EnrolledCourse.count'],
      filters: [
        returningFilter,
        courseStatusFilter,
      ],
      dimensions: ['EnrolledCourse.academicYear'],
      order: [['EnrolledCourse.academicYear', 'desc']],
    };
  }
  if (selectedoption === 'year') {
    query = {
      measures: ['EnrolledCourse.count'],
      filters: [
        returningFilter,
        courseStatusFilter,
        {
          member: 'EnrolledCourse.academicYear',
          operator: 'equals',
          values: academicYear.length ? academicYear.map((a) => a?.label) : currentYears,
        },
      ],
    };
  }

  if (selectedoption === 'week') {
    query = {
      measures: ['EnrolledCourse.count'],
      filters: [
        returningFilter,
        {
          member: 'EnrolledCourse.academicYear',
          operator: 'equals',
          values: academicYear.length ? academicYear.map((a) => a?.label) : currentYears,
        },
        courseStatusFilter,
      ],
      timeDimensions: [
        {
          dimension: 'EnrolledCourse.createdAt',
          dateRange: last7Days,

        },
      ],
    };
  }
  if (selectedoption === 'today') {
    query = {
      measures: ['EnrolledCourse.count'],
      filters: [
        returningFilter,
        courseStatusFilter,
      ],
      timeDimensions: [
        { dimension: 'EnrolledCourse.createdAt', dateRange: 'Today' },
      ],
    };
  }

  // month
  if (selectedoption === 'month') {
    query = {
      measures: ['EnrolledCourse.count'],
      timeDimensions: [
        {
          dimension: 'EnrolledCourse.createdAt',
          dateRange: last30Days,
        },
      ],
      filters: [
        returningFilter,
        {
          member: 'EnrolledCourse.academicYear',
          operator: 'equals',
          values: academicYear.length ? academicYear.map((a) => a?.label) : currentYears,
        },
        courseStatusFilter,
      ],
    };
  }

  if (query) {
    const enrollGraphOption = selectedFilter?.find((i) => i.filterKey === 'academic_year')?.label;
    const yearOption = enrollGraphOption?.split('-') || currentYears[0]?.split('-');
    const currentYear = (yearOption?.length && yearOption[1]) || 2023;

    const acrossYearArray = [];
    for (let i = 0; i < 4; i++) {
      acrossYearArray.push(Number(currentYear) - i);
    }

    const formattedArray = acrossYearArray?.length && acrossYearArray?.map((i) => {
      return (`${i - 1}-${i}`);
    });
    const acrossYear = formattedArray?.length && formattedArray.reverse();
    query = modifyQuery(query, selectedFilter, selectedoption, acrossYear);
    if (locationIds.length > 0 && isRegionalCoordinator) {
      query.filters.push({
        member: 'EnrolledCourse.locationId',
        operator: 'equals',
        values: locationIds,
      });
    }
    if (locationIds.length === 0 && isRegionalCoordinator) {
      query.filters.push({
        member: 'EnrolledCourse.locationId',
        operator: 'notSet',
      });
    }
  }

  if (isRegionalCoordinator) {
    query.filters = [
      ...query.filters,
      { member: "Region.isActive", operator: "equals", values: ["true"] },
    ];
  }

  return query;
}
const COLORS = [
  colors.persianBlue,
  colors.yellowOrange,
  colors.rum,
  colors.highLand,
  colors.violet,
  colors.persimmon,
  colors.rosePink,
  colors.primary,
  colors.secondary,
];
function getColor(year) {
  let color;
  if (year === '2018-2019') {
    color = COLORS[0];
  }
  if (year === '2019-2020') {
    color = COLORS[1];
  }
  if (year === '2020-2021') {
    color = COLORS[2];
  }
  if (year === '2021-2022') {
    color = COLORS[3];
  }
  if (year === '2022-2023') {
    color = COLORS[4];
  }
  return color;
}

const formatYearArray = (arr) => {
  const yearSet = new Set();
  let finalArr = [];
  arr.forEach((itm) => {
    if (!yearSet.has(itm.name)) {
      yearSet.add(itm.name);
      const sameYearArr = arr.filter((yr) => yr.name === itm.name);
      sameYearArr.sort((a, b) => b.isReturning - a.isReturning);
      sameYearArr.forEach((smY) => {
        smY.sortBy = Number(smY.name.split('-').join(''));
      });
      finalArr = [...finalArr, ...sameYearArr];
    }
  });

  finalArr.sort((a, b) => b.sortBy - a.sortBy);
  finalArr.forEach((itm) => delete itm.sortBy);
  return finalArr;
};

const getTimezoneOffset = (value) => value.getTimezoneOffset() * 60000;

const makeLocalAppearUTC = (value) => {
  if (!value) {
    return value;
  }
  const dateTime = new Date(value);
  const utcFromLocal = new Date(dateTime.getTime() + getTimezoneOffset(dateTime));
  return utcFromLocal;
};

const localToUTC = (dateTime) => {
  if (!dateTime) {
    return dateTime;
  }
  const utcFromLocal = new Date(dateTime.getTime() - getTimezoneOffset(dateTime));
  return utcFromLocal;
};

export {
  getEnrollQuery, getQuery, getColor, formatYearArray, filteredData,
  makeLocalAppearUTC, localToUTC,
};
