import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, Checkbox } from '../../../components/atoms';
import { Buttons } from '../../../constant';
import useStyles from '../../../custom-hooks/useStyles';
import styles from './style';
import commonStyle from '../../../utils/commonClasses';
import {
  getAllQuarterFields,
  getAllSemesterFields,
  getQuarterCSVHeaders,
  getSemesterCSVHeaders,
  getAllSemesterFields2023,
} from '../list-view/columns';

export default function SelectColumns({
  setDialogOpen, visibleFields,
  setVisibleFields,
  setFileHeaders,
  validSemester,
  isselectedYear,
}) {
  const [checkList, setCheckList] = useState([]);
  const [csvHeaders, setCsvHeaders] = useState([]);
  const { t } = useTranslation();
  const [checked, setChecked] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(true);
  const [selectS1Checked, setSelectS1Checked] = useState(true);
  const [selectS2Checked, setSelectS2Checked] = useState(true);
  const reduxStore = useSelector((state) => state);
  const courseList = reduxStore?.getStudent?.courses;
  const classes = useStyles(styles)();
  const commonClasses = useStyles(commonStyle)();

  useEffect(() => {
    if (validSemester) {
      if (isselectedYear === '2023-2024') {
        setCheckList(getAllSemesterFields2023(t));
      } else {
        setCheckList(getAllSemesterFields(t));
      }
      setCsvHeaders(getSemesterCSVHeaders(t));
    } else {
      setCheckList(getAllQuarterFields(t));
      setCsvHeaders(getQuarterCSVHeaders(t));
    }
  }, [validSemester, isselectedYear]);

  useEffect(() => {
  }, [courseList]);

  useEffect(() => {
    setChecked(visibleFields);
    setSelectAllChecked(visibleFields.length === checkList.length);
    setSelectS1Checked(visibleFields.some((field) => field.startsWith('S1')));
    setSelectS2Checked(visibleFields.some((field) => field.startsWith('S2')));
  }, [visibleFields, checkList]);

  const handleCheck = (event, item) => {
    let updatedList = [...checked];

    const handleRelatedFields = (semester, add) => {
      const relatedFields = checkList.filter((field) => field.startsWith(semester));
      if (add) {
        updatedList = [...new Set([...updatedList, ...relatedFields])];
      } else {
        updatedList = updatedList.filter((field) => !relatedFields.includes(field));
      }
    };

    if (event.target.checked) {
      updatedList = [...checked, item];
      if (item === 'Semester 1') {
        handleRelatedFields('S1', true);
        setSelectS1Checked(true);
      } else if (item === 'Semester 2') {
        handleRelatedFields('S2', true);
        setSelectS2Checked(true);
      } else if (item.startsWith('S1')) {
        const relatedFields = checkList.filter((field) => field.startsWith('S1'));
        const relatedFieldsChecked = updatedList.filter((field) => field.startsWith('S1'));
        setSelectS1Checked(relatedFields.length === relatedFieldsChecked.length);
      } else if (item.startsWith('S2')) {
        const relatedFields = checkList.filter((field) => field.startsWith('S2'));
        const relatedFieldsChecked = updatedList.filter((field) => field.startsWith('S2'));
        setSelectS2Checked(relatedFields.length === relatedFieldsChecked.length);
      }
    } else {
      if (item !== 'Semester 1' && item !== 'Semester 2') {
        updatedList.splice(updatedList.indexOf(item), 1);
        if (item.startsWith('S1')) {
          setSelectS1Checked(false);
        } else if (item.startsWith('S2')) {
          setSelectS2Checked(false);
        }
      }
      if (item === 'Semester 1') {
        handleRelatedFields('S1', false);
        setSelectS1Checked(false);
      } else if (item === 'Semester 2') {
        handleRelatedFields('S2', false);
        setSelectS2Checked(false);
      }
    }
    setChecked(updatedList);
    setSelectAllChecked(updatedList.length === checkList.length);
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setChecked(checkList);
      setSelectAllChecked(true);
      setSelectS1Checked(true);
      setSelectS2Checked(true);
    } else {
      setChecked([t('STUDENT_NAME')]);
      setSelectAllChecked(false);
      setSelectS1Checked(false);
      setSelectS2Checked(false);
    }
  };

  const handleSubmit = () => {
    setVisibleFields(checked);
    setDialogOpen(false);
    const prevHeader = [...csvHeaders];
    const headers = prevHeader?.filter((o1) => checked?.some((o2) => o1.name === o2));
    if (headers?.length) {
      setFileHeaders(headers);
    }
  };

  const isChecked = (item) => (!!checked.includes(item));

  return (
    <Grid container className={classes.gridContainer}>
      <Grid item xs={12}>
        <Grid container justifyContent="flex-start" flexDirection="row" flexWrap="wrap" className={classes.checkboxesRow}>
          <Grid item xs={6} md={4} lg={2.4}>
            <Checkbox
              customClasses="columnCheckBox"
              checked={selectAllChecked}
              value="selectAll"
              label={t('SELECT_ALL')}
              handleChange={handleSelectAll}
            />
          </Grid>
          <Grid item xs={6} md={4} lg={2.4}>
            <Checkbox
              customClasses="columnCheckBox"
              checked={selectS1Checked}
              value="selectS1"
              label={t('S1')}
              handleChange={(e) => handleCheck(e, 'Semester 1')}
            />
          </Grid>
          <Grid item xs={6} md={4} lg={2.4}>
            <Checkbox
              customClasses="columnCheckBox"
              checked={selectS2Checked}
              value="selectS2"
              label={t('S2')}
              handleChange={(e) => handleCheck(e, 'Semester 2')}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-start" flexDirection="row" flexWrap="wrap" className={classes.checkboxesRow}>
          {checkList.map((item) => (
            <Grid item xs={6} md={4} lg={2.4} key={item}>
              <Checkbox
                customClasses="columnCheckBox"
                checked={isChecked(item)}
                value={item}
                label={item}
                handleChange={(e) => handleCheck(e, item)}
                disabled={item === t('PICTURES') || item === t('STUDENT_NAME')}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid container className={classes.dialogButtons}>
        <Grid container justifyContent="flex-end">
          <Button
            name={t('CANCEL')}
            onClick={() => setDialogOpen(false)}
            btntype={Buttons.SECONDARY}
          />
          <Button
            name={t('SAVE')}
            onClick={handleSubmit}
            btntype={Buttons.PRIMARY}
            className={commonClasses.activeButton}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
