/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import {
  Box, Grid, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Scheme from './index';
import { styles } from './styles';
import useStyles from '../../../../custom-hooks/useStyles';

import useStudent from '../../../../custom-hooks/useStudent';
import { getAllCourses } from '../../../../store/actions/getStudent';
import { getAssignedLocations } from '../../../../store/actions/getLocationCoordinator';
import useLocationCoordinator from '../../../../custom-hooks/useLocationCoordinator';
import { Loader } from '../../../../components/atoms';

function Schema() {
  const classes = useStyles(styles)();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  const [selectedCourse, setSelectedCourse] = useState('');

  useEffect(() => {
    setLoading(true);
    const loadFalse = () => setLoading(false);

    dispatch(getAllCourses());
    dispatch(getAssignedLocations(loadFalse));
  }, []);

  const { courses } = useStudent();
  const { assignedYears } = useLocationCoordinator();

  return (
    <Box p={2}>
      {loading && (
        <Grid>
          <Loader message={t('LOADING')} />
        </Grid>
      )}
      <Box padding={3}>
        <Grid container item className={classes.titleRow}>
          <Grid item>
            <Typography className={classes.headerTitle}>
              {t('ACADEMIC_SCHEMA')}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box class={classes.contentCont}>
        <Box class={classes.content}>
            <Scheme {...{
              courses,
              academicYears: assignedYears,
              setLoading,
              selectedCourse,
              setSelectedCourse,
            }}
            />
        </Box>
      </Box>
    </Box>
  );
}

export default Schema;
