import {
  Box, Grid, TextField, Typography,
} from '@mui/material';
import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikProvider, useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import * as Yup from 'yup';
import useStyles from '../../../custom-hooks/useStyles';
import styles from './styles';

import { getAllCourses } from '../../../store/actions/getStudent';
import useStudent from '../../../custom-hooks/useStudent';
import useLocationCoordinator from '../../../custom-hooks/useLocationCoordinator';
import { getAssignedLocations } from '../../../store/actions/getLocationCoordinator';
import { Loader, DropdownCurricular } from '../../../components/atoms';
import RenderCategoryField from './category-fields';
import { createCurricularActivitiesSchema, getCurricularActivitiesSchema, updateCurricularActivitiesSchema } from '../../../store/actions/curricular-activities';
import { getQuarterDetails } from '../../../store/actions/academic-panel';
import useCurricularActivitiesSchema from '../../../custom-hooks/useCurricularActivities';
import { getCurrentYear, isValidSemesterYear } from '../../../utils/methods';

function ActivitiesSchema() {
  const classes = useStyles(styles)();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { courses } = useStudent();
  const { assignedYears } = useLocationCoordinator();
  const [loading, setLoading] = useState(true);
  const [updateSchema, setUpdateSchema] = useState(false);
  const [curricularMarksCopy, setCurricularMarksCopy] = useState([]);
  const [error, setError] = useState('');
  const [errorMarks, setErrorMarks] = useState('');
  const [validSemesterYear, setValidSemesterYear] = useState(true);
  const [successMsg, setSuccessMsg] = useState('');
  const [errorSchemaRemoved, setErrorSchemaRemoved] = useState('');
  const [saveClicked, setSaveClicked] = useState(false);

  const semesterOptions = [
    { id: 'S1', key: 'SEMESTER1' },
    { id: 'S2', key: 'SEMESTER2' },
  ];

  useEffect(() => {
    setLoading(true);
    const loadFalse = () => setLoading(false);
    dispatch(getAllCourses());
    dispatch(getAssignedLocations(loadFalse));
    setErrorSchemaRemoved('');
    setSuccessMsg('');
  }, []);

  const restrictKeys = (ev, allowDecimal = false) => {
    const restrictedSymbols = ['+', '-', 'e', '*'];

    if (!allowDecimal) {
      restrictedSymbols.push('.');
    }

    if (restrictedSymbols.includes(ev.key)) {
      ev.preventDefault();
    }
  };

  const useGetCourseRows = (ques) => {
    const [rows, setRow] = useState([]);

    useEffect(() => {
      setRow([]);
      [...Array(ques)].map((_, i) => setRow((oldRows) => [...oldRows, i]));
    }, [ques]);

    return { rows, setRow };
  };

  const isValidDate = (value) => {
    const val = moment(value).format('yyyy-MM-DD');
    const now = moment().format('yyyy-MM-DD');
    if (moment(val).isBefore(moment(now))) {
      return false;
    }
    return true;
  };

  const handleSave = (values) => {
    setLoading(true);

    const createCurricularSchema = values?.activitiesSchemaMarks?.map((ele) => ({
      category: ele.category,
      marks: ele.marks,
      notes: ele.notes,
    }));

    const updateCurricularSchema = values?.activitiesSchemaMarks?.map((ele) => ({
      category: ele.category || 'category',
      marks: ele.marks || 0,
      notes: ele.notes || 'notes',
      isActive: true,
      attendenceActivitiesSchemaId: updateSchema,
    }));

    const payload = {
      courseId: values.courses,
      academicYear: assignedYears.find((year) => year.id === values.academicYear)?.name,
      maximumMarks: values.maxMarks,
      schemaLabel: values.schemaLabel,
      passingCriteria: 0,
      weightage: values.weightage,
      totalQuestions: values.totalQuestions,
      attendenceActivitiesSchemaMarks: updateSchema ? updateCurricularSchema : createCurricularSchema,
      quarter: values.semester,
      isActive: !!updateSchema,
      ...(updateSchema ? { attendenceActivitiesSchemaId: updateSchema } : {}),
    };

    if (!updateSchema) {
      // eslint-disable-next-line no-use-before-define
      dispatch(createCurricularActivitiesSchema(payload, refreshSchema, setLoading, setError, setSuccessMsg));
    } else {
      // eslint-disable-next-line no-use-before-define
      dispatch(updateCurricularActivitiesSchema(payload, refreshSchema, setLoading, setError, setSuccessMsg));
    }
  };

  const validationSchema = Yup.object({
    schemaLabel: Yup.string(t('SCHEMA_LABEL_REQUIRED'))
      .required(t('SCHEMA_LABEL_REQUIRED')),
    weightage: Yup.number(t('WEIGHTAGE_REQUIRED'))
      .required(t('WEIGHTAGE_REQUIRED'))
      .max(100, t('INVALID_WEIGHTAGE')),
    totalQuestions: Yup.number(t('TOTAL_QUESTIONS_REQUIRED'))
      .required(t('TOTAL_QUESTIONS_REQUIRED')),
    maxMarks: Yup.number(t('MAX_MARKS_REQUIRED'))
      .required(t('MAX_MARKS_REQUIRED')),
    activitiesSchemaMarks: Yup.array().of(
      Yup.object().shape({
        category: Yup.string().test('category required test', t('CATEGORY_REQUIRED'), (value, context) => {
          const { path } = context;
          const currIndex = parseInt(path.split('[')[1].split(']')[0], 10);
          if (curricularMarksCopy[currIndex]?.deletedAt) {
            return true;
          }
          if (!value) {
            return false;
          }

          return true;
        }),
        marks: Yup.number().test('marks required test', t('MARKS_REQUIRED'), (value, context) => {
          const { path } = context;

          const currIndex = parseInt(path.split('[')[1].split(']')[0], 10);

          if (curricularMarksCopy[currIndex]?.deletedAt) {
            return true;
          }

          if (!value) {
            return false;
          }

          return true;
        }),
        notes: Yup.string().test('notes required test', t('NOTES_REQUIRED'), (value) => {
          if (!value) {
            return false;
          }

          return true;
        }),
      }),
    ),
  });

  const formik = useFormik({
    initialValues: {
      courses: '',
      academicYear: '',
      schemaLabel: '',
      weightage: '',
      totalQuestions: '',
      maxMarks: '',
      activitiesSchemaMarks: [],
      semester: 'S1',
    },
    validationSchema,
    onSubmit: handleSave,
  });

  const refreshSchema = () => {
    dispatch(getCurricularActivitiesSchema(formik.values.courses, formik.values.academicYear, formik.values.semester));
    formik.setTouched({}, false);
  };

  const currentAcademicYear = getCurrentYear()?.id;
  useEffect(() => {
    formik.setFieldValue('academicYear', currentAcademicYear);
  }, [assignedYears]);

  useEffect(() => {
    formik.setFieldValue('courses', courses?.[0]?.id);
  }, [courses]);

  useEffect(() => {
    if (curricularMarksCopy?.length !== formik?.touched?.activitiesSchemaMarks?.length) {
      formik?.touched?.activitiesSchemaMarks?.pop();
    }
  }, [curricularMarksCopy]);

  useEffect(() => {
    if (!formik.values.academicYear || formik.values.academicYear === '' || assignedYears.length === 0) {
      return;
    }
    setValidSemesterYear(isValidSemesterYear(formik.values.academicYear));
    formik.setTouched({}, false);
    const quarterDetailsAction = getQuarterDetails(assignedYears.find((year) => year.id === formik.values.academicYear)?.name);
    dispatch(quarterDetailsAction);
  }, [formik.values.academicYear, assignedYears]);

  const deleteActivityMarksEntry = (index) => {
    setCurricularMarksCopy((oldMarks) => {
      const clonedMarks = [...oldMarks];
      clonedMarks.splice(index, 1);
      formik.setFieldValue('activitiesSchemaMarks', clonedMarks, false);
      return clonedMarks;
    });
  };

  useEffect(() => {
    if (formik.values.totalQuestions < formik.values.activitiesSchemaMarks.length) {
      const countDiff = formik.values.activitiesSchemaMarks.length - formik.values.totalQuestions;

      for (let i = 1; i <= countDiff; i++) {
        deleteActivityMarksEntry(formik.values.activitiesSchemaMarks.length - i);
      }
    } else if (formik.values.totalQuestions > formik.values.activitiesSchemaMarks.length && formik.values.totalQuestions < 21) {
      const countDiff = formik.values.totalQuestions - formik.values.activitiesSchemaMarks.length;
      const newEntries = [];

      for (let i = 1; i <= countDiff; i++) {
        newEntries.push({
          category: '',
          marks: '',
          notes: '',
        });
      }

      formik.setFieldValue('activitiesSchemaMarks', [...formik.values.activitiesSchemaMarks, ...newEntries]);
    }
  }, [formik.values.totalQuestions]);

  const setTotalQuestions = (questions, index) => {
    formik.setFieldValue('totalQuestions', questions);
    deleteActivityMarksEntry(index);
  };

  const changeAcademicPanelMarks = (row, key, value) => {
    const clonedMarks = [...formik.values.activitiesSchemaMarks];

    if (clonedMarks.length <= row) {
      clonedMarks[row] = {
        [key]: value,
      };
    }
    clonedMarks[row][key] = value;

    formik.setFieldValue('activitiesSchemaMarks', clonedMarks);
    setCurricularMarksCopy(clonedMarks);
  };

  const { schema, quarterDetails } = useCurricularActivitiesSchema();

  const checkIfSchemeCanBeEdited = () => {
    let editable = false;
    if (quarterDetails) {
      const { academicYearQuarterDates } = quarterDetails;

      academicYearQuarterDates.forEach((quarterItem) => {
        if (quarterItem.quarter === formik.values.quarter || quarterItem.quarter === formik.values.semester) {
          editable = isValidDate(quarterItem.endDate);
        }
      });
    }
    return editable;
  };

  const calculateCourseTotal = () => {
    if (!formik.values.activitiesSchemaMarks.length) {
      return null;
    }

    const total = formik.values.activitiesSchemaMarks.reduce((oldVal, currentEle) => (
      oldVal + (currentEle?.marks ? parseInt(currentEle.marks, 10) : 0)
    ), 0);

    return total;
  };

  const courseFieldTotal = useMemo(
    () => calculateCourseTotal() || 0,
    [formik.values.activitiesSchemaMarks, formik.values.totalQuestions],
  );

  const schemaCanBeEdited = useMemo(checkIfSchemeCanBeEdited, [quarterDetails, formik.values.semester, formik.values.quarter]);

  useEffect(() => {
    setSaveClicked(false);
    setSuccessMsg('');
    setErrorSchemaRemoved('');
    if (formik.values.courses && formik.values.academicYear && formik.values.semester) {
      refreshSchema();
    }
  }, [formik.values.courses, formik.values.academicYear, formik.values.semester]);

  const prefillSchema = (data) => {
    if (!data.length) {
      formik.setFieldValue('schemaLabel', '');
      formik.setFieldValue('passingCriteria', '');
      formik.setFieldValue('weightage', '');
      formik.setFieldValue('totalQuestions', '');
      formik.setFieldValue('maxMarks', '');
      formik.setFieldValue('activitiesSchemaMarks', []);
      setCurricularMarksCopy([]);
      setUpdateSchema(false);
      setLoading(false);

      return;
    }

    const prefillData = data[0];

    formik.setFieldValue('schemaLabel', prefillData?.schemaLabel || '');
    formik.setFieldValue('passingCriteria', prefillData?.passingCriteria >= 0 ? prefillData?.passingCriteria : 0);
    formik.setFieldValue('weightage', prefillData?.weightage >= 0 ? prefillData?.weightage : 0);
    formik.setFieldValue('totalQuestions', prefillData?.totalQuestions || 0);
    formik.setFieldValue('maxMarks', prefillData?.maximumMarks >= 0 ? prefillData?.maximumMarks : 0);
    formik.setFieldValue('activitiesSchemaMarks', prefillData?.attendence_activities_schema_marks || []);
    setTimeout(() => {
      formik.setFieldTouched('activitiesSchemaMarks', prefillData?.attendence_activities_schema_marks.map(() => ({
        category: false,
        marks: false,
        notes: false,
      })));
    }, 1000);

    if (prefillData?.attendence_activities_schema_marks?.length > 0) {
      setErrorSchemaRemoved('');
    } else {
      setErrorSchemaRemoved(validSemesterYear ? t('SEMESTER_SCHEMA_REMOVED') : t('QUARTER_SCHEMA_REMOVED'));
      setSuccessMsg('');
    }

    setCurricularMarksCopy(prefillData?.attendence_activities_schema_marks || []);
    setUpdateSchema(prefillData.id);

    setLoading(false);
  };

  useEffect(() => {
    if (schema === null) {
      return;
    }

    prefillSchema(schema);
  }, [schema]);

  useEffect(() => {
    if (formik.values.semester === '') {
      setError('');
    } else if (!validSemesterYear) {
      setError(t('CURRICULAR_YEAR_ERROR'));
      setErrorSchemaRemoved('');
      setSuccessMsg('');
    } else if (!schemaCanBeEdited && schemaCanBeEdited !== null) {
      setError(t('CURRICULAR_SCHEMA_ERROR'));
      setErrorSchemaRemoved('');
      setSuccessMsg('');
    } else {
      setError('');
    }
  }, [schemaCanBeEdited, formik.values.semester, formik.values.courses, validSemesterYear]);

  const dependenciesForMarksValidation = formik.values.activitiesSchemaMarks.map(
    (ele) => ele.marks,
  );

  useEffect(() => {
    /* eslint-disable no-else-return */
    const totalMarks = calculateCourseTotal();
    if ((formik.values.totalQuestions === 0) && (formik.values.maxMarks >= 1)) {
      setErrorMarks(t('TOTAL_WEEKS_ERROR'));
      setErrorSchemaRemoved('');
      setSuccessMsg('');
      return;
    }

    if (totalMarks === 0 || totalMarks === null || formik.values.maxMarks === '') {
      setErrorMarks('');
      return;
    }

    if (totalMarks !== formik.values.maxMarks) {
      setErrorMarks(t('MAX_MARKS_ERROR'));
      setErrorSchemaRemoved('');
      setSuccessMsg('');
    } else {
      setErrorMarks('');
    }
  }, [dependenciesForMarksValidation, formik.values.maxMarks]);

  useEffect(() => {
    const { totalQuestions } = formik.values;
    if (totalQuestions > 21) {
      formik.values.totalQuestions = 20;
    }
  }, [formik.values.totalQuestions]);

  return (
    <Box p={2}>
      {loading && (
        <Grid>
          <Loader message={t('LOADING')} />
        </Grid>
      )}
      <Box padding={3}>
        <Grid container item className={classes.titleRow}>
          <Grid item>
            <Typography className={classes.headerTitle}>
              {t('CURRICULAR_ACTIVITIES_SCHEMA')}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box class={classes.contentCont}>
        <Box class={classes.content}>
          <Box m={3}>
            <FormikProvider value={formik}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3} className={classes.formControlGridDropdown}>
                  <DropdownCurricular
                    label={`${t('COURSE')} *`}
                    id="courses"
                    name="courses"
                    labelId="courses"
                    disabled={false}
                    value={formik.values.courses}
                    handleChange={(e) => {
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    options={courses}
                    customStyles={styles.dropDownCont}
                    shrink
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3} className={classes.formControlGridDropdown}>
                  <DropdownCurricular
                    label={`${t('ACADEMICYEAR')} *`}
                    id="academicYear"
                    name="academicYear"
                    labelId="academicYear"
                    disabled={false}
                    value={formik.values.academicYear}
                    handleChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    options={assignedYears}
                    customStyles={styles.dropDownCont}
                    shrink
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3} className={classes.formControlGridDropdown}>
                  <DropdownCurricular
                    label={`${t('SEMESTER')} *`}
                    id="semester"
                    name="semester"
                    labelId="semester"
                    value={formik.values.semester}
                    handleChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    options={semesterOptions.map((sem) => ({
                      id: sem.id,
                      name: sem.key,
                    }))}
                    customStyles={styles.dropDownCont}
                    shrink
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3} className={classes.formControl}>
                  <TextField
                    label={t('LABEL')}
                    id="schemaLabel"
                    labelId="schemaLabel"
                    name="schemaLabel"
                    required
                    type="text"
                    disabled={!schemaCanBeEdited}
                    value={formik.values.schemaLabel}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    size="small"
                    inputProps={{ style: styles.textFieldLabel }}
                    InputLabelProps={{ style: styles.textFieldLabel }}
                    error={formik.touched.schemaLabel ? formik.errors.schemaLabel : undefined}
                    helperText={formik.touched.schemaLabel ? formik.errors.schemaLabel : undefined}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} className={classes.formControl}>
                  <TextField
                    className={classes.formControl}
                    label={t('WEIGHTAGE')}
                    id="weightage"
                    name="weightage"
                    labelId="weightage"
                    required
                    type="number"
                    disabled={!schemaCanBeEdited}
                    value={formik.values.weightage}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    size="small"
                    onKeyPress={(e) => restrictKeys(e, true)}
                    inputProps={{ min: '0', max: '100', style: styles.textFieldLabel }}
                    InputLabelProps={{ style: styles.textFieldLabel }}
                    error={formik.touched.weightage ? formik.errors.weightage : undefined}
                    helperText={formik.touched.weightage ? formik.errors.weightage : undefined}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} className={classes.formControl}>
                  <TextField
                    label={t('TOTAL_QUESTIONS')}
                    required
                    id="totalQuestions"
                    name="totalQuestions"
                    labelId="totalQuestions"
                    type="number"
                    disabled={!schemaCanBeEdited}
                    value={formik.values.totalQuestions < 21 ? formik.values.totalQuestions : 20}
                    onKeyPress={restrictKeys}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    size="small"
                    inputProps={{ min: '0', style: styles.textFieldLabel, max: '20' }}
                    InputLabelProps={{ style: styles.textFieldLabel }}
                    error={formik.touched.totalQuestions ? formik.errors.totalQuestions : undefined}
                    helperText={formik.touched.totalQuestions ? formik.errors.totalQuestions : undefined}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} className={classes.formControl}>
                  <TextField
                    label={t('MAXIMUM_MARKS')}
                    id="maxMarks"
                    labelId="maxMarks"
                    name="maxMarks"
                    required
                    type="number"
                    disabled={!schemaCanBeEdited}
                    value={formik.values.maxMarks}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    onKeyPress={restrictKeys}
                    size="small"
                    inputProps={{ min: '0', style: styles.textFieldLabel }}
                    InputLabelProps={{ style: styles.textFieldLabel }}
                    error={formik.touched.maxMarks ? formik.errors.maxMarks : undefined}
                    helperText={formik.touched.maxMarks ? formik.errors.maxMarks : undefined}
                  />
                </Grid>
              </Grid>

              {error && (
                <Box style={styles.errorCont}>
                  <Typography style={styles.errorText} variant="text">
                    {error}
                  </Typography>
                </Box>
              )}

              {errorMarks && (
                <Box style={styles.errorCont}>
                  <Typography style={styles.errorText} variant="text">
                    {errorMarks}
                  </Typography>
                </Box>
              )}

              {successMsg && (
                <Box style={styles.successCont}>
                  <Typography style={styles.successText} variant="text">
                    {successMsg}
                  </Typography>
                </Box>
              )}

              {errorSchemaRemoved && (
                <Box style={styles.errorCont}>
                  <Typography style={styles.errorText} variant="text">
                    {saveClicked === true ? errorSchemaRemoved : ''}
                  </Typography>
                </Box>
              )}

              <RenderCategoryField
                questions={formik.values.totalQuestions || 0}
                {...{ setTotalQuestions, changeAcademicPanelMarks, classes }}
                handleSave={formik.handleSubmit}
                courseFieldData={formik.values.activitiesSchemaMarks}
                courseFieldErrors={formik.errors.activitiesSchemaMarks}
                courseFieldTouched={formik.touched.activitiesSchemaMarks}
                showSaveBtn={courseFieldTotal === formik.values.maxMarks && schemaCanBeEdited}
                schemaCanBeEdited={schemaCanBeEdited}
                useGetCourseRows={useGetCourseRows}
                styles={styles}
                restrictKeys={restrictKeys}
                refreshSchema={refreshSchema}
                setError={setError}
                setErrorSchemaRemoved={setErrorSchemaRemoved}
                setSuccessMsg={setSuccessMsg}
                setSaveClicked={setSaveClicked}
              />
            </FormikProvider>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default ActivitiesSchema;
