/* eslint-disable */
import React, { useState, useEffect } from 'react';
import {
  Box, Grid, Typography, TextField,
} from '@mui/material';
import { useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import { useSelector } from 'react-redux';
import useStyles from '../../../custom-hooks/useStyles';
import { AntSwitch, style } from './style';
import ButtonAtom from '../../../components/atoms/button/index';
import commonStyle from '../../register/style';
import { Buttons } from '../../../constant';
import { getLocalStorage } from '../../../utils/localStorageMethod';
import userRoles from '../../../constant/userRoles';
import { PerformantTextField, PerformantDropdown, Dropdown } from '../../../components/atoms';
import decryptedUserRoles from '../../../constant/decryptedUserRoles';
import Loader from '../../../components/atoms/loader';
import { checkFloatOrIntGreaterThanZero } from '../../../utils/methods';

function UpdateMarks(props) {
  const {
    studentData,
    courseData,
    handleClose,
    updateMarks,
    assignLoading,
    setSelectedQuarter,
    selectedQuarter,
    setStudentContent,
    setStudentData,
    getEnrollDetails,
  } = props;
  const marksValidation = {};
  const marksInitial = {};
  const maxMarksValues = {};
  const { t } = useTranslation();
  const encryptedUserRole = getLocalStorage('userRole');
  const userRole = decryptedUserRoles[encryptedUserRole];
  const registerClasses = useStyles(commonStyle)();
  const allEnrollDetails = useSelector((state) => state.getStudent.enrollmentDetails);
  const enrollStudentDetails = Object.keys(allEnrollDetails).length ? allEnrollDetails[studentData.studentId] ? allEnrollDetails[studentData.studentId] : {} : {};
  const [enrollDetails, setEnrollDetails] = useState([]);
  const [isPresent, setIsPresent] = useState(true);
  const [isMarksTotal, setMarksTotal] = useState(0);
  const [isHomeworkTotal, setHomeworkTotal] = useState(0);
  const [createNewMarks, setIsCreateNewMarks] = useState(false);
  const [createHomeworkMarks, setIsCreateHomeworkMarks] = useState(false);
  const [createCurricularMarks, setIsCreateCurricularMarks] = useState(false);
  const [createAssignmentMarks, setIsCreateAssignmentMarks] = useState(false);
  const [isValueChanged, setIsValuechanged] = useState(false);
  const [quizName, setQuizName] = useState('');
  const [quizOptions, setQuizOptions] = useState([{ id: 'Quiz 1', name: 'Quiz 1' }, { id: 'Quiz 2', name: 'Quiz 2' }]);
  const [selectedQuiz, setSelectedQuiz] = useState('Quiz 1');
  const [examWeightage, setExamWeightage] = useState(0);
  const [hwWeightage, setHwWeightage] = useState(0);
  const [quiz1Weightage, setQuiz1Weightage] = useState(0);
  const [quiz2Weightage, setQuiz2Weightage] = useState(0);
  const [assignmentWeightage, setAssignmentWeightage] = useState(0);
  const [ccWeightage, setCcWeightage] = useState(0);


  React.useEffect(() => {
    setQuizName(studentData.quizName);
  }, [studentData?.quizName]);

  const fetchMarks = () => {
    let details = [];
    if (studentData?.updateType === 'marks') {
      details = enrollStudentDetails?.marks?.[0] ? enrollStudentDetails?.marks?.[0] : enrollStudentDetails;
    } else if (studentData?.updateType === 'homework-marks') {
      details = enrollStudentDetails?.homeworkMarks?.[0] ? enrollStudentDetails?.homeworkMarks?.[0] : enrollStudentDetails;
    } else if (studentData?.updateType === 'curricular-marks') {
      details = enrollStudentDetails?.attendenceActivitiesMarks?.[0] ? enrollStudentDetails?.attendenceActivitiesMarks?.[0] : enrollStudentDetails;
    } else if (studentData?.updateType === 'assignments') {
      details = enrollStudentDetails?.assignmentMarks?.[0] ? enrollStudentDetails?.assignmentMarks?.[0] : enrollStudentDetails;
    } else if (studentData?.updateType === 'quiz1-marks') {
      details = enrollStudentDetails?.quizPanelQ1Marks?.[0] ? enrollStudentDetails?.quizPanelQ1Marks?.[0] : enrollStudentDetails;
    } else if (studentData?.updateType === 'quiz2-marks') {
      details = enrollStudentDetails?.quizPanelQ2Marks?.[0] ? enrollStudentDetails?.quizPanelQ2Marks?.[0] : enrollStudentDetails;
    }
    return details;
  };

  useEffect(() => {
    setHomeworkTotal(0);
    setMarksTotal(0);
    const details = fetchMarks();

    setIsCreateNewMarks(false);
    setIsCreateHomeworkMarks(false);
    if (enrollStudentDetails?.marks?.length === 0) {
      setIsCreateNewMarks(true);
    }

    if (enrollStudentDetails?.homeworkMarks?.length === 0) {
      setIsCreateHomeworkMarks(true);
    }

    if (enrollStudentDetails?.attendenceActivitiesMarks?.length === 0) {
      setIsCreateCurricularMarks(true);
    }

    if (enrollStudentDetails?.assignmentMarks?.length === 0) {
      setIsCreateAssignmentMarks(true);
    }

    let marks = [];
    if (studentData?.updateType === 'marks') {
      const setAscending = details?.academicPanel?.academic_panel_marks?.sort((a, b) => a.category - b.category);
      const studentDetailMarks = details?.studentDetailMarks?.sort((a, b) => a.academicPanelMarks.category - b.academicPanelMarks.category);
      marks = setAscending?.map((o1, index) => ({
        panelMarksId: o1?.id,
        maxMarks: o1?.marks,
        obtainedMarks: checkFloatOrIntGreaterThanZero(studentDetailMarks?.[index]?.obtainedMarks) ? studentDetailMarks?.[index]?.obtainedMarks : '',
        notes: o1?.notes,
        category: o1?.category,
      }));
      const present = details?.isAttended !== undefined ? details.isAttended : true;
      setIsPresent(present);
      setExamWeightage(details?.academicPanel?.weightage || 0);
    } else if (studentData?.updateType === 'homework-marks') {
      const studentHomeworkMarksDetail = [];
      enrollStudentDetails?.homeworkPanel?.homework_panel_marks?.forEach((obj) => {
        const stu = details?.studentHomeworkMarksDetail?.filter((o) => o.homeworkPanelMarks?.id === obj.id);
        studentHomeworkMarksDetail.push({
          week: stu?.[0]?.homeworkPanelMarks?.week || obj?.week,
          obtainedMarks: checkFloatOrIntGreaterThanZero(stu?.[0]?.obtainedMarks) ? stu?.[0]?.obtainedMarks : '',
          panelMarksId: stu?.[0]?.homeworkPanelMarks?.id || obj?.id,
          notes: stu?.[0]?.homeworkPanelMarks?.notes || obj?.notes,
          category: stu?.[0]?.homeworkPanelMarks?.week || obj?.week,
          maxMarks: stu?.[0]?.homeworkPanelMarks?.marks || obj?.marks,
        });
      });
      const studentHomeworkMarks = studentHomeworkMarksDetail?.sort((a, b) => a.week.split(' ')[1] - b.week.split(' ')[1]);
      marks = studentHomeworkMarks?.map((o1) => ({
        panelMarksId: o1?.panelMarksId,
        maxMarks: o1?.maxMarks,
        obtainedMarks: o1.obtainedMarks,
        notes: o1?.notes,
        category: o1?.week,
      }));
      setHwWeightage(details?.homeworkPanel?.weightage);
    } else if (studentData?.updateType === 'curricular-marks') {
      const curricularObj = details?.attendenceActivitesPanelList ? details?.attendenceActivitesPanelList?.attendence_activities_schema_marks : details?.attendenceActivitiesSchema?.attendenceActivitiesSchemaMarks;
      const setAscending = curricularObj?.sort((a, b) => a.category - b.category);
      const studentDetailMarks = details?.attendenceActivitiesSchema?.studentAttendenceActivitiesMarksDetail?.sort((a, b) => a.studentAttendenceActivitiesMarksDetail[0].category - b.studentAttendenceActivitiesMarksDetail[0].category);
      marks = setAscending?.map((o1, index) => ({
        panelMarksId: o1?.id,
        maxMarks: o1?.marks,
        schemaId: o1?.attendenceActivitiesSchemaId,
        obtainedMarks: checkFloatOrIntGreaterThanZero(studentDetailMarks?.[index]?.studentAttendenceActivitiesMarksDetail?.[0].obtainedMarks) ? studentDetailMarks?.[index]?.studentAttendenceActivitiesMarksDetail?.[0].obtainedMarks : '',
        notes: o1?.notes,
        category: o1?.category,
      }));
      setCcWeightage(details?.attendenceActivitiesSchema?.weightage || 0);
      const present = details?.isAttended !== undefined ? details.isAttended : true;
      setIsPresent(present);
    } else if (studentData?.updateType === 'assignments') {
      const assignmentsObj = details?.assignmentPanelList ? details?.assignmentPanelList?.assignment_marks_schemas : details?.assignmentSchema?.assignment_marks_schemas;
      setAssignmentWeightage(details?.assignmentPanelList ? details?.assignmentPanelList?.weightage : details?.assignmentSchema?.weightage || 0);
      const setAscending = assignmentsObj?.sort((a, b) => a.category - b.category);
      const studentDetailMarks = details?.studentAssignmentDetailMarks?.sort((a, b) => a.assignmentMarksSchema.category - b.assignmentMarksSchema.category);
      marks = setAscending?.map((o1, index) => ({
        panelMarksId: o1?.id,
        maxMarks: o1?.marks,
        schemaId: o1?.assignmentSchemaId || details?.assignmentSchemaId,
        obtainedMarks: checkFloatOrIntGreaterThanZero(studentDetailMarks?.[index]?.obtainedMarks) ? studentDetailMarks?.[index]?.obtainedMarks : '',
        notes: o1?.notes,
        category: o1?.category,
      }));
      const present = details?.isAttended !== undefined ? details.isAttended : true;
      setIsPresent(present);
    } else if (studentData?.updateType === 'quiz1-marks') {
      const quizObj = details?.quizPanelQ1List ? details?.quizPanelQ1List : details?.quizSchema;
      setQuiz1Weightage(details?.quizPanelQ1List ? details?.quizPanelQ1List?.weightage : details?.quizSchema?.weightage || 0)
      const setAscending = quizObj?.quiz_schema_marks?.sort((a, b) => a.category - b.category);
      const quizDetailMarks = details?.studentQuizDetailMarks?.sort((a, b) => a.quizSchemaMarks.category - b.quizSchemaMarks.category);
      marks = setAscending?.map((o1, index) => ({
        panelMarksId: o1?.id,
        maxMarks: o1?.marks,
        schemaId: o1?.quizSchemaId || quizDetailMarks?.[index]?.studentQuizMarksId,
        obtainedMarks: checkFloatOrIntGreaterThanZero(quizDetailMarks?.[index]?.obtainedMarks) ? quizDetailMarks?.[index]?.obtainedMarks : '',
        notes: o1?.notes,
        category: o1?.category,
      }));
      
      const present = details?.isAttended !== undefined ? details.isAttended : true;
      setIsPresent(present);
    } else if (studentData?.updateType === 'quiz2-marks') {
      const quizObj = details?.quizPanelQ2List ? details?.quizPanelQ2List : details?.quizSchema;
      setQuiz2Weightage(details?.quizPanelQ2List ? details?.quizPanelQ2List?.weightage : details?.quizSchema?.weightage || 0)
      const setAscending = quizObj?.quiz_schema_marks?.sort((a, b) => a.category - b.category);
      const quizDetailMarks = details?.studentQuizDetailMarks?.sort((a, b) => a.quizSchemaMarks.category - b.quizSchemaMarks.category);
      marks = setAscending?.map((o1, index) => ({
        panelMarksId: o1?.id,
        maxMarks: o1?.marks,
        schemaId: o1?.quizSchemaId || quizDetailMarks?.[index]?.studentQuizMarksId,
        obtainedMarks: checkFloatOrIntGreaterThanZero(quizDetailMarks?.[index]?.obtainedMarks) ? quizDetailMarks?.[index]?.obtainedMarks : '',
        notes: o1?.notes,
        category: o1?.category,
      }));
      const present = details?.isAttended !== undefined ? details.isAttended : true;
      setIsPresent(present);
    }
    setEnrollDetails(marks);
  }, [enrollStudentDetails, quizName]);

  const formattedValue = (min, max, value) => {
    return Math.max(Number(min), Math.min(Number(max), Number(value)));
  };

  const textField = (
    label,
    id,
    type,
    handleChange,
    onBlur,
    value,
    error,
    max,
    min,
    disable = false,
    allowSpecialCharacter = false,
  ) => {
    return (
      <PerformantTextField
        label={label}
        id={id}
        name={id}
        type={type}
        value={type === 'number' ? formattedValue(min, max, value) : value}
        onBlur={onBlur}
        error={error}
        onChange={handleChange}
        disabled={disable}
        allowSpecialCharacter={allowSpecialCharacter}
        max={max}
        min={0}
        onKeyDown={(event) => {
          if (type === 'number' && (event.key === '+' || event.key === '-' || event.key === 'E' || event.key === 'e')) {
            event.preventDefault();
          }
        }}
      />
    );
  };
  const classes = useStyles(style)();

  const marksValidations = Yup.object({
    fullName: Yup.string()
      .required(t('FULL_NAME_REQUIRED'))
      .typeError(t('FULL_NAME_REQUIRED')),
    courseName: Yup.string()
      .required(t('COURSE_REQUIRED'))
      .typeError(t('COURSE_REQUIRED')),
    quarterName: Yup.string()
      .required(t('QUARTER_REQUIRED'))
      .typeError(t('QUARTER_REQUIRED')),
    ...marksValidation,
  });
  const validationSchema = marksValidations;
  const formik = useFormik({
    initialValues: {
      fullName: studentData?.firstName,
      courseName: studentData?.courseTo,
      studentId: studentData?.studentId,
      updateType: studentData?.updateType,
      quarterName: studentData?.selectedQuarter,
      ...marksInitial,
    },
    validationSchema,
    onSubmit: (values) => {
      const payload = values;
      formik.resetForm();
      if (values?.updateType === 'marks') {
        let studentMarksDetail = '';
        if (!isPresent) {
          studentMarksDetail = enrollDetails?.map((o1) => ({
            academicPanelMarksId: o1?.panelMarksId,
            obtainedMarks: 0,
          }));
        } else {
          studentMarksDetail = enrollDetails?.map((o1, index) => ({
            academicPanelMarksId: o1?.panelMarksId,
            obtainedMarks: values[`marksField-${index}`] ? parseFloat(values[`marksField-${index}`], 10) : 0,
          }));
        }
        payload.studentDetailMarks = studentMarksDetail;
        payload.academicYear = studentData?.academicYear;
        payload.weightage = enrollDetails?.weightage;
        payload.maximumMarks = enrollDetails?.academic_panel?.maximumMarks;
        payload.isPresent = isPresent;
        payload.locationId = studentData?.location;
        payload.isAttended = isPresent;
        handleClose();

        if (createNewMarks) {
          updateMarks(payload);
        } else {
          updateMarks(payload);
        }
      } else if (values?.updateType === 'homework-marks') {
        let studentHomeworkMarksDetail = '';
        studentHomeworkMarksDetail = enrollDetails?.map((o1, index) => ({
          homeworkPanelMarksId: o1?.panelMarksId,
          obtainedMarks: values[`marksField-${index}`] ? parseFloat(values[`marksField-${index}`], 10) : 0,
        }));
        payload.studentHomeworkMarksDetail = studentHomeworkMarksDetail;
        payload.academicYear = studentData?.academicYear;
        payload.weightage = enrollDetails?.weightage;
        payload.maximumMarks = enrollDetails?.homeworkPanel?.maximumMarks;
        payload.isPresent = true;
        payload.locationId = studentData?.location;
        payload.isAttended = true;
        handleClose();
        if (createHomeworkMarks) {
          updateMarks(payload);
        } else {
          updateMarks(payload);
        }
      } else if (values?.updateType === 'curricular-marks') {
        let studentAttendenceActivitiesDetailMarks = '';
        studentAttendenceActivitiesDetailMarks = enrollDetails?.map((o1, index) => ({
          id: o1?.panelMarksId,
          attendenceActivitiesSchemaId: o1?.schemaId || enrollDetails[0]?.schemaId,
          obtainedMarks: values[`marksField-${index}`] ? parseFloat(values[`marksField-${index}`], 10) : 0,
        }));
        payload.studentAttendenceActivitiesDetailMarks = studentAttendenceActivitiesDetailMarks;
        payload.academicYear = studentData?.academicYear;
        payload.weightage = enrollDetails?.weightage;
        payload.maximumMarks = enrollDetails?.homeworkPanel?.maximumMarks;
        payload.isPresent = true;
        payload.locationId = studentData?.location;
        payload.isAttended = true;
        handleClose();
        if (createCurricularMarks) {
          updateMarks(payload);
        } else {
          updateMarks(payload);
        }
      } else if (values?.updateType === 'assignments') {
        let studentAssignmentDetailMarks = '';
        studentAssignmentDetailMarks = enrollDetails?.map((o1, index) => ({
          id: o1?.panelMarksId,
          assignmentSchemaId: o1?.schemaId || enrollDetails[0]?.schemaId,
          obtainedMarks: values[`marksField-${index}`] ? parseFloat(values[`marksField-${index}`], 10) : 0,
        }));
        payload.studentAssignmentDetailMarks = studentAssignmentDetailMarks;
        payload.academicYear = studentData?.academicYear;
        payload.weightage = enrollDetails?.weightage;
        payload.maximumMarks = enrollDetails?.homeworkPanel?.maximumMarks;
        payload.isPresent = true;
        payload.locationId = studentData?.location;
        payload.isAttended = true;
        handleClose();
        if (createAssignmentMarks) {
          updateMarks(payload);
        } else {
          updateMarks(payload);
        }
      } else if (values?.updateType === 'quiz1-marks') {
        let studentDetailMarks = '';
        studentDetailMarks = enrollDetails?.map((o1, index) => ({
          id: o1?.panelMarksId,
          quizSchemaId: o1?.schemaId || enrollDetails[0]?.schemaId,
          obtainedMarks: values[`marksField-${index}`] ? parseFloat(values[`marksField-${index}`], 10) : 0,
        }));
        payload.studentQuizDetailMarks = studentDetailMarks;
        payload.academicYear = studentData?.academicYear;
        payload.weightage = enrollDetails?.weightage;
        payload.maximumMarks = enrollDetails?.homeworkPanel?.maximumMarks;
        payload.isPresent = true;
        payload.locationId = studentData?.location;
        payload.isAttended = true;
        payload.quiz = 'Quiz 1';
        handleClose();
        updateMarks(payload);
      } else if (values?.updateType === 'quiz2-marks') {
        let studentDetailMarks = '';
        studentDetailMarks = enrollDetails?.map((o1, index) => ({
          id: o1?.panelMarksId,
          quizSchemaId: o1?.schemaId || enrollDetails[0]?.schemaId,
          obtainedMarks: values[`marksField-${index}`] ? parseFloat(values[`marksField-${index}`], 10) : 0,
        }));
        payload.studentQuizDetailMarks = studentDetailMarks;
        payload.academicYear = studentData?.academicYear;
        payload.weightage = enrollDetails?.weightage;
        payload.maximumMarks = enrollDetails?.homeworkPanel?.maximumMarks;
        payload.isPresent = true;
        payload.locationId = studentData?.location;
        payload.isAttended = true;
        payload.quiz = 'Quiz 2';
        handleClose();
        updateMarks(payload);
      }
    },
  });

  React.useEffect(() => {
    if (formik.values.quarterName === 'S1' && studentData?.updateType === 'quiz1-marks') {
      setSelectedQuiz('Quiz 1');
    } else if (formik.values.quarterName === 'S2' && studentData?.updateType === 'quiz1-marks') {
      setSelectedQuiz('Quiz 3');
    } else if (formik.values.quarterName === 'S1' && studentData?.updateType === 'quiz2-marks') {
      setSelectedQuiz('Quiz 2');
    } else if (formik.values.quarterName === 'S2' && studentData?.updateType === 'quiz2-marks') {
      setSelectedQuiz('Quiz 4');
    }
  }, [formik.values.quarterName, studentData?.updateType]);

  React.useEffect(() => {
    if (formik.values.quarterName === 'S1') {
      setQuizOptions([{ id: 'Quiz 1', name: 'Quiz 1' }, { id: 'Quiz 2', name: 'Quiz 2' }]);
    } else if (formik.values.quarterName === 'S2') {
      setQuizOptions([{ id: 'Quiz 3', name: 'Quiz 3' }, { id: 'Quiz 4', name: 'Quiz 4' }]);
    }
  }, [formik.values.quarterName]);

  React.useEffect(() => {
    setHomeworkTotal(0);
    setMarksTotal(0);
    if (studentData?.updateType === 'marks') {
      let marks = 0;
      enrollDetails?.forEach((elem, i) => {
        marksValidation[`marksField-${i}`] = Yup.number()
          .required('Required')
          .typeError('Required');
        maxMarksValues[`marksField-${i}`] = elem?.maxMarks;
        formik.setFieldValue(`marksField-${i}`, elem?.obtainedMarks);
        marks += elem.obtainedMarks;
      });
      setMarksTotal(parseFloat(marks));
    } else {
      let homeworkmarks = 0;
      enrollDetails?.forEach((elem, i) => {
        marksValidation[`marksField-${i}`] = Yup.number()
          .required('Required')
          .typeError('Required');
        maxMarksValues[`marksField-${i}`] = elem?.maxMarks;
        formik.setFieldValue(`marksField-${i}`, elem?.obtainedMarks);
        homeworkmarks += elem.obtainedMarks;
      });
      setHomeworkTotal(parseFloat(homeworkmarks));
    }
  }, [enrollDetails]);

  const customMarkChange = (e) => {
    let marks = 0;
    enrollDetails?.forEach((elem, i) => {
      const key = `marksField-${i}`;
      if (key === e.target.id) {
        if (e.target.value <= elem.maxMarks) {
          const regx = /^\d+(\.\d{0,2})?$/;
          if (e.target.value === '' || regx.test(e.target.value)) {
            if (e.target.value !== '') {
              const num = e.target.value ? Number(parseFloat(e.target.value, 10).toFixed(2)) : 0;
              if (num === 0 && e.nativeEvent.data === '0') {
                formik.setFieldValue(`marksField-${i}`, num.toString());
              } else {
                formik.setFieldValue(`marksField-${i}`, num);
              }
              marks += num;
            } else {
              formik.setFieldValue(`marksField-${i}`, '');
            }
          } else {
            const num = e.target.value ? Number(parseFloat(e.target.value, 10).toFixed(2)) : 0;
            if (num === 0 && e.nativeEvent.data === '0') {
              formik.setFieldValue(`marksField-${i}`, num.toString());
            } else {
              formik.setFieldValue(`marksField-${i}`, num);
            }
            marks += num;
          }
        } else {
          formik.setFieldValue(`marksField-${i}`, 0);
        }
      } else if (key.includes('marksField')) {
        marks += formik.values[key] ? Number(formik.values[key]) : 0;
      } else {
        marks += 0;
      }
    });
    setIsValuechanged(true);
    setMarksTotal(marks);
  };

  const customHomeworkChange = (e) => {
    let homeworkMarks = 0;
    enrollDetails?.forEach((elem, i) => {
      const key = `marksField-${i}`;
      if (key === e.target.id) {
        if (e.target.value <= elem.maxMarks) {
          const regx = /^\d+(\.\d{0,2})?$/;
          if (e.target.value === '' || regx.test(e.target.value)) {
            if (e.target.value !== '') {
              const num = e.target.value ? Number(parseFloat(e.target.value, 10).toFixed(2)) : 0;
              if (num === 0 && e.nativeEvent.data === '0') {
                formik.setFieldValue(`marksField-${i}`, num.toString());
              } else {
                formik.setFieldValue(`marksField-${i}`, num);
              }
              homeworkMarks += num;
            } else {
              formik.setFieldValue(`marksField-${i}`, '');
            }
          } else {
            const num = e.target.value ? Number(parseFloat(e.target.value, 10).toFixed(2)) : 0;
            if (num === 0 && e.nativeEvent.data === '0') {
              formik.setFieldValue(`marksField-${i}`, num.toString());
            } else {
              formik.setFieldValue(`marksField-${i}`, num);
            }
            homeworkMarks += num;
          }
        } else {
          formik.setFieldValue(`marksField-${i}`, 0);
        }
      } else if (key.includes('marksField')) {
        homeworkMarks += formik.values[key] ? Number(formik.values[key]) : 0;
      } else {
        homeworkMarks += 0;
      }
    });
    setIsValuechanged(true);
    setHomeworkTotal(homeworkMarks);
  };
  const getErrorText = (key, errorText) => {
    if (formik.touched[key] && formik.errors[key]) {
      return (
        <span data-testid={key} className={classes.errorText}>
          {formik.errors[key]}
        </span>
      );
    } if (errorText) {
      return (
        <span className={classes.errorText}>
          {errorText}
        </span>
      );
    }
    return null;
  };

  const handlePresent = () => {
    setIsPresent(!isPresent);
    setIsValuechanged(true);
  };

  const handleQuarter = (e) => {
    setStudentContent(studentData);
    setSelectedQuarter(e.target.value);
    formik.setFieldValue('quarterName', e.target.value);
  };

  return (
    <Box>
      {assignLoading ? (
        <Grid>
          <Loader message={t('LOADING')} />
        </Grid>
      ) : (
        <FormikProvider value={formik}>
          <form
            name="tenantUserForm"
            noValidate
            autoComplete="off"
            className={`${registerClasses.form} ${classes.form} ${classes.changeGridLayout}`}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                {textField(
                  t('FULL_NAME'),
                  'fullName',
                  'text',
                  formik.handleChange,
                  formik.handleBlur,
                  formik.values.fullName,
                  formik.errors.fullName,
                  '',
                  '',
                  true,
                  true,
                  true,
                  false,
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <PerformantDropdown
                  shrink
                  minWidth="100%"
                  labelId={t('COURSE')}
                  label={`${t('COURSE')}`}
                  id="courseName"
                  name="courseName"
                  value={formik.values.courseName}
                  handleChange={formik.handleChange}
                  disabled={userRole === userRoles.REGION_COORDINATOR ? userRole === userRoles.REGION_COORDINATOR : true}
                  error={getErrorText('courseName')}
                  options={courseData}
                />
                {getErrorText('courseName')}
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <div className={classes.assignquarter}>
                  <Dropdown
                    shrink
                    minWidth="100%"
                    label={studentData?.validSemester ? `${t('SEMESTER')}` : `${t('QUARTER')}`}
                    labelId={t('QUARTER')}
                    id="quarterName"
                    name="quarterName"
                    className="assignquarter"
                    value={formik.values.quarterName ? formik.values.quarterName : selectedQuarter}
                    handleChange={handleQuarter}
                    options={studentData?.quarterOptions}
                    error={getErrorText('quarterName')}
                  />
                  {getErrorText('quarterName')}
                </div>
              </Grid>
              {studentData?.updateType === 'marks' && (
                <Grid item xs={12} sm={6} md={3} className={classes.switchSection}>
                  <Typography variant="h5" gutterBottom component="div">
                    {t('ATTENDANCE')}
                  </Typography>
                  <Stack className={classes.switchUi} spacing={1}>
                    <Typography className={!isPresent ? classes.setRed : ''}>{t('ABSENT')}</Typography>
                    <AntSwitch checked={isPresent} onChange={() => handlePresent()} inputProps={{ 'aria-label': 'ant design' }} />
                    <Typography className={isPresent ? classes.setGreen : ''}>{t('PRESENT')}</Typography>
                  </Stack>
                </Grid>
              )}
              {studentData?.updateType === 'homework-marks' && (
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    label={t('WEIGHTAGE')}
                    id="weightage"
                    name="weightage"
                    autoComplete="weightage"
                    value={hwWeightage || 0}
                    disabled="true"
                  />
                </Grid>
              )}
              {studentData?.updateType === 'quiz1-marks' && (
                <Grid item xs={12} sm={6} md={3} className={classes.assignquarter}>
                  <Dropdown
                    shrink
                    minWidth="100%"
                    label={selectedQuiz}
                    labelId={selectedQuiz}
                    id="QUIZ"
                    name="quizName"
                    className="quizName"
                    value={selectedQuiz}
                    handleChange={(e) => {
                      setSelectedQuiz(e.target.value);
                      const oldValues = studentData;
                      const payload = {
                        firstName: oldValues?.firstName,
                        courseTo: oldValues?.courseTo,
                        studentId: oldValues?.studentId,
                        academicYear: oldValues?.academicYear,
                        selectedQuarter: oldValues?.selectedQuarter,
                        location: oldValues?.location,
                        quarterOptions: oldValues?.quarterOptions,
                        updateType: e.target.value === 'Quiz 1' || e.target.value === 'Quiz 3' ? 'quiz1-marks' : 'quiz2-marks',
                        quizName: e.target.value === 'Quiz 1' || e.target.value === 'Quiz 3' ? 'Quiz 1' : 'Quiz 2',
                        validSemester: oldValues?.validSemester,
                      };
                      setStudentData(payload);
                      getEnrollDetails(payload);
                    }}
                    options={quizOptions}
                  />
                </Grid>
              )}
              {studentData?.updateType === 'quiz2-marks' && (
                <Grid item xs={12} sm={6} md={3}>
                  <Dropdown
                    shrink
                    minWidth="100%"
                    label={selectedQuiz}
                    labelId={selectedQuiz}
                    id="QUIZ"
                    name="quizName"
                    className="quizName"
                    value={selectedQuiz}
                    handleChange={(e) => {
                      setSelectedQuiz(e.target.value);
                      const oldValues = studentData;
                      const payload = {
                        firstName: oldValues?.firstName,
                        courseTo: oldValues?.courseTo,
                        studentId: oldValues?.studentId,
                        academicYear: oldValues?.academicYear,
                        selectedQuarter: oldValues?.selectedQuarter,
                        location: oldValues?.location,
                        quarterOptions: oldValues?.quarterOptions,
                        updateType: e.target.value === 'Quiz 1' || e.target.value === 'Quiz 3' ? 'quiz1-marks' : 'quiz2-marks',
                        quizName: e.target.value === 'Quiz 1' || e.target.value === 'Quiz 3' ? 'Quiz 1' : 'Quiz 2',
                        validSemester: oldValues?.validSemester,
                      };
                      setStudentData(payload);
                      getEnrollDetails(payload);
                    }}
                    options={quizOptions}
                  />
                </Grid>
              )}
              <Grid container>

                <Grid container spacing={2} mt={3} mb={2} className={classes.addStyleHead}>
                  <Grid item xs={3}>
                    <Typography variant="body1" gutterBottom>
                      {studentData?.updateType === 'homework-marks' ? (
                        t('WEEKS')
                      ) : (
                        t('CATEGORY')
                      )}

                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="body1" gutterBottom>
                      {t('MARKS')}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="body1" gutterBottom>
                      {studentData?.updateType === 'marks' ? (
                        t('NOTES')
                      ) : (
                        t('NOTES')
                      )}
                    </Typography>
                  </Grid>
                  {(studentData?.updateType === 'curricular-marks') ? (
                    <Grid item xs={3}>
                      <Typography variant="body1" gutterBottom>
                        {t('ATTENDANCE_WEEKS')}
                        {' '}
                        {enrollStudentDetails?.isPresentAttendance ? enrollStudentDetails?.isPresentAttendance : '0'}
                        {' / '}
                        {enrollStudentDetails?.totalAttendance ? enrollStudentDetails?.totalAttendance : '0'}
                      </Typography>
                    </Grid>
                  ) : (
                    null
                  )}
                </Grid>
                {studentData?.updateType === 'marks' ? (isPresent && enrollDetails?.map((e, i) => (
                  <Grid container spacing={2} className={classes.alignHorCenter}>
                    <Grid item xs={3}>
                      <Typography variant="body1" gutterBottom className={classes.categoryLabel}>
                        {e?.category}
                      </Typography>
                    </Grid>
                    <Grid item xs={3} className={classes.marksDropDown}>
                      <TextField
                        label={t('MARKS')}
                        id={`marksField-${i}`}
                        type="number"
                        onChange={customMarkChange}
                        value={parseInt(formik.values[`marksField-${i}`], 10) >= 0 ? formik.values[`marksField-${i}`] : ''}
                        InputProps={{ inputProps: { min: 0, max: e?.maxMarks } }}
                        onKeyDown={(event) => {
                          if ((event.key === '+' || event.key === '-' || event.key === 'E' || event.key === 'e')) {
                            event.preventDefault();
                          }
                        }}
                      />
                      /
                      {e?.maxMarks}
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="body1" gutterBottom>
                        {e?.notes}
                      </Typography>
                    </Grid>
                  </Grid>
                ))) : (enrollDetails?.map((e, i) => (
                  <Grid container spacing={2} className={classes.alignHorCenter}>
                    <Grid item xs={3}>
                      <Typography variant="body1" gutterBottom className={classes.categoryLabel}>
                        {e?.category}
                      </Typography>
                    </Grid>
                    <Grid item xs={3} className={classes.marksDropDown}>
                      <TextField
                        label={t('MARKS')}
                        id={`marksField-${i}`}
                        type="number"
                        onChange={customHomeworkChange}
                        value={parseInt(formik.values[`marksField-${i}`], 10) >= 0 ? formik.values[`marksField-${i}`] : ''}
                        InputProps={{ inputProps: { min: 0, max: e?.maxMarks } }}
                        onKeyDown={(event) => {
                          if ((event.key === '+' || event.key === '-' || event.key === 'E' || event.key === 'e')) {
                            event.preventDefault();
                          }
                        }}
                      />
                      /
                      {e?.maxMarks}
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="body1" gutterBottom>
                        {e?.notes}
                      </Typography>
                    </Grid>
                  </Grid>
                )))}
              </Grid>
              {studentData?.updateType === 'marks' ? ((isPresent && enrollDetails?.length > 0) && (
                <Grid container>
                  <Grid container spacing={2} className={classes.grayLine}>
                    <Grid item xs={3}>Total Marks</Grid>
                    <Grid item xs={3}>
                      <TextField
                        id="outlined-number"
                        label=" Marks"
                        value={(Number.isInteger(isMarksTotal) ? isMarksTotal : isMarksTotal.toFixed(2))}
                        className={classes.totalMarksInput}
                      />
                      </Grid>
                      <Grid item xs={3}>Weightage of marks</Grid>
                      <Grid item xs={3}>
                      <TextField
                        id="outlined-number-weightage"
                        label="Weightage"
                        value={Number.isInteger(examWeightage) ? (examWeightage * isMarksTotal) / 100 : ((examWeightage * isMarksTotal) / 100).toFixed(1)}
                      />
                      </Grid>
                  </Grid>
                </Grid>
              )) : (
                <Grid container>
                  <Grid container spacing={2} className={classes.grayLine}>
                    <Grid item xs={3}>Total Marks</Grid>
                    <Grid item xs={3}>
                      <TextField
                        id="outlined-number"
                        label=" Marks"
                        value={Number.isInteger(isHomeworkTotal) ? isHomeworkTotal : isHomeworkTotal.toFixed(2)}
                        className={classes.totalMarksInput}
                      />
                      </Grid>
                      <Grid item xs={3}>Weightage of marks</Grid>
                      <Grid item xs={3}>
                        <TextField
                          id="outlined-number-weightage"
                          label="Weightage"
                          value={(() => {
                            let weightage;
                            if (studentData?.updateType === 'homework-marks') {
                              weightage = hwWeightage;
                            } else if (studentData?.updateType === 'curricular-marks') {
                              weightage = ccWeightage;
                            } else if (studentData?.updateType === 'assignments') {
                              weightage = assignmentWeightage;
                            } else if (studentData?.updateType === 'quiz1-marks') {
                              weightage = quiz1Weightage;
                            } else if (studentData?.updateType === 'quiz2-marks') {
                              weightage = quiz2Weightage;
                            }
                            return Number.isInteger(weightage) ? (weightage * isHomeworkTotal) / 100 : ((weightage * isHomeworkTotal) / 100).toFixed(1);
                          })()}
                        />
                      </Grid>
                    </Grid>
                </Grid>
              )}
              <Grid container justifyContent="flex-end">
                <ButtonAtom
                  btntype={Buttons.SECONDARY}
                  name={t('CANCEL')}
                  className={classes.secButtonNew}
                  onClick={() => {
                    formik.resetForm();
                    handleClose();
                  }}
                />
                <ButtonAtom
                  btntype={Buttons.PRIMARY}
                  name={t('UPDATE')}
                  onClick={() => {
                    formik.handleSubmit();
                  }}
                  disabled={!isValueChanged}
                  className={[classes.activeButtonNew, classes.secButtonNew]}
                />
              </Grid>
            </Grid>
          </form>
        </FormikProvider>
      )}
    </Box>
  );
}
export default UpdateMarks;
