import { colors, fonts } from '../../../../theme';

const styles = (theme) => ({
  tagContainer: {
    minHeight: '71px',
    maxHeight: '71px',
    overflow: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  formControlGridRightItems: {
    marginBottom: '5px',
    paddingBottom: '5px',
    width: '100%',
    '& .MuiOutlinedInput-input.Mui-disabled': {
      background: `${colors.disabledField} !important`,
      '-webkit-text-fill-color': `${colors.black} !important`,
    },
    '& .MuiInputLabel-root': {
      fontFamily: `${fonts.fontType.roboto} !important`,
      fontSize: '12px !important',
      color: `${colors.primary} !important`,
      position: 'absolute',
      top: '0%',
      left: '-2%',
      transform: 'translateY(-50%)',
      pointerEvents: 'none',
      zIndex: 1,
      background: 'transparent',
      padding: '0 4px',
      transition: 'transform 200ms, font-size 200ms',
      '@media (max-width: 1199px)': {
        transform: 'translate(10px, 10px)',
      },
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(20px, -8px) !important',
      background: '#fff',
      fontSize: '12px !important',
      color: colors.primary,
      '@media (max-width: 599px)': {
        fontSize: '12px !important',
        transform: 'translate(-0.5vw, -3.3vw) !important',

      },
    },
    '& .MuiOutlinedInput-root': {
      position: 'relative',
      '&:focus-within .MuiInputLabel-root': {
        transform: 'translate(-4px, -50%) scale(0.8)',
        fontSize: '0.8vw',
      },
      background: '#fff',
      borderRadius: '0.4vw',
      '@media (max-width: 600px)': {
        height: '55px !important',
      },
      color: colors.placeHolderColor,
      '@media (min-width: 1200px)': {
        fontSize: '12px !important',
        height: '2.7vw',
      },
      outline: 'none',
      '& .MuiOutlinedInput-notchedOutline': {
        border: `0.1vw solid ${colors.primary}`,
      },
      '&:hover fieldset': {
        border: `0.2vw solid ${colors.primary} !important`,
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `0.2vw solid ${colors.primary}`,
      },
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        border: `0.1vw solid ${colors.errorText}`,
      },
      '&.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `0.2vw solid ${colors.errorText}`,
      },
      '& input': {
        '@media (min-width: 1200px) and (max-width: 1599px)': {
          padding: '0.7vw',
          fontSize: '12px !important',
        },
        fontFamily: `${fonts.fontType.roboto} !important`,
        color: `${colors.black}!important`,
        textOverflow: 'ellipsis',
        [theme.breakpoints.down('md')]: {
          fontSize: '12px !important',
        },
      },
    },
    '& .MuiFormHelperText-contained': {
      margin: 0,
    },
  },
  formControlGrid: {
    marginBottom: '5px',
    paddingBottom: '5px',
    width: '100%',
    '@media (max-width: 1350px)': {
      width: '95%',
      marginRight: 0,
      paddingLeft: '5px !important',
    },
    '& .MuiOutlinedInput-input.Mui-disabled': {
      background: `${colors.disabledField} !important`,
      '-webkit-text-fill-color': `${colors.black} !important`,
    },
    '& .MuiInputLabel-root': {
      fontFamily: `${fonts.fontType.roboto} !important`,
      fontSize: '12px !important',
      color: `${colors.primary} !important`,
      position: 'absolute',
      top: '0%',
      left: '-2%',
      transform: 'translateY(-50%)',
      pointerEvents: 'none',
      zIndex: 1,
      background: 'transparent',
      padding: '0 4px',
      transition: 'transform 200ms, font-size 200ms',
      '@media (max-width: 1199px)': {
        transform: 'translate(10px, 10px)',
      },
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(20px, -8px) !important',
      background: '#fff',
      fontSize: '12px !important',
      color: colors.primary,
      '@media (max-width: 599px)': {
        fontSize: '12px !important',
        transform: 'translate(-0.5vw, -3.3vw) !important',

      },
    },
    '& .MuiInputAdornment-root': {
    },
    '& .MuiOutlinedInput-root': {
      position: 'relative',
      '&:focus-within .MuiInputLabel-root': {
        transform: 'translate(-4px, -50%) scale(0.8)',
        fontSize: '0.8vw',
      },
      background: '#fff',
      borderRadius: '0.4vw',
      '@media (max-width: 600px)': {
        height: '55px !important',
      },
      color: colors.placeHolderColor,
      '@media (min-width: 1200px)': {
        fontSize: '12px !important',
        height: '2.7vw',
      },
      outline: 'none',
      '& .MuiOutlinedInput-notchedOutline': {
        border: `0.1vw solid ${colors.primary}`,
      },
      '&:hover fieldset': {
        border: `0.2vw solid ${colors.primary} !important`,
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `0.2vw solid ${colors.primary}`,
      },
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        border: `0.1vw solid ${colors.errorText}`,
      },
      '&.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `0.2vw solid ${colors.errorText}`,
      },
      '& input': {
        '@media (min-width: 1200px) and (max-width: 1599px)': {
          padding: '0.7vw',
          fontSize: '12px !important',
        },
        fontFamily: `${fonts.fontType.roboto} !important`,
        color: `${colors.black}!important`,
        textOverflow: 'ellipsis',
        [theme.breakpoints.down('md')]: {
          fontSize: '12px !important',
        },
      },
    },
    '& .MuiFormHelperText-contained': {
      margin: 0,
    },
  },

  dropdownFieldd: {
    marginBottom: '5px',
    paddingBottom: '5px',
    paddingTop: '10px',
    width: '100%',
    '& .MuiInputLabel-root': {
      fontFamily: `${fonts.fontType.roboto} !important`,
      fontSize: '12px !important',
      color: `${colors.primary} !important`,
      position: 'absolute',
      top: '0%',
      left: '-2%',
      transform: 'translateY(-50%)',
      pointerEvents: 'none',
      zIndex: 1,
      background: '#fff',
      padding: '0 4px',
      transition: 'transform 200ms, font-size 200ms',
      '@media (max-width: 600px)': {
        fontSize: '12px !important',
        marginTop: '3px',
      },
      '&.MuiInputLabel-shrink': {
        transform: 'translate(20px, -8px) !important',
        fontSize: '12px !important',
        color: colors.primary,
        '@media (max-width: 599px)': {
          fontSize: '12px !important',
          transform: 'translate(-0.5vw, -3.3vw) !important',

        },
      },
    },
    '& .MuiOutlinedInput-root': {
      '@media (min-width: 1200px)': {
        height: '2.4vw !important',
      },
      '@media (min-width: 600px) and (max-width: 899px)': {
        height: '52px !important',
      },
    },
    fontFamily: fonts.fontType.roboto,
    '& .MuiInputLabel-root.Mui-focused': {
      transform: 'translate(5px, -8px) !important',
      marginLeft: '15px',
    },
  },

  formControlClipIcon: {
    marginTop: '-5px',
    '@media (max-height: 850px)': {
      marginTop: '-15px',
    },
    '@media (max-width: 1199px)': {
      paddingTop: '15px',
    },
  },
  formControlGridLeft: {
    width: '25%',
  },

  formControlGridRight: {
    width: '100%',
    marginBottom: '10px',
  },

  multilineText: {
    border: `0.5vw solid ${colors.primary}`,
  },

  textFields: {
    marginBottom: '1vw',
    fontSize: '1vw',
    '@media (max-width: 600px)': {
      fontSize: '3vw',
    },
    fontWeight: 'bold',
  },
  textFieldsDescription: {
    marginLeft: '-15px',
    marginBottom: '1vw',
    color: `${colors.primary}`,
    fontSize: '1vw',
    '@media (max-width: 600px)': {
      fontSize: '3vw',
    },
  },

  containerElements: {
    marginRight: '.5vw',
    marginTop: '.5vw',
    marginBottom: '.5vw',
    width: '100%',
  },

  editorClassContainer: {
    marginTop: '-5px',
    borderRadius: '0.4vw',
    marginLeft: '3.5%',
    width: '99% !important',
    border: `1px solid ${colors.primary}`,
    height: '40vh',
    padding: '10px !important',
    '@media (max-width: 1350px)': {
      width: '91% !important',
    },
    '& .toolbarClassName': {
      backgroundColor: colors.secondary,
    },
    '& .rdw-option-wrapper, .rdw-dropdown-wrapper': {
      border: `0.1vw solid ${colors.backgroundGrey}`,
      height: '0.3vw',
      padding: '0.5vw',
      fontSize: '12px',
      '@media (max-width: 900px)': {
        border: 'none',
        marginRight: '2.5%',
        height: 'auto',
        padding: '2px',
        fontSize: '12px',
      },
      '& .rdw-dropdown-selectedtext': {
        padding: '0 0.5vw 0 0.5vw',
      },
    },
    '@media (max-width: 600px)': {
      margin: '0.5vw 0.8vw 0.8vw 0',
    },
  },

  DialogAtomAddAssignment: {
    '& .MuiDialog-paper': {
      '@media (min-width: 1200px)': {
        minWidth: '65vw !important',
        maxWidth: '75vw !important',
      },
      overflowX: 'hidden !important',
    },
    '& .MuiDialogContent-root': {
      overflow: 'hidden',
      '@media (max-width:900px)': {
        overflow: 'auto',
      },
    },
    '& .MuiDialogContent-root .MuiGrid-root': {
      '@media (min-width:700px) and (max-width:1199px)': {
        paddingLeft: '12px !important',
      },
      '@media (min-width: 1200px)': {
        maxWidth: '65vw !important',
      },
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'flex-start',
    },
    '& .MuiBox-root': {
      padding: '0vw !important',
    },
  },

  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1,
  },

  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
  },

  topContainerBox: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  headerGridItem: {
    paddingLeft: '1.6vw',
    paddingTop: '1vw',
  },

  functionalGridItem: {
    display: 'flex',
  },

  formCrossRow: {
    display: 'flex',
    justifyContent: 'left',
    marginTop: '4px',
    alignItems: 'center',
  },

  dropdownDiv: {
    width: '12vw',
    padding: '1vw',
    '@media (max-width:900px)': {
      width: '120px',
    },
  },

  buttonDiv: {
    padding: '1vw',
    '@media (max-width:900px)': {
      '& .MuiButtonBase-root.MuiButton-root': {
        maxHeight: '55px !important',
        fontSize: 'smaller !important',
      },
      width: '120px',
    },
  },

  bottomGridContainer: {
    padding: '1vw 1.5vw',
  },

  mainCard: {
    minWidth: 275,
    minHeight: 500,
    maxHeight: 500,
    border: '1px solid #104F96',
  },

  cardContainerBox: {
    backgroundColor: 'white',
    minHeight: '22vw',
    width: '23vw',
    '@media (max-width:900px)': {
      width: 'auto !important',
      overflowWrap: 'anyWhere',
    },
  },

  cardTopBox: {
    minHeight: '5vw',
  },

  firstGridContainer: {
    minHeight: '1vw',
    display: 'inline-block',
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: '1vw 1vw 0 1vw !important',
  },

  zeroPadding: {
    padding: '0px !important',
    '& svg': {
      width: '1.1vw',
      height: '1vw',
    },
  },
  zeroPaddingDelete: {
    padding: '0px !important',
    '& svg': {
      width: '1.5vw',
      height: '1.5vw',
    },
  },

  cardTitleContainer: {
    fontWeight: 'bold !important',
    fontSize: '1vw',
    '@media (max-width: 900px)': {
      fontSize: '15px',
    },
  },

  secondGridContainer: {
    minHeight: '1vw',
    padding: '0vw 1vw 0 1vw !important',
    '& span': {
      fontSize: '0.8vw',
      '@media (max-width: 900px)': {
        fontSize: '12px',
      },
    },
  },

  deleteIcon: {
    '& svg': {
      width: '20px',
      height: '20px',
    },
  },

  thirdGridContainer: {
    minHeight: '1vw',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0vw 1vw 0 1vw !important',
    '& span': {
      fontSize: '0.8vw',
    },
  },
  cardBottomBox: {
    padding: '0vw 1vw 0 1vw !important',
    minHeight: '69%',
  },
  truncateText: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  cardBottomGridContainer: {
    padding: '1vw 1vw !important',
    height: '12vw',
    width: '100%',
    overflowY: 'auto',
    wordBreak: 'break-word',
    display: '-webkit-box',
    fontSize: '12px',
    '&::-webkit-scrollbar': {
      width: '7px',
      '- webkit-transition': 'all 300ms',
      transition: 'all 300ms',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      backgroundColor: colors.secondary,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: colors.primary,
      borderRadius: '1vw',
    },
    '@media (max-width: 900px)': {
      fontSize: '10px',
    },
  },
  iconButton: {
    '& svg': {
      width: '15px !important',
      height: '15px !important',
    },
  },
  dropDownCont: {
    padding: '0px !important',
    margin: 0,
  },
  headerTitle: {
    fontWeight: fonts.fontWeight.bold,
    fontSize: '1vw',
    padding: '10px 10px 10px 1px',
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
  },
  datePickerWrapper: {
    width: '13vw !important',
  },
  popupGrid: {
    marginTop: '5vh',
    marginLeft: '30%',
    '@media (max-width: 900px)': {
      justifyContent: 'center',
    },
    '@media (max-height: 850px)': {
      justifyContent: 'center',
      marginTop: '-3.5vh',
    },
    '@media (min-width: 700px) and (max-width: 1199px)': {
      marginTop: '0vh !important',
    },
  },
  popupBtn: {
    width: '5vw',
    '@media (max-width: 900px)': {
      width: 'max-content',
      maxHeight: '50px !important',
    },
    '@media (min-width: 700px) and (max-width: 1199px)': {
      width: 'max-content',
      maxHeight: '50px !important',
    },
  },
  popupButton: {
    width: '5vw',
    '@media (max-width: 900px)': {
      minWidth: 'max-content',
      maxHeight: '50px !important',
    },
    '@media (min-width: 700px) and (max-width: 1199px)': {
      minWidth: 'max-content',
      maxHeight: '50px !important',
    },
  },
  datePicker: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      '@media (min-width: 1200px)': {
        marginTop: '-0.04vw',
        fontSize: '12px',
        '&.MuiInputBase-root': {
          lineHeight: '1',
        },
      },
      '& .MuiButtonBase-root.MuiIconButton-root': {
        '@media (max-width: 900px)': {
          marginTop: '10px',
        },
      },
      '@media (max-width: 1199px)': {
        borderRadius: 4,
      },
      fontFamily: fonts.fontType.roboto,
      borderRadius: '0.2vw',
      outline: 'none',
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `0.2vw solid ${colors.primary}`,
      },
      '&.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `0.2vw solid ${colors.errorText}`,
      },
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        border: `0.1vw solid ${colors.errorText}`,
      },
      '& .MuiSvgIcon-root': {
        '@media (min-width: 1200px)': {
          width: '2vw',
          height: '2vw',
        },
      },
    },
    '& .MuiFormControl-root.MuiTextField-root': {
      '@media (min-width: 1200px)': {
        width: '100% !important',
      },
    },
    '& .MuiFormControl-root': {
      '& .MuiOutlinedInput-root': {
        '& .MuiOutlinedInput-input': {
          '&::placeholder': {
            fontFamily: `${fonts.fontType.roboto} !important`,
            '@media (min-width: 1200px)': {
              fontSize: '12px !important',
            },
            color: `${colors.placeHolderColor}!important`,
            letterSpacing: 1,
          },
        },
      },
    },
  },
  datePickerMobile: {
    width: '100%',
    '& .MuiInputLabel-root': {
      [theme.breakpoints.down('md')]: {
        fontSize: 12,
      },
      '&.MuiInputLabel-shrink': {
        '@media (max-width: 600px)': {
          transform: 'translate(4.0vw, -2.5vw) !important',
          fontSize: '10px !important',
        },
      },
    },
  },
  dropdownField: {
    width: '14vw !important',
    '& .MuiInputLabel-root': {
      transform: 'translate(0.7vw, 12px)',
      fontSize: '12px',
      '@media (max-width: 600px)': {
        fontSize: '12px !important',
        marginTop: '3px',
      },
      '&.MuiInputLabel-shrink': {
        transform: 'translate(0.5vw, -0.3vw) !important',
        fontSize: '12px',
        color: colors.primary,
        paddingLeft: 12,
        '@media (max-width: 600px)': {
          fontSize: '12px !important',
          transform: 'translate(-0.5vw, -3.3vw) !important',

        },
        '@media (min-width: 600px) and (max-width: 899px)': {
          paddingLeft: 8,
          transform: 'translate(3px, -6px) !important',
        },
      },
    },
    '& .MuiOutlinedInput-root': {
      '@media (min-width: 1200px)': {
        height: '2.4vw !important',
      },
    },
    fontFamily: fonts.fontType.roboto,
  },
  dropdownFieldDesk: {
    '& .MuiOutlinedInput-root.MuiInputBase-root': {
      padding: '8.4px !important',
    },
  },
  dropdownFieldMobile: {
    width: '15vw !important',
    '@media (min-width: 601px) and (max-width: 1199px)': {
      width: '100% !important',
    },
    '& .MuiInputLabel-root': {
      fontSize: '12px',
      '@media (max-width: 600px)': {
        fontSize: '12px !important',
      },
      '& .MuiInputBase-root.MuiOutlinedInput-root': {
        '@media (max-width: 600px)': {
          height: '8vw !important',
        },
      },
      '&.MuiInputLabel-shrink': {
        '@media (min-width: 1200px)': {
          fontSize: '0.7vw',
        },
        color: colors.primary,
        '@media (max-width: 600px)': {
          fontSize: '12px !important',
        },
      },
    },
    fontFamily: fonts.fontType.roboto,
  },
  addPopup: {
    paddingTop: '1vw',
    paddingBottom: '1vw',
    '& .MuiGrid-root': {
      paddingLeft: '1vw',
      '@media (max-width: 600px)': {
        paddingLeft: '20px !important',
      },
      '& .MuiTextField-root': {
        '& .MuiInputLabel-root': {
          '@media (min-width: 1200px)': {
            transform: 'translate(0.7vw, 0.5vw)',
            fontSize: '12px',
          },
          '&.MuiInputLabel-shrink': {
            transform: 'translate(1.3vw, -0.5vw)',
            '@media (min-width: 1200px)': {
              fontSize: '0.72vw !important',
            },
            '@media (min-width: 601px) and (max-width: 1199px)': {
              transform: 'translate(2.3vw, -1.2vw)',
            },
            color: colors.primary,
            '@media (max-width: 600px)': {
              transform: 'translate(4.3vw, -2.5vw)',
              fontSize: '10px !important',
            },
          },
        },
      },
      '& .MuiOutlinedInput-root': {
        '@media (max-width: 600px)': {
          height: '40px !important',
          fontSize: '12px !important',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: colors.primary,
          padding: 12,
        },
      },
      '& .MuiAutocomplete-input': {
        padding: '0vw',
        '@media (min-width: 1200px)': {
          fontSize: '12px',
        },
      },
    },
  },
  viewPopup: {
    padding: '1vw',
    '& .MuiGrid-root': {
      paddingLeft: '1vw',
      '& .MuiTextField-root': {
        '& .MuiInputLabel-root': {
          '@media (min-width: 1200px)': {
            transform: 'translate(0.7vw, 0.5vw)',
            fontSize: '12px',
          },
          '&.MuiInputLabel-shrink': {
            transform: 'translate(1.3vw, -0.5vw)',
            fontSize: '12px !important',
            '@media (min-width: 600px) and (max-width: 899px)': {
              transform: 'translate(10px, -5px)',
              fontSize: '10px !important',
            },
            color: colors.primary,
            '@media (max-width: 600px)': {
              fontSize: '10px !important',
              transform: 'translate(2.3vw, -2vw)',
            },
          },
        },
      },
      '& .MuiOutlinedInput-root': {
        height: '2.4vw !important',
        '@media (max-width: 599px)': {
          height: '40px !important',
          fontSize: '12px !important',
        },
        '@media (min-width: 600px) and (max-width: 899px)': {
          height: '40px !important',
          fontSize: '12px !important',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: colors.primary,
        },
      },
      '& .MuiAutocomplete-input': {
        padding: '0vw',
        '@media (min-width: 1200px)': {
          fontSize: '12px',
        },
      },
    },
  },
  errorText: {
    fontSize: '12px',
    color: colors.errorText,
    '@media (max-width: 600px)': {
      fontSize: '2vw',
    },
  },
  deleteText: {
    fontSize: '12px',
    marginBottom: '2vw',
  },
  viewText: {
    fontSize: '12px',
    marginBottom: '2vw',
  },
  popupTextField: {
    '& .MuiOutlinedInput-root': {
      '@media (min-width: 1200px)': {
        height: '2.4vw !important',
      },
    },
    '& .MuiInputLabel-root': {
      fontFamily: `${fonts.fontType.roboto} !important`,
      fontSize: '12px !important',
      [theme.breakpoints.down('md')]: {
        fontSize: '12px !important',
      },
    },
  },
});

export default styles;
