/* eslint-disable */
/* eslint-disable no-restricted-syntax */
import React, {
  useState, useEffect, useRef,
} from 'react';
import Grid from '@mui/material/Grid';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import FilterListIcon from '@mui/icons-material/FilterList';
import IconButton from '@material-ui/core/IconButton';
import { CircularProgress } from '@mui/material';
import Divider from '@mui/material/Divider';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PersonIcon from '@mui/icons-material/Person';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import Chip from '@mui/material/Chip';
import { useFormik } from 'formik';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedCourse } from '../../store/actions/getLocationCoordinator';
import { getAllCourses } from '../../store/actions/getStudent';
import useStyles from '../../custom-hooks/useStyles';
import { getPlaceInformation } from '../../utils/mapMethod';
import { AddressAutoCompleteRegistration, MultiSelectDropdownRegistration } from '../../components/atoms';
import MapContainer from './google-map';
import { getAllLocationsSearchLocations } from '../../store/actions/getLocations';
import style from './style';
import Loader from '../../components/atoms/loader';
import { getLocationsObject } from './helper';

export default function LocationsMap() {
  const { t } = useTranslation();
  const classes = useStyles(style)();
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(null);
  const dispatch = useDispatch();
  const [mapAddressInfo, setMapAddressInfo] = useState('');
  const reduxStore = useSelector((state) => state.getLocations);
  const reduxStudentStore = useSelector((state) => state?.getStudent);
  const assignedCourses = reduxStudentStore?.courses;
  const [data, setData] = useState([]);
  const [locNo, setLocNo] = useState();
  const [currentLoc, setCurrentLoc] = React.useState({ lat: null, lng: null });
  const [selectedLoc, setSelectedLoc] = React.useState({ lat: null, lng: null });
  const [distance, setDistance] = useState();
  const [isSetMap, setIsSetMap] = useState(true);
  const [distanceMatrix, setDistanceMatrix] = useState(true);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [setLocation, setSelectedLocation] = useState();
  const [batchNumber, setBatchNumber] = useState(1);
  const refContenedor = useRef(null);
  useEffect(() => {
    const obtenerUbicacion = async () => {
      try {
        if (navigator.geolocation) {
          const position = await new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject);
          });
          setCurrentLoc({lat: position.coords.latitude, lng: position.coords.longitude});
        }
      } catch (error) {
        const activarGeolocalizacion = window.confirm(
          `To find out the closest locations to you, you'll have to manually activate location in your browser's options`,
        );
      }
    };
    obtenerUbicacion();
  }, []);
  const formik = useFormik({
    initialValues: {
      Course: [],
    },
  });
  function toRadians(degrees) {
    return degrees * (Math.PI / 180);
  }
  function haversine(lat1, lon1, lat2, lon2) {
    const R = 6371;
    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distanceSort = R * c;
    return distanceSort;
  }
  const aproximatedLocations = async (payload, locations) => {
    const distances = [];
    for (const row of locations) {
      const locationLat = row.locationAddress.latitude;
      const locationLon = row.locationAddress.longitude;
      const distanceSort = haversine(payload.lat, payload.lng, locationLat, locationLon);
      distances.push({ location: row, distanceSort });
    }
    distances.sort((a, b) => a.distanceSort - b.distanceSort);
    const sortedLocations = distances.map((item) => item.location);
    return sortedLocations;
  };
  const handleCourseChange = async (e) => {
    setLoading(true);
    setIsSetMap(true);
    const filteredList = [];
    const coursename = [];
    if (e.target) {
      formik.setFieldValue('Course', e.target.value);
      dispatch(setSelectedCourse(e.target));
      if (e.target.value?.length > 0) {
        e.target.value?.forEach((selectedCourse) => {
          reduxStore?.locations?.forEach((loc) => {
            const activeCourse = loc.location_courses?.filter((course) => course.courseId === selectedCourse);
            coursename.push([activeCourse[0]?.course.name, activeCourse[0]?.courseId]);
            if (activeCourse?.length > 0) {
              filteredList.push(loc);
            }
          });
        });
        const list = [...new Set(filteredList)];
        const course = [...new Set(coursename.filter((item) => item[0] !== undefined).map(JSON.stringify))].map(JSON.parse);
        setSelectedCourses(course);
        const result = await getLocationsObject(await aproximatedLocations(currentLoc, list), currentLoc, distanceMatrix, batchNumber);
        if (result.length > 0) {
          setSelectedLoc({ lat: result[0]?.lat, lng: result[0]?.lng });
        }
        setData(result);
        setLocNo(list?.length);
      } else {
        setSelectedCourses([]);
        formik.setFieldValue('Course', []);
        const result = await getLocationsObject(await aproximatedLocations(currentLoc, reduxStore?.locations), currentLoc, distanceMatrix, batchNumber);
        if (result.length > 0) {
          setSelectedLoc({ lat: result[0]?.lat, lng: result[0]?.lng });
        }
        setData(result);
        setLocNo(result.length);
      }
    } else {
      const initialCourseValues = formik.values.Course;
      const currentCourseValuesonDispatch = assignedCourses;
      const updatedSelectedOptions = initialCourseValues.filter((option) => (option !== e[1]));
      if (updatedSelectedOptions.length > 0) {
        formik.setFieldValue('Course', updatedSelectedOptions);
        dispatch(setSelectedCourse(currentCourseValuesonDispatch));
        updatedSelectedOptions.map((option) => { return option[1]; });
        updatedSelectedOptions?.forEach((selectedCourse) => {
          reduxStore?.locations?.forEach((loc) => {
            const activeCourse = loc.location_courses?.filter((course) => course.courseId === selectedCourse);
            coursename.push([activeCourse[0]?.course.name, activeCourse[0]?.courseId]);
            if (activeCourse?.length > 0) {
              filteredList.push(loc);
            }
          });
        });
        const list = [...new Set(filteredList)];
        const course = [...new Set(coursename.filter((item) => item[0] !== undefined).map(JSON.stringify))].map(JSON.parse);
        setSelectedCourses(course);
        const result = await getLocationsObject(await aproximatedLocations(currentLoc, list), currentLoc, distanceMatrix, batchNumber);
        if (result.length > 0) {
          setSelectedLoc({ lat: result[0]?.lat, lng: result[0]?.lng });
        }
        setData(result);
        setLocNo(list?.length);
      } else {
        setSelectedCourses([]);
        formik.setFieldValue('Course', []);
        const result = await getLocationsObject(await aproximatedLocations(currentLoc, reduxStore?.locations), currentLoc, distanceMatrix, batchNumber);
        if (result.length > 0) {
          setSelectedLoc({ lat: result[0]?.lat, lng: result[0]?.lng });
        }
        setData(result);
        setLocNo(result.length);
      }
    }
    setLoading(false);
  };

  useEffect(async () => {
    setSelectedLoc({ lat: null, lng: null });
    const filteredList = [];
    if (mapAddressInfo !== '') {
      const initialCourseValues = formik.values.Course;
      setCurrentLoc({ lat: mapAddressInfo?.lat, lng: mapAddressInfo?.lng });
      if (initialCourseValues.length > 0) {
        initialCourseValues?.forEach((selectedCourse) => {
          reduxStore?.locations?.forEach((loc) => {
            const activeCourse = loc.location_courses?.filter((course) => course.courseId === selectedCourse);
            if (activeCourse?.length > 0) {
              filteredList.push(loc);
            }
          });
        });
        const list = [...new Set(filteredList)];
        const result = await getLocationsObject(await aproximatedLocations(mapAddressInfo, list), mapAddressInfo, distanceMatrix, batchNumber);
        if (result.length > 0) {
          setSelectedLoc({ lat: result[0]?.lat, lng: result[0]?.lng });
        }
        setData(result);
        setLocNo(list?.length);
      } else {
        const result = await getLocationsObject(await aproximatedLocations(mapAddressInfo, reduxStore?.locations), mapAddressInfo, distanceMatrix, batchNumber);
        if (result.length > 0) {
          setSelectedLoc({ lat: result[0]?.lat, lng: result[0]?.lng });
        }
        setData(result);
        setLocNo(result.length);
      }
    }
    formik.validateField('mapAddress');
  }, [mapAddressInfo]);
  /* eslint-disable*/
  const getLocation = async () => {
    setSelectedLoc({ lat: null, lng: null });
    setMapAddressInfo('');
    formik.values.mapAddress = null;
    setIsSetMap(true);
    if (!navigator.geolocation) {
      setStatus('Geolocation is not supported by your browser');
    } else {
      setStatus('Locating...');
      navigator.geolocation.watchPosition(
        async (position) => {
          setStatus(null);
          let oLatLng = {
            lat: '',
            lng: ''
          };
          oLatLng = { lat: position.coords.latitude, lng: position.coords.longitude };
          console.log("Using browser to get location: ", oLatLng);
          const result = await getLocationsObject(await aproximatedLocations(oLatLng, reduxStore?.locations), oLatLng, distanceMatrix, batchNumber);
          if (result.length > 0) {
            const value = new google.maps.LatLng(result[0]?.lat, result[0]?.lng);
            setSelectedLoc({ lat: value.lat(), lng: value.lng() });
          }
          setData(result);
          setCurrentLoc({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        async (error) => {
          console.error("Using geoLocation by IP: ", error);
          setStatus(null);
          if (currentLoc.lat === null && currentLoc.lng === null && error.code === 1) {
            const response = await fetch('https://ipapi.co/json/');
            const dataGeo = await response.json();
            const oLatLng = { lat: dataGeo.latitude, lng: dataGeo.longitude };
            if (currentLoc.lat === null && currentLoc.lng === null) {
              console.log("Using IP to get location: ", oLatLng);
              const result = await getLocationsObject(await aproximatedLocations(oLatLng, reduxStore?.locations), oLatLng, distanceMatrix, batchNumber);
              if (result.length > 0) {
                setSelectedLoc({ lat: result[0]?.lat, lng: result[0]?.lng });
              }
              setData(result);
              setCurrentLoc(oLatLng);
            }
          } else {
            console.log("Using old Geo to establish location: ", currentLoc);
            const result = await getLocationsObject(await aproximatedLocations(currentLoc, reduxStore?.locations), currentLoc, distanceMatrix, batchNumber);
            if (result.length > 0) {
              setSelectedLoc({ lat: result[0]?.lat, lng: result[0]?.lng });
            }
            setData(result);
            setCurrentLoc(currentLoc);
          }
        },
        { enableHighAccuracy: true, timeout: 10000, maximumAge: 10000 },
      );
    }
  };

  useEffect(() => {
    getLocation();
  }, [reduxStore?.locations.length]);

  const handleObjectClick = async (event) => {
    const location = reduxStore?.locations.filter((loc) => loc.id === event.id);
    setSelectedLoc({
      lat: location[0].locationAddress.latitude,
      lng: location[0].locationAddress.longitude,
    });
  };

  useEffect(() => {
    setLoading(true);
    dispatch(getAllCourses());
    setLoading(false);
  }, []);
  const getUserCoords = async () => {
    let oLatLng = {
      lat: '',
      lng: ''
    };
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        function(position) {
          // Ubicación obtenida con éxito
          oLatLng = { lat: position.coords.latitude, lng: position.coords.longitude };
          console.log("Using browser to get location.: ", oLatLng);
          // Hacer algo con las coordenadas
          setCurrentLoc({
            lat: oLatLng.lat,
            lng: oLatLng.lng,
          });
        },
        function(error) {
          // Manejar errores aquí
          console.error("Error at get location by browser.: ", error);
        }
      );
    } else {
      if (currentLoc.lat === null && currentLoc.lng === null) {
        const response = await fetch('https://ipapi.co/json/');
        const dataGeo = await response.json();
        oLatLng = { lat: dataGeo.latitude, lng: dataGeo.longitude };
        console.log("Using IP to get location.: ", oLatLng);
        setCurrentLoc({
          lat: oLatLng.lat,
          lng: oLatLng.lng,
        });
      } else {
        console.log("Using old Geo to establish location.: ", currentLoc);
        oLatLng = { lat: currentLoc.lat, lng: currentLoc.lng };
      }
    }
    return oLatLng;
  };
  useEffect( async() => {
    const payload = await getUserCoords();
    dispatch(await getAllLocationsSearchLocations(payload));
  }, []);

  const ChipsContainer = () => {
    return (
      <div className={classes.chipsContainer}>
        {selectedCourses.length > 0 ? <><br /> <b>Filter By</b> <br /></> : null}
        {selectedCourses.map((course) => (
          <Chip
            key={course[1]}
            label={course[0]}
            variant="outlined"
            onDelete={() => {handleCourseChange(course)}}
          />
        ))}
      </div>
    );
  };

  useEffect(() => {
    if (loading) {
      setIsExpanded(false);
    }
  }, [loading]);

  useEffect(() => {
    cleanLocationCoords();
    getLocation();
  }, [batchNumber]);

  const handleScroll = (event) => {
    const contenedor = event.target;
    if ((contenedor.scrollTop + contenedor.clientHeight >= contenedor.scrollHeight) && (batchNumber * 25 < locNo)) {
      setBatchNumber(batchNumber + 1);
    }
  };

  const cleanUserCoords = () => {
    setCurrentLoc({ lat: null, lng: null });
  };
  const cleanLocationCoords = () => {
    setSelectedLoc({ lat: null, lng: null });
  };
  
  return (
    <Grid>
        <Grid container className={classes.MapLocations}>
          <Grid item xs={12} md={8} lg={8} className={classes.mapGrid}>
            <MapContainer
              originLatLng={!mapAddressInfo ? currentLoc || { lat: 0, lng: 0 } : mapAddressInfo}
              destinationLatLng={selectedLoc}
              isSetMap={isSetMap}
              data={data}
              setDistance={setDistance}
              setDistanceMatrix={setDistanceMatrix}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4} className={classes.locationsGrid}>
            <Grid container spacing={2} className={classes.innerContainerSide}>
              <Grid item xs={8} sm={8} md={8} lg={9} display="inline-block">
                <AddressAutoCompleteRegistration
                  innerCustomClass="customClass1"
                  className={classes.textArea}
                  label={`${t('Enter Location')} *`}
                  id="mapAddress"
                  required
                  name="mapAddress"
                  value={formik.values.mapAddress}
                  onPlaceSelected={async (e) => {
                    setLoading(true);
                    const res = await getPlaceInformation(e.formatted_address);
                    formik.setFieldValue(
                      'mapAddress',
                      e.formatted_address,
                      true,
                    );
                    setSelectedLocation(true);
                    setSelectedLoc({ lat: null, lng: null });
                    const currentLocation = { lat: e.geometry.location.lat(), lng: e.geometry.location.lng() };
                    const result = await getLocationsObject(await aproximatedLocations(currentLocation, reduxStore?.locations), currentLocation, distanceMatrix, batchNumber);
                    if (result.length > 0) {
                      setSelectedLoc({ lat: result[0]?.lat, lng: result[0]?.lng });
                    }
                    setData(result);
                    setCurrentLoc(currentLocation);
                    setMapAddressInfo({ ...res, add: e.formatted_address });
                    setLoading(false);
                  }}
                  onChange={(e) => {
                    formik.setFieldValue('mapAddress', e.target.value);
                    setSelectedLocation(false);
                  }}
                />
                {
                  // eslint-disable-next-line no-nested-ternary
                  (setLocation === false) ? 
                  <span className={classes.errorText}>{t('LOCATION_ADDRESS_FROM_SUGESTIONS')} </span> : null
                }
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={1.2} className={classes.GPSIcon}>
                <IconButton onClick={() => {
                  cleanUserCoords();
                  cleanLocationCoords();
                  getLocation();
                }}>
                  {status ? <CircularProgress color="inherit" /> : <GpsFixedIcon />}
                </IconButton>
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={1.5} className={classes.GPSIcon}>
                <IconButton onClick={() => setIsExpanded(!isExpanded)}>
                  {isExpanded ? <KeyboardArrowUpIcon /> : <FilterListIcon />}
                </IconButton>
              </Grid>
            </Grid>
            {isExpanded && (
              <>
                <Grid item xs={12} sm={12} md={12} lg={11} className={classes.MultiValueinnerContainer}>
                  <MultiSelectDropdownRegistration
                    minWidth="100%"
                    showLabel
                    disabled={loading}
                    label={t('Course')}
                    id="Course"
                    name="Course"
                    value={formik.values.Course}
                    handleChange={(e) => handleCourseChange(e)}
                    options={assignedCourses.map((per) => ({
                      id: per.id,
                      name: per.name,
                    }))}
                    required
                    labelId="Course"
                  />
                  <ChipsContainer />
                </Grid>
              </>
            )}
            <Divider />
            <Grid container className={classes.locationDataBox} ref={refContenedor} onScroll={handleScroll}>
              {data.slice(0, batchNumber * 25).map((lMap) => (
                <>
                  <Grid container spacing={2} className={classes.innerContainer} onClick={() => handleObjectClick(lMap)}>
                    <Grid item xs={12} sm={9} style={{ display: 'flex' }}>
                      <LocationOnIcon />
                      <b>{lMap?.name}</b>
                    </Grid>
                    <Grid item xs={12} sm={3} style={{ textAlign: 'right' }}>
                      <em style={{ fontSize: '12px', fontStyle: 'italic' }}>
                        {`${(selectedLoc.lat === lMap.lat && selectedLoc.lng === lMap.lng) ? distance : `~ ${Number(lMap?.distanceFromLoc).toFixed(2) ?? 'N/A'} mi`}`}
                      </em>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} className={classes.locDataAdr}>
                      {lMap?.address}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} className={classes.locData}>
                      <AccessTimeIcon className={classes.locDataIcon} />
                      <span>
                        Class Timing:
                        {' '}
                        {lMap?.classTime}
                      </span>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} className={classes.locData}>
                      <PersonIcon className={classes.locDataIcon} />
                      {lMap?.locationCoordinator}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} className={classes.locData}>
                      <LocalPhoneIcon className={classes.locDataIcon} />
                      <a className={classes.contactUsLinks} href={`tel:${lMap?.contactNo}`}>{lMap?.contactNo}</a>
                    </Grid>
                    <Grid item xs={12} sm={2} md={12} lg={12} className={classes.locData}>
                      <EmailIcon className={classes.locDataIcon} />
                      <a className={classes.contactUsLinks} href={`mailto:${lMap?.mailID}`}>{lMap?.mailID}</a>
                    </Grid>
                    <Grid item xs={12} sm={2} md={12} lg={12} className={classes.locDataAdr}>
                      <b>Active Courses</b>
                      <br />
                      <em>
                        {lMap?.activeCourses}
                      </em>
                    </Grid>
                  </Grid>
                  <Divider />
                </>
              ))}
            </Grid>
            <Grid className={classes.allLocNum}>
              {
                locNo ? (
                  <span>
                    showing
                    {' '}
                    {locNo}
                    {' '}
                    of
                    {' '}
                    {reduxStore?.locations.length}
                    {' '}
                    locations
                  </span>
                  )
                : (
                    <span>
                      showing
                      {' '}
                      {reduxStore?.locations.length}
                      {' '}
                      locations
                    </span>
                  )
              }
            </Grid>
          </Grid>
        </Grid>
        {
        loading ? (
          <Grid>
          <Loader message={t('LOADING')} />
          </Grid>
        ) : null
        }
    </Grid>
  );
}
