/* eslint-disable */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-filename-extension */
import React, {
  useEffect, useRef, forwardRef, useLayoutEffect,
} from 'react';
import { Jodit } from 'jodit';
import 'jodit/build/jodit.min.css';

const { isFunction } = Jodit.modules.Helpers;

const JoditEditor = forwardRef(
  (
    {
      config, id, name, onBlur, onChange, tabIndex, value, editorRef, onClick,
    },
    ref,
  ) => {
    const textArea = useRef(null);

    useLayoutEffect(() => {
      if (ref) {
        if (isFunction(ref)) {
          ref(textArea.current);
        } else {
          ref.current = textArea.current;
        }
      }
    }, [textArea, ref]);

    useEffect(() => {
      const element = textArea.current;
      textArea.current = Jodit.make(element, config);

      if (isFunction(editorRef)) {
        editorRef(textArea.current);
      }

      return () => {
        if (textArea?.current) {
          textArea.current.destruct();
        }

        textArea.current = element;
      };
    }, [config, editorRef]);

    useEffect(() => {
      if (textArea.current.workplace) {
        textArea.current.workplace.tabIndex = tabIndex || -1;
      }
    }, [tabIndex]);

    useEffect(() => {
      if (!textArea.current.events || (!onBlur && !onChange && !onClick)) {
        return;
      }

      const onBlurHandler = (e) => onBlur && onBlur(textArea.current.value, e);
      const onChangeHandler = (value) => onChange && onChange(value);
      const onClickHandler = (e) => onClick && onClick(textArea.current.value, e);

      // adding event handlers
      textArea.current.events
        .on('blur', onBlurHandler)
        .on('change', onChangeHandler)
        .on('click', onClickHandler);

      return () => {
        // Remove event handlers
        textArea.current?.events
          ?.off('blur', onBlurHandler)
          .off('change', onChangeHandler)
          .off('click', onClickHandler);
      };
    }, [onBlur, onChange, onClick]);

    useEffect(() => {
      if (textArea?.current?.value !== value) {
        textArea.current.value = value;
      }
    }, [value]);

    // Función para manejar el click en el textarea interno del editor
    const handleTextAreaClick = (e) => {
      // Llamar a la función onClick pasada como prop
      if (isFunction(onClick)) {
        onClick(e);
      }
    };

    return (
      <div className="jodit-react-container">
        <textarea
          name={name}
          id={id}
          ref={textArea}
          onClick={handleTextAreaClick}
        />
      </div>
    );
  },
);

JoditEditor.displayName = 'JoditEditor';

export default JoditEditor;

export const substitutionTagsAdmin = [
  {
    title: 'Substitution Tags',
    options: [
      {
        key: 'parent1Name',
        text: `Parent 1 First Name`,
      },
      {
        key: 'parent1LastName',
        text: `Parent 1 Last Name`,
      },
      {
        key: 'academicYear',
        text: 'Academic Year',
      },
      {
        key: 'studentName',
        text:  `Student Name`,
      },
      {
        key: 'manabadiEmail',
        text: `Student ManaBadi Email`,
      },
      {
        key: 'course',
        text: `Student Course Name`,
      },
      {
        key: 'fee',
        text: `Student Fee`,
      },
      {
        key: 'discount',
        text: `Student Discount`,
      },
      {
        key: 'registrationFee',
        text: `Student Registration Fee`,
      },
      {
        key: 'resetLink',
        text: `Reset Password Link`,
      },
      {
        key: 'portalLogin',
        text: `ManaBadi Portal Login`,
      },
      {
        key: 'locationCoordinator',
        text: `Location Coordinator's Name`,
      },
      {
        key: 'locationCoordinatorPhone',
        text: `Location Coordinator's Phone Number`,
      },
      {
        key: 'locationName',
        text: `Location Name`,
      },
      {
        key: 'locationAddress',
        text: `Location Address`,
      },
      {
        key: 'locationTimings',
        text: `Location Timings`,
      },
      {
        key: 'amount',
        text: `Donation Amount`,
      },
    ],
  },
];
